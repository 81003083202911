<template>
  <div>
    <div id="goback" style="margin-bottom: 10px; font-size: 14px">
      <i class="el-icon-back" @click="$router.go(-1)" style="cursor: pointer"
        >返回</i
      >&nbsp;&nbsp;
      <span style="font-size: 14px; color: rgb(192, 196, 204)">|</span>
      &nbsp;&nbsp;<span style="font-size: 14px; color: rgb(192, 196, 204)"
        >编号：</span
      >{{ pcode }}
      <span style="font-size: 14px; color: rgb(192, 196, 204)">项目：</span
      >{{ pname }}&nbsp;&nbsp;<span
        style="font-size: 14px; color: rgb(192, 196, 204)"
        >报告：</span
      >{{ bname }}
    </div>

    <div id="content">
      <div>
        <div>
          <el-upload
            class="upload mar10"
            name="quota"
            :action="upurl"
            :headers="{ Authorization: token }"
            auto-upload
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
            :on-success="onSuccess"
            :data="{ pid: pid, kid: kid }"
          >
            <el-button  type="success" size="small" >上传配额表</el-button>
          </el-upload>
          <el-button
          class="mar10"
          type="warning"
            size="small"
            @click="pdowndata(fname, 'quotaDown','配额表不存在,请先进行上传')"
            >下载配额表</el-button
          >
          <el-button
          style="margin:0 20px 0px 50px"
          type="primary"            
            size="small"
            @click="runQuota"
            :disabled="runded"
            >{{ runtip }}</el-button
          >
          <span style="font-size:14px;color:#666">启用 remove:</span>
          <el-switch
          style="margin:0 15px"
            v-model="removed"
            @change="quotaRemove"
            active-color="#13ce66"
            inactive-color="#ccc"
            active-value="1"
            inactive-value="0"
          ></el-switch>


          <el-button
          class="mar10"
          style="margin:0 15px;margin-left:60px;"
          
            size="small"
            type="primary" plain
            @click="pdowndata('respid.xlsx', 'quotaRidDown','不存在respid,请先进行撞数')"
            >下载respID</el-button
          >

          <el-button
          class="mar10"
          type="primary" plain
            size="small"
            @click="exportQuota"
            >导入到quotaID</el-button
          >

       

         
        </div>
      </div>

      <div class="part2">
        <div class="quota" v-if="result!=null && result.length>0">
          <span class="tit">quotaMin：</span><span class="val">{{quota.min}}</span>
          <span class="tit">quotaMax：</span><span class="val">{{quota.max}}</span>
          <span class="tit">quotaCountNow：</span><span class="val">{{quota.count}}</span>
        </div>
        <el-table
        size="small"
    :data="result"
    stripe>
    <el-table-column
    sortable
      prop="Exp"
      label="表达式"
      width="400">
    </el-table-column>
    <el-table-column
    sortable
      prop="Min"
      label="min"
      width="150">
    </el-table-column>
    <el-table-column
    sortable
      prop="Max"
      width="150"
      label="max">
    </el-table-column>
    <el-table-column
    sortable
      prop="CountNow"
      width="150"
      label="CountNow">
    </el-table-column>
    <el-table-column
    sortable
      prop="CountGap"
      width="150"
      label="CountGap">
      <template slot-scope="scope">
        <span :class="scope.row.CountGap != 0 ? 'gapred' : 'gapblack'">{{ scope.row.CountGap }}</span>  
      </template>
    </el-table-column>
    <el-table-column
    sortable
      prop="Remove"
      width="150"
      label="Remove">
    </el-table-column>
  </el-table>



      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "QuotaRun",
  props: ["pid", "kid", "pname", "pcode", "bname"],
  data() {
    return {
      upurl: this.COMMON.httpUrl + "quotaUpload",
      token: "Bearer " + localStorage.getItem("token"),
      runded: false,
      runtip: "运行撞数",
      removed: "0",
      fname: "",
      result:[],
      quota:[],
    };
  },

  methods: {
    runQuota() {
      this.runded=true
      this.runtip="启动中.."      

      var api = this.COMMON.httpUrl + "quotaTaskRun";
      var data = {
        kid: this.kid,
        pid: this.pid,
      };
      this.axios.post(api, data).then((response) => {
        var rs = response.data;
        if (rs.code) {
          this.runded=false
          this.runtip="运行撞数"   
          this.$message.error(rs.message);
        } else {          
          this.$message.success(rs.message);
          this.startPolling() 
        }
      });
    },
    // remove开关
    quotaRemove() {
      var api = this.COMMON.httpUrl + "quotaRemove";
      var data = {
        kid: this.kid,
        pid: this.pid,
        removed:this.removed
      };
      this.axios.post(api, data).then((response) => {
        var rs = response.data;
        if (rs.code) {
          // this.$message.error(rs.message);
        } else {
          // this.$message.error(rs.message);
        }
      });
    },
    exportQuota() {
      var api = this.COMMON.httpUrl + "exportRidQuota";
      var data = {
        kid: this.kid,
        pid: this.pid,
      };
      this.axios.post(api, data).then((response) => {
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        } else {          
          this.$message.success(rs.message);
        }
      });
    },
    getQid() {
      var api = this.COMMON.httpUrl + "getQuota";
      var data = {
        kid: this.kid,
        pid: this.pid,
      };
      this.axios.post(api, data).then((response) => {
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        } else {          
            this.fname = rs.data.fname;
            this.removed = rs.data.removed;
            this.result=rs.data.result;
            this.quota=rs.data.quota;
            if(rs.data.status=="1"){
              this.runded=true
              this.runtip="运行中.."
            }else{
              this.runtip="运行撞数"   
              this.runded=false
              this.stopPolling();
            }
        }
      });
    },
    beforeAvatarUpload(file) {      
      let fileName = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = fileName === "xls";
      const extension2 = fileName === "xlsx";
      if (!extension && !extension2) {
        this.$message({
          message: "上传文件只能是 xls、xlsx格式!",
          type: "warning",
        });
        return false;
      }
    },
    onSuccess(res) {
      if (res.code) {
        this.$message.error(res.message);
      } else {
        this.fname = res.data;
        this.$message.success(res.message);
      }
    },
    // 文件下载
    pdowndata(fname, url,er) {

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: this.token,
        },
        body: JSON.stringify({ pid: this.pid, kid: this.kid }),
      };

      let src = this.COMMON.httpUrl + url;
      fetch(src, requestOptions)
        .then((response) => {
          if (!response.ok) {
            throw new Error(er);
          }
          return response.blob();
        })
        // .then((resp) => resp.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          // 文件名称可以在这里更改
          a.download = fname;
          a.href = url;
          a.click();
        })
        .catch((error) => {
          // alert(error)
          this.$message.error(error);
        });
    },
     // 开始轮询后端  
     startPolling() {  
      this.intervalId = setInterval(this.getQid, 2000);  
    },  
    // 停止轮询后端  
    stopPolling() {  
      clearInterval(this.intervalId);  
    }  
  },
  created() {
    this.getQid();
    this.startPolling();
  },
  beforeDestroy() {  
    this.stopPolling();  
  }  
};
</script>
<style scoped>
#content {
  background-color: white;
  padding-top: 30px;
}

.part1 {
  text-align: left;
} 
.part2 {
  margin: 30px 0;
  width:1150px;
}
.upload {
  display: inline-block;
  /* margin: 0 8px; */
}
.mar10 {
  margin-right: 20px;
}
.quota{
  margin-top:50px;
  margin-bottom: 30px;
  background-color: #f1f3f8;
  height:50px;
  line-height:50px;
  padding-left:20px;
}
.quota .tit{
font-weight: 800;
color:#999;
}
.quota .val{
  margin-left:5px;
  margin-right:40px;
  color:#666;
  font-weight: 800;
}
.gapred{
  color:red;
  font-weight:800;
  font-size: 16px;
}
.gapblack{
  color:#333;
  font-size: 14px;
}
</style>
 