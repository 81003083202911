<template>
  <div>
    <div id="content">
      <el-row>
        <el-col :span="12"
          ><div class="dleft">
            <div class="anl" style="text-align: right; margin-right: 30px">
              <el-button type="info" plain size="small" @click="reset"
                >重置</el-button
              >
              <el-button size="small" type="primary" @click="add"
                >添加</el-button
              >
            </div>

            <div class="anl">
              <el-input
                v-model="keywordL"
                placeholder="条件"
                clearable
                size="mini"
                style="width: 184px; margin-right: 280px"
              ></el-input>
              <el-button type="info" plain size="mini" @click="selectAllR"
                >全选</el-button
              ><el-button type="info" plain size="mini" @click="valueQidR = []"
                >全不选</el-button
              >
            </div>
            <div>
              <table>
                <tr>
                  <td width="200">
                    <el-cascader-panel
                      v-model="valueQidL"
                      :options="optionsFilL"
                      :props="{ checkStrictly: false }"
                    ></el-cascader-panel>
                  </td>
                  <td td width="400">
                    <el-cascader-panel
                      v-model="valueQidR"
                      :options="optionsFilR"
                      :props="{ multiple: true, checkStrictly: false }"
                    ></el-cascader-panel>
                  </td>
                </tr>
              </table>
            </div>
            <div style="margin-top: 20px">
              <el-button-group>
                <el-button size="mini" type="primary" plain @click="parse(1)"
                  >任一选中</el-button
                >
                <el-button size="mini" type="primary" plain @click="parse(2)"
                  >均未选中</el-button
                >
                <el-button size="mini" type="warning" plain @click="parse(3)"
                  >单项选中</el-button
                >
                <el-button size="mini" type="warning" plain @click="parse(4)"
                  >大于</el-button
                >
                <el-button size="mini" type="warning" plain @click="parse(5)"
                  >大于等于</el-button
                >
                <el-button size="mini" type="warning" plain @click="parse(6)"
                  >小于</el-button
                >
                <el-button size="mini" type="warning" plain @click="parse(7)"
                  >小于等于</el-button
                >
                <el-button size="mini" type="success" plain @click="parse(8)"
                  >and</el-button
                >
                <el-button size="mini" type="success" plain @click="parse(9)"
                  >or</el-button
                >
              </el-button-group>
            </div>
            <div class="part3">
              <el-input
                spellcheck="false"
                placeholder="表达式生成,可编辑"
                type="textarea"
                v-model="product"
                class="product"
                rows="3"
              ></el-input>
            </div></div
        >
        <div class="anl">
          <el-row>
            <el-col :span="10">
              <el-select
                v-model="openv"
                placeholder="请选择 open 题"
                filterable
                clearable
                style="width: 90%"
              >
                <el-option
                  v-for="item in opens"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="12">
            
            </el-col>
          </el-row>
        </div>
        <div class="anl">
          <template>
              <el-radio v-model="guan"  border label="1">填入跟以下句子意思相近的句子</el-radio>
              <el-radio v-model="guan"  border label="0">填入跟以下句子意思都不相近的句子</el-radio>
            </template>
        </div>
        <div class="anl">
          <el-input v-model="txt" placeholder="请输入文本内容" maxlength="300" style="width: 600px;"></el-input>
        </div>
      </el-col>
        <el-col :span="12"
          ><div class="dright">
            <div class="anl">
              <span style="margin-right: 20px; font-size: 13px; color: #afafaf"
                >已创建模板，共 {{ configs.length }} 条</span
              >
              <el-button
                type="danger"
                size="small"
                @click="delall"
                >清空</el-button
              >
            <el-upload
                class="upload"
                name="oe"
                :action="upurl"
                :headers="{ Authorization: token }"
                :data="{ pid: pid }"
                auto-upload
                :show-file-list="false"
                :before-upload="beforeAvatarUpload"
                :on-success="onSuccess"
                ><el-button  type="info" plain size="small" 
                  >导入逻辑编辑模板</el-button
                ></el-upload
              ><el-link type="primary" :href="tempurl"  style="margin-left:10px">下载导入模板</el-link>
            </div>
            <div
              v-show="configs.length > 0"
              style="height: 600px; width: 750px; overflow: auto"
            >
              <el-table size="mini" row-key="id" :data="configs" fit border>                        
                <el-table-column prop="yuor" label="逻辑或" width="60">
                  <template slot-scope="scope">                                    
                  <el-checkbox v-model="scope.row.yuor" true-label="1" false-label="0" @change="luoji(scope.row.id,$event)"></el-checkbox>
                </template>  
                </el-table-column>
                <el-table-column prop="tiaojian" label="条件">
                </el-table-column>
                <el-table-column prop="open" label="open题" width="80">
                </el-table-column>       
                <el-table-column prop="guan" label="相关" width="80">
                  <template slot-scope="scope">
                  <span  v-if="scope.row.guan=='1'">相关</span>
                  <span  v-else>不相关</span>
                </template>  
                
                </el-table-column>                
                  <el-table-column prop="txt" label="文本"> </el-table-column>
                <el-table-column label="操作" width="80">
                  <template slot-scope="scope">
                    <el-button
                      type="text"
                      size="mini"
                      @click="del(scope.$index, scope.row.id)"
                      >删除</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div></el-col
        >
      </el-row>
    </div>
  </div>
</template>
  <script>
export default {
  name: "OeText",
  props: ["pid"],
  data() {
    return {
      upurl: this.COMMON.httpUrl + "oeTextImport",
      tempurl:this.COMMON.httpUrl + "storage/temp/oeTextImport.xlsx",
      token: "Bearer " + localStorage.getItem("token"),
      loading3: false,

      txt:"",
      guan:"1",
      opens: [],
      openv: "",
      
      keywordL: "",

      optionsL: [], //select选项
      optionsFilL: [], //筛选后的select选项
      valueQidL: [], //select选中的值

      optionsFilR: [], //筛选后的select选项
      valueQidR: [], //select选中的值
      ns: [],

      qidcode: [], //select选中的值对应的属性
      configs: [], //右侧用于存储的配置

      pick: {
        dan: [3, 4, 5, 6, 7],
        duo: [1, 2],
        fu: [8, 9],
        entity: {
          1: "==",
          2: "!=",
          3: "==",
          4: ">",
          5: ">=",
          6: "<",
          7: "<=",
          8: " and ",
          9: " or ",
        },
      },
      valueNew: {
        firstKey: "",
        firstArray: [],
      },
      product: "", //最终解析后的表达式
    };
  },
  watch: {

    keywordL: {
      handler(val) {
        val = val.toLowerCase();
        this.optionsFilL = this.optionsL.filter((p) => {
          return p.label.toLowerCase().indexOf(val) !== -1;
        });
      },
    },
    valueQidL: {
      handler(val) {
        this.optionsFilR = this.ns[val[0]];
        this.valueQidR = [];
      },
    },
  },
  methods: {
    luoji(id,z){
      
      var api = this.COMMON.httpUrl + "oeTextYuor";
      var data = {
        pid: this.pid,
        id:id,
        z:z,
      };
      this.axios.post(api, data).then((response) => {
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        } 
      });
    },
    parse(sign) {
      // 逻辑运算符
      if (this.pick.fu.includes(sign)) {
        this.product += this.pick.entity[sign];
        return;
      }
      // 是否有选中了
      if (this.valueQidR.length == 0) {
        return;
      }
      this.resetValueQid();
      let firsts = this.valueNew.firstArray;
      let firstk = this.valueNew.firstKey;
      let count = this.valueNew.firstArray.length;

      // 选项数量与运算符数量不匹配
      if (count > 1 && this.pick.dan.includes(sign)) {
        alert("所选运算只能有一个选项值");
        // this.$message.error("所选运算只能有一个选项值");
        return;
      }
      // 组装表达式，只选中了一个的情况
      if (count == 1) {
        let acode = firsts[0];
        let ds = this.qidcode[firstk + "@" + acode];
        // alert(this.valueNew.firstKey+"@"+acode)
        let tp = ds.tp;
        let vid = ds.vid;
        switch (tp) {
          case "multi":
            if (sign == 1 || sign == 3) {
              //单项选中，任一选中（== 1 ）
              this.product += vid + `==1`;
            } else if (sign == 2) {
              //均未选中 
              this.product += vid + `!=1`;
            } else {
              return;
            }
            break;
          case "single":
          case "grid":
            this.product += vid + this.pick.entity[sign] + acode;
            break;
          case "range":
          case "numericlist":
            this.product += vid + this.pick.entity[sign];
            break;
        }
      }
      // 组装表达式，只选中了2个以上的情况
      if (count >= 2) {
        let acode = firsts[0];
        let ds = this.qidcode[firstk + "@" + acode];
        let tp = ds.tp;

        // 任一选中
        if (sign == 1) {
          let exps = [];
          let vid2;
          switch (tp) {
            case "multi":
              firsts.forEach((acode) => {
                let ds = this.qidcode[this.valueNew.firstKey + "@" + acode];
                let vid = ds.vid;
                exps.push(vid + `==1`);
              });
              this.product += exps.join(" or ");
              break;
            case "grid":
            case "single":
              firsts.forEach((acode) => {
                let ds = this.qidcode[this.valueNew.firstKey + "@" + acode];
                vid2 = ds.vid;
                exps.push(acode);
              });
              this.product += vid2 + ` in [` + exps.join() + `]`;
              break;
          }
        }

        // 均未选中
        if (sign == 2) {
          let exps = [];
          switch (tp) {
            case "multi":
              firsts.forEach((acode) => {
                let ds = this.qidcode[this.valueNew.firstKey + "@" + acode];
                let vid = ds.vid;
                exps.push(vid + `!=1`);
              });
              this.product += exps.join(" and ");
              break;
            case "grid":
            case "single":
              firsts.forEach((acode) => {
                let ds = this.qidcode[this.valueNew.firstKey + "@" + acode];
                let vid = ds.vid;
                exps.push(vid + `!=` + acode);
              });
              this.product += exps.join(" and ");
              break;
          }
        }
      }

      // 重置选中值
      this.valueQidL = [];
      this.valueNew.firstKey = "";
      this.valueNew.firstArray = [];
    },
    // 根据key把选中值做下分组
    resetValueQid() {
      this.valueNew.firstKey = this.valueQidL[0];
      this.valueNew.firstArray = this.valueQidR;
    },

    getQid() {
      // this.$message.warning("数据加载中，请稍候");
      var api = this.COMMON.httpUrl + "oeTextOption";
      var data = {
        pid: this.pid,
      };
      this.axios.post(api, data).then((response) => {
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          this.opens = rs.data.opens;
          this.lps = rs.data.lps;

          this.optionsL = rs.data.ms;
          this.optionsFilL = rs.data.ms;

          this.ns = rs.data.ns;
          this.qidcode = rs.data.ds;
          this.configs = rs.data.cfg;
        }
      });
    },

    selectAll() {
      this.valueQidTop = [];
      for (let v1 of this.optionsFilTop) {
        for (let v2 of v1.children) {
          this.valueQidTop.push([v1.value, v2.value]);
        }
      }
    },
    selectAllR() {
      let x = [];
      for (let v1 of this.optionsFilR) {
        x.push([v1.value]);
      }
      this.valueQidR = x;
    },

    add() {
    
      if (this.product.trim() == "") {
        alert("条件不能为空");
        return;
      }
      if (this.openv == "") {
        alert("open题不能为空");
        return;
      }
      if (this.txt.trim() == "") {
        alert("文本内容不能为空");
        return;
      }
    
      let cfg = {};
      cfg.pid = this.pid;
      cfg.open = this.openv;
      cfg.guan=this.guan;
      cfg.tiaojian = this.product;
      cfg.txt=this.txt;
      this.save(cfg);
      
    },

    // 提交保存
    save(cfg) {
      this.loading = true;
      var api = this.COMMON.httpUrl + "oeTextAdd";
      var data = cfg;
      this.axios.post(api, data).then((response) => {
        this.loading = false;
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          this.$message.success(rs.message);
          cfg.id=rs.data;
          this.configs.push(cfg);
        }
      });
    },
    downtemp() {
      this.loading = true;
      var api = this.COMMON.httpUrl + "oeTempDown";
      var data = { pid: this.pid };
      this.axios.post(api, data).then((response) => {
        this.loading = false;
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          this.$message.success(rs.message);
        }
      });
    },
    reset() {
      // 重置
      this.openv = "";      
      this.product = "";
      this.keywordL = "";
      this.txt="";
      this.guan="1";
      this.valueQidR=[];
      this.valueQidL=[];

    },
    del(index, tid) {
      var api = this.COMMON.httpUrl + "oeTextDel";
      var data = {
        pid: this.pid,
        tid: tid,
      };
      this.axios.post(api, data).then((response) => {
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          this.configs.splice(index, 1);
          this.$message.success(rs.message);
        }
      });
    },
    delall() {
      var api = this.COMMON.httpUrl + "oeTextClear";
      var data = {
        pid: this.pid,
      };
      this.axios.post(api, data).then((response) => {
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          this.configs = [];
          this.$message.success(rs.message);
        }
      });
    },
    beforeAvatarUpload(file) {
      this.loading = true;
      let fileName = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = fileName === "xls";
      const extension2 = fileName === "xlsx";
      if (!extension && !extension2) {
        this.$message({
          message: "上传文件只能是 xls、xlsx格式!",
          type: "warning",
        });
        this.loading = false;
        return false;
      }
    },
    onSuccess(res) {
      if (res.code) {
        this.$message.error(res.message);
      } else {
        this.$message.success(res.message);
        this.configs = res.data;
      }
      this.loading = false;
    },
  },
  created() {
    this.getQid();
  },
};
</script>
<style scoped>
#content {
  background-color: white;
  padding-top: 0px;  
}
.dleft {
  width: 630px;
}
.anl {
  margin-bottom: 10px;
  margin-top: 25px;
}

.part3 {
  margin-top: 15px;
}
.product {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  width: 600px;
}
::v-deep .el-cascader-menu__wrap {
  height: 220px;
}
.upload {
  display: inline-block;
  margin: 0 8px;
}
</style>
 