<template>
  <div>
    <div id="content">
      <el-row>
        <el-col :span="12"
          ><div class="dleft">
            <div class="anl">
              <el-input v-model="keyword" placeholder="搜索"  clearable size="small" style="width:184px;margin-right:115px"></el-input>
              <el-button type="info" plain size="small" @click="selectAll"
                >全选</el-button
              ><el-button type="info" plain size="small" @click="valueQid = []"
                >全不选</el-button
              ><el-button size="small" type="primary" @click="add" :loading="loading"
                >添加</el-button
              >
            </div>
            <div>
              <el-cascader-panel              
                v-model="valueQid"
                :options="optionsFil"
                :props="{multiple: true, checkStrictly: false }"
              ></el-cascader-panel>
            </div>
            <div style="margin-top: 60px">
              <p style="font-size:13px;color:#999;margin-bottom:15px;">计算格式:</p>              
              <el-checkbox-group v-model="geshi" size="mini">
              <el-checkbox label="top" border></el-checkbox>
              <el-checkbox label="bottom" border></el-checkbox>
              <el-checkbox label="nps" border></el-checkbox>
              <el-checkbox label="mean" border></el-checkbox>
              <el-checkbox label="average" border></el-checkbox>
            </el-checkbox-group>
            </div></div
        ></el-col>
        <el-col :span="12"
          ><div class="dright">
            <div class="anl"  v-show="configs.length>0">
              <span
                style="font-size: 13px; color: #afafaf"
                >已创建计算格式，共 {{ configs.length }} 条</span
              >
            </div>
            <div v-show="configs.length>0" style="height:600px;width:580px;overflow: auto;padding-right:20px">
              <el-table size="mini" :data="configs"   fit border>
                <el-table-column prop="zukey" label="题号" width="150">
                </el-table-column>
                <el-table-column prop="geshi" label="计算格式" >
                </el-table-column>
                <el-table-column label="操作" width="80"><template slot-scope="scope">
                    <el-button type="text" size="mini" @click="del(scope.$index, scope.row.id)"
                      >删除</el-button>
                  </template></el-table-column>
              </el-table>

            </div>
           </div
        ></el-col>
      </el-row>
    </div>
  </div>
</template>
  <script>
export default {
  name: "KpiGeshi",
  props: ["kid", "pid"],
  data() {
    return {
      loading:false,
      keyword:"",    
      geshi:[],
      
      valueQid: [],      
      configs: [],
      qidcode: [],

      options: [],
      optionsFil: [],
    };
  },
  watch:{
    keyword:{      
      handler(val){
        val=val.toLowerCase()
        this.optionsFil=this.options.filter((p)=>{
          return p.label.toLowerCase().indexOf(val) !== -1
        })
      }
    }
  },
  methods: {
    getQid() {
      var api = this.COMMON.httpUrl + "kpiGeshiSelect";
      var data = {
        pid: this.pid,
        kid: this.kid,
      };
      this.axios.post(api, data).then((response) => {
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          this.options = rs.data.bs;
          this.optionsFil = rs.data.bs;
          this.qidcode = rs.data.ds;
          this.configs=rs.data.cfg;
        
          
        }
      });
    },
    selectAll() {
      this.valueQid = [];
      for (let v1 of this.optionsFil) {
        this.valueQid.push([v1.value]);
        
      }
      console.log("xxz:",this.valueQid);

    },
    add(){      

      if(this.valueQid.length==0){
        alert("请至少选取一个题目")
        return
      }
      if(this.geshi.length==0){
        alert("请至少选取一个计算格式")
        return
      }
      let cfg=[];
     
    for(let v of this.valueQid){

      let arr=this.qidcode[v[0]]    

        arr["geshi"]=this.geshi.join(",")
        arr["zukey"]=v[0]
        arr["pid"]=this.pid
        arr["kid"]=this.kid
       
        // 计算tkey
        let tkey=""
        if (arr["lp"]){
          // loop情况
          switch(arr["tp"]){
            case "multi","single":
            tkey=arr["qid"]+"#"+arr["lph"]
            break
            case "grid"://这里默认有两个下划线
              var gs=arr["vid"].split("_")
              tkey=gs[0]+"_"+gs[1]+"#"+arr["lph"]
            break
            default:
            tkey=arr["qid"]+"#"+arr["lph"]
          }

        }else{
          switch(arr["tp"]){
            case "multi":
            tkey=arr["qid"]
            break
            case "single","grid":
            tkey=arr["vid"]
            break
            default:
            tkey=arr["qid"]            
          }
        }

        arr["tkey"]=tkey
        delete arr["qid"]
        delete arr["tp"]
        delete arr["vid"]
        delete arr["lph"]
        delete arr["lp"]
        cfg.push(arr)
    }

    this.save(cfg)
    },
     // 提交保存
     save(cfg) {
      this.loading = true;      
      var api = this.COMMON.httpUrl + "kpiGeshiSave";
      var data = {
        kid:this.kid,
        configs: cfg,
      };
      this.axios.post(api, data).then((response) => {
        this.loading = false;
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          this.$message.success(rs.message);
          this.configs = rs.data;
          // 重置
           this.valueQid=[];
           this.geshi=[];          
           this.keyword=""
        }
      });
    },

    del(index,id) {      
      var api = this.COMMON.httpUrl + "kpiGeshiDel";
      var data = {
        kid:this.kid,
        id: id,
      };
      this.axios.post(api, data).then((response) => {

        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          this.configs.splice(index, 1);
          this.$message.success(rs.message);

        }
      });
    },

  },
  created() {
    localStorage.setItem("kpitag","five")
    this.getQid();
  },
};
</script>
  <style scoped>

#content {
  background-color: white;
  padding-top: 40px;
  padding-left: 50px;
}
.dleft {
  width: 550px;
}
.anl {
  margin-bottom: 20px;
}
/* v-deep深度选择器 */
::v-deep .el-cascader-menu__wrap {
  height: 350px;
}
.el-checkbox.is-bordered+.el-checkbox.is-bordered{
    margin-left:0;
  }
</style>
 