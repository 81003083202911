<template>
  <div>
    <div id="content">
      <el-row>
        <el-col :span="12"
          ><div class="dleft">
            <div class="anl">
              <el-input
                v-model="keywordTop"
                placeholder="当前题"
                clearable
                size="mini"
                style="width: 184px; margin-right: 90px"
              ></el-input>
              <el-button type="info" plain size="mini" @click="sync"
                >同类题选项处理</el-button
              >
              <el-button type="info" plain size="mini" @click="selectAll"
                >全选</el-button
              ><el-button
                type="info"
                plain
                size="mini"
                @click="valueQidTop = []"
                >全不选</el-button
              ><el-button size="mini" v-loading="loading" type="primary" @click="add"
                >添加</el-button
              >
            </div>
            <div style="width: 600px">
              <el-cascader-panel
                v-model="valueQidTop"
                :options="optionsFilTop"
                :props="{ multiple: true, checkStrictly: false }"
              ></el-cascader-panel>
            </div>
            <div class="anl">
              <el-input
                v-model="keywordL"
                placeholder="base题"
                clearable
                size="mini"
                style="width: 184px; margin-right: 280px"
              ></el-input>
              <el-button type="info" plain size="mini" @click="selectAllR"
                >全选</el-button
              ><el-button type="info" plain size="mini" @click="valueQidR = []"
                >全不选</el-button
              >
            </div>
            <div>
              <table>
                <tr>
                  <td width="200">
                    <el-cascader-panel
                      v-model="valueQidL"
                      :options="optionsFilL"
                      :props="{ checkStrictly: false }"
                    ></el-cascader-panel>
                  </td>
                  <td td width="400">
                    <el-cascader-panel
                      v-model="valueQidR"
                      :options="optionsFilR"
                      :props="{ multiple: true, checkStrictly: false }"
                    ></el-cascader-panel>
                  </td>
                </tr>
              </table>
            </div>
            <div style="margin-top: 20px">
              <el-button-group>
                <el-button size="mini" type="primary" plain @click="parse(1)"
                  >任一选中</el-button
                >
                <el-button size="mini" type="primary" plain @click="parse(2)"
                  >均未选中</el-button
                >
                <el-button size="mini" type="warning" plain @click="parse(3)"
                  >单项选中</el-button
                >
                <el-button size="mini" type="warning" plain @click="parse(4)"
                  >大于</el-button
                >
                <el-button size="mini" type="warning" plain @click="parse(5)"
                  >大于等于</el-button
                >
                <el-button size="mini" type="warning" plain @click="parse(6)"
                  >小于</el-button
                >
                <el-button size="mini" type="warning" plain @click="parse(7)"
                  >小于等于</el-button
                >
                <el-button size="mini" type="success" plain @click="parse(8)"
                  >and</el-button
                >
                <el-button size="mini" type="success" plain @click="parse(9)"
                  >or</el-button
                >
              </el-button-group>
            </div>
            <div class="part3">
              <el-input
                spellcheck="false"
                placeholder="表达式生成,可编辑"
                type="textarea"
                v-model="product"
                class="product"
                rows="3"
              ></el-input>
            </div></div
        ></el-col>
        <el-col :span="12"
          ><div class="dright">
            <div class="anl">              
             <span
                style="margin-right:20px;font-size: 13px; color: #afafaf"
                >已创建逻辑，共 {{ configs.length }} 条</span
              ><el-button type="danger" size="mini" v-loading="loading" @click="delall"
                >清空</el-button
              >
              <el-upload class="upload" name="show" :action="upurl"  :headers="{ Authorization: token}" :data="{pid: pid}" auto-upload :show-file-list="false"
                            :before-upload="beforeAvatarUpload" :on-success="onSuccess"><el-button type="success"
                                size="mini" v-loading="loading">导入</el-button></el-upload>
              <el-link type="primary" :href="tempridurl"  style="margin-left:10px">下载导入模板</el-link>
            </div>
            <div v-show="configs.length>0" style="height:600px;width:580px;overflow: auto;padding-right:20px">
              <el-table size="mini"  row-key="id" :data="configs"   fit border>
                <el-table-column prop="dang" label="当前题" width="150">
                </el-table-column>
                <el-table-column prop="base" label="base题" >
                </el-table-column>
                <el-table-column label="操作" width="80">
                  <template slot-scope="scope">
                    <el-button type="text" size="mini" @click="del(scope.$index, scope.row.id)"
                      >删除</el-button>
                  </template>
                </el-table-column>
              </el-table>

            </div>
          </div></el-col
        >
      </el-row>
    </div>
  </div>
</template>
  <script>
export default {
  name: "LogicShow",
  props: ["pid"],
  data() {
    return {
      upurl: this.COMMON.httpUrl+"logicShowImport",
      token:"Bearer " + localStorage.getItem("token"),
      tempridurl:this.COMMON.httpUrl + "storage/temp/logicShowImport.xlsx",      

      loading: false,
      keywordL: "",
      keywordTop: "",

      optionsTop: [], //select选项
      optionsFilTop: [], //筛选后的select选项
      valueQidTop: [], //select选中的值

      optionsL: [], //select选项
      optionsFilL: [], //筛选后的select选项
      valueQidL: [], //select选中的值

      optionsFilR: [], //筛选后的select选项
      valueQidR: [], //select选中的值
      ns: [],

      qidcode: [], //select选中的值对应的属性
      configs: [], //右侧用于存储的配置

      pick: {
        dan: [3, 4, 5, 6, 7],
        duo: [1, 2],
        fu: [8, 9],
        entity: {
          1: "==",
          2: "!=",
          3: "==",
          4: ">",
          5: ">=",
          6: "<",
          7: "<=",
          8: " and ",
          9: " or ",
        },
      },
      valueNew: {
        firstKey: "",
        firstArray: [],
      },
      product: "", //最终解析后的表达式
    };
  },
  watch: {
    keywordTop: {
      handler(val) {
        val = val.toLowerCase();
        this.optionsFilTop = this.optionsTop.filter((p) => {
          return p.label.toLowerCase().indexOf(val) !== -1;
        });
      },
    },
    keywordL: {
      handler(val) {
        val = val.toLowerCase();
        this.optionsFilL = this.optionsL.filter((p) => {
          return p.label.toLowerCase().indexOf(val) !== -1;
        });
      },
    },
    valueQidL: {
      handler(val) {
        this.optionsFilR = this.ns[val[0]];
        this.valueQidR = [];
      },
    },
  },
  methods: {
    parse(sign) {
      // 逻辑运算符
      if (this.pick.fu.includes(sign)) {
        this.product += this.pick.entity[sign];
        return;
      }
      // 是否有选中了
      if (this.valueQidR.length == 0) {
        return;
      }
      this.resetValueQid();
      let firsts = this.valueNew.firstArray;
      let firstk = this.valueNew.firstKey;
      let count = this.valueNew.firstArray.length;

      // 选项数量与运算符数量不匹配
      if (count > 1 && this.pick.dan.includes(sign)) {
        alert("所选运算只能有一个选项值");
        // this.$message.error("所选运算只能有一个选项值");
        return;
      }
      // 组装表达式，只选中了一个的情况
      if (count == 1) {
        let acode = firsts[0];
        let ds = this.qidcode[firstk + "@" + acode];
        // alert(this.valueNew.firstKey+"@"+acode)
        let tp = ds.tp;
        let vid = ds.vid;
        switch (tp) {
          case "multi":
            if (sign == 1 || sign == 3) {
              //单项选中，任一选中（== 1 ）
              this.product += vid + `==1`;
            } else if (sign == 2) {
              //均未选中 （== 0 ）
              this.product += vid + `!=1`;
            } else {
              return;
            }
            break;
          case "single":
          case "grid":
            this.product += vid + this.pick.entity[sign] + acode;
            break;
          case "range":
          case "numericlist":
            this.product += vid + this.pick.entity[sign];
            break;
        }
      }
      // 组装表达式，只选中了2个以上的情况
      if (count >= 2) {
        let acode = firsts[0];
        let ds = this.qidcode[firstk + "@" + acode];
        let tp = ds.tp;

        // 任一选中
        if (sign == 1) {
          let exps = [];
          let vid2;
          switch (tp) {
            case "multi":
              firsts.forEach((acode) => {
                let ds = this.qidcode[this.valueNew.firstKey + "@" + acode];
                let vid = ds.vid;
                exps.push(vid + `==1`);
              });
              this.product += exps.join(" or ");
              break;
            case "grid":
            case "single":
              firsts.forEach((acode) => {
                let ds = this.qidcode[this.valueNew.firstKey + "@" + acode];
                vid2 = ds.vid;
                exps.push(acode);
              });
              this.product += vid2 + ` in [` + exps.join() + `]`;
              break;
          }
        }

        // 均未选中
        if (sign == 2) {
          let exps = [];
          switch (tp) {
            case "multi":
              firsts.forEach((acode) => {
                let ds = this.qidcode[this.valueNew.firstKey + "@" + acode];
                let vid = ds.vid;
                exps.push(vid + `!=1`);
              });
              this.product += exps.join(" and ");
              break;
            case "grid":
            case "single":
              firsts.forEach((acode) => {
                let ds = this.qidcode[this.valueNew.firstKey + "@" + acode];
                let vid = ds.vid;
                exps.push(vid + `!=` + acode);
              });
              this.product += exps.join(" and ");
              break;
          }
        }
      }

      // 重置选中值
      this.valueQidL = [];
      this.valueNew.firstKey = "";
      this.valueNew.firstArray = [];
    },
    // 根据key把选中值做下分组
    resetValueQid() {
      this.valueNew.firstKey = this.valueQidL[0];
      this.valueNew.firstArray = this.valueQidR;
    },
    resetValueQid22() {
      // console.log("valueQid:",this.valueQid)
      this.valueQid.forEach((ele) => {
        let k = ele[0];
        let v = ele[1];
        if (this.valueNew.firstKey == "") {
          this.valueNew.firstKey = k;
        }
        if (this.valueNew.objQid[k] !== undefined) {
          this.valueNew.objQid[k].push(v);
        } else {
          this.valueNew.objQid[k] = [v];
        }
      });
      this.valueNew.firstArray = this.valueNew.objQid[this.valueNew.firstKey];
      delete this.valueNew.objQid[this.valueNew.firstKey];
    },
    getQid() {
      // this.$message.warning("数据加载中，请稍候");
      var api = this.COMMON.httpUrl + "logicShowSelect";
      var data = {
        pid: this.pid,
      };
      this.axios.post(api, data).then((response) => {
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          this.optionsTop = rs.data.bs;
          this.optionsFilTop = rs.data.bs;

          this.optionsL = rs.data.ms;
          this.optionsFilL = rs.data.ms;

          this.ns = rs.data.ns;
          this.qidcode = rs.data.ds;
          this.configs = rs.data.cfg;
        }
      });
    },
    selectAll() {
      this.valueQidTop = [];
      for (let v1 of this.optionsFilTop) {
        for (let v2 of v1.children) {
          this.valueQidTop.push([v1.value, v2.value]);
        }
      }
    },
    selectAllR() {
      let x = [];
      for (let v1 of this.optionsFilR) {
        x.push([v1.value]);
      }
      this.valueQidR = x;
    },
    sync(){
      if (this.valueQidTop.length == 0) {
        alert("当前题选项不能为空");
        return;
      }
      // //筛选出第一个Qid及选项acode
      let qidFirst=""
      let codes=[]
      let qidT=[]
      for (let v of this.valueQidTop) {
          let qid=v[0]
          let acode=v[1]
          if(qidFirst==""){
            qidFirst=qid
          }
          if(qidFirst==qid){
            // 第一个题，收集acode
            codes.push(acode)
          }else{
            // 后面的题,收集qid
            if(!qidT.includes(qid)){
              qidT.push(qid)
            }            
          }

      }
      // 重置acode
      let vs= []
      for(let a of codes){
        vs.push([qidFirst,a])
      }
      for(let q of qidT){
        for(let a of codes){
          vs.push([q,a])
        }
      }
      this.valueQidTop=vs
    },
    add() {

      if (this.valueQidTop.length == 0 || this.product.trim() == "") {
        alert("当前题和base题选项均不能为空");
        return;
      }
      let cfg = [];
      for (let v of this.valueQidTop) {
        let ds = this.qidcode[v[0] + "@" + v[1]];
        let tp = ds.tp;
        let vid = ds.vid;
        let acode = ds.acode;
        let dang = "";
        switch (tp) {
          case "multi":
            dang = vid + `==1`;
            break;
          case "single":
          case "grid":
            dang = vid + `==` + acode;
            break;
          // case "range":
          // case "numericlist":
          //   vid + this.pick.entity[sign];
          //   break;
        }
        if (dang.trim() != "") {
          cfg.push({pid:this.pid, dang: dang, base: this.product });
        }
      }
      // this.configs.push(...cfg);
      // console.log(this.configs);
      this.save(cfg)
    },
    // 提交保存
    save(cfg) {
      this.loading = true;      
      var api = this.COMMON.httpUrl + "logicShowSave";
      var data = {
        pid:this.pid,
        configs: cfg,
      };
      this.axios.post(api, data).then((response) => {
        this.loading = false;
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          this.$message.success(rs.message);
          this.configs = rs.data;
          // 重置
          this.valueQidTop=[];
          this.product="";
          this.keywordTop=""
          this.keywordL=""
        }
      });
    },
    del(index,id) {      
      var api = this.COMMON.httpUrl + "logicShowDel";
      var data = {
        pid:this.pid,
        id: id,
      };
      this.axios.post(api, data).then((response) => {

        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          this.configs.splice(index, 1);
          this.$message.success(rs.message);

        }
      });
    },
    delall() {      
      var api = this.COMMON.httpUrl + "logicShowDel";
      var data = {
        pid:this.pid,
        id: 0,
      };
      this.axios.post(api, data).then((response) => {

        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          this.configs=[];
          this.$message.success(rs.message);

        }
      });
    },
    beforeAvatarUpload(file) {
            this.loading = true
            let fileName = file.name.substring(file.name.lastIndexOf('.') + 1)

            //    alert(fileName)
            const extension = fileName === 'xls'
            const extension2 = fileName === 'xlsx'
            if (!extension && !extension2) {
                this.$message({
                    message: '上传文件只能是 xls、xlsx格式!',
                    type: 'warning'
                });
                this.loading = false
                return false
            }

        },
        onSuccess(res) {
            if (res.code) {
                this.$message.error(res.message)
            } else {
                this.$message.success(res.message);
                this.configs=res.data
            }
            this.loading = false

        }
  },
  created() {
    this.getQid();
  },
};
</script>
<style scoped>
#content {
  background-color: white;
  padding-top: 0px;
  padding-left: 50px;
}
.dleft {
  width: 630px;
}
.anl {
  margin-bottom: 10px;
  margin-top: 25px;
}

.part3 {
  margin-top: 15px;
}
.product {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  width: 600px;
}
::v-deep .el-cascader-menu__wrap {
  height: 220px;
}
.upload {
  display: inline-block;
  margin: 0 8px;
}
</style>
 