<template>
    <div>
        <div id="content">

            <div class="op">
                <el-button type="danger" size="small" icon="el-icon-delete" @click="del">删除</el-button>
                <el-upload class="upload" name="sms" :action="upurl" :limit="10" auto-upload :show-file-list="false"
                    :before-upload="beforeAvatarUpload" :on-success="onSuccess">
                    <el-button :loading="loading" size="small" type="warning" icon="el-icon-upload2">导入</el-button>
                </el-upload>
            </div>
            <div class="plist">
                <el-table :data="tableData" ref="multipleTable" style="width: 100%">
                    <el-table-column type="selection">
                    </el-table-column>
                    <el-table-column prop="id" label="id" v-if="false">
                    </el-table-column>
                    <el-table-column prop="sn" label="任务编号" width="400">
                    </el-table-column>                  
                    <el-table-column label="" width="100">
                        <template slot-scope="scope">
                                <router-link :to="{ name: 'smshow', params: { sid: scope.row.id }}"  class="caozuo"><i class="el-icon-view el-icon--right"></i> 查看</router-link>
                        </template>
                    </el-table-column>
                    <el-table-column prop="created_time"   label="创建日期">
                    </el-table-column>
                </el-table>
            </div>
            <div class="fenye">
                <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="10"
                    layout="total, prev, pager, next" :total=total>
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SmsExcel',
    data() {
        return {
            loading: false,
            upurl: '',
            multipleSelection: [],
            tableData: [],
            currentPage: 1,
            total: 1,
            token: ''
        }
    },
    created() {

        this.handleCurrentChange(1)
        this.upurl = this.COMMON.httpUrl + "smsupload?token=" + localStorage.getItem('token')
    },
    methods: {
        del() {

            var s = []
            this.$refs.multipleTable.selection.forEach((Ele) => {
                s.push(Ele.id)
            });
            if (s.length == 0) {
                return
            }

            this.$confirm('删除后会清空任务数据,是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.rundel()
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        rundel() {

            var s = []
            this.$refs.multipleTable.selection.forEach((Ele) => {
                s.push(Ele.id)
            });

            var api = this.COMMON.httpUrl + "smsdel"
            var data = {
                tids: s
            }
            this.axios.post(api, data).then((response) => {
                var rs = response.data
                if (rs.code) {
                    this.$message.error(rs.message);
                } else {

                    this.tableData = rs.message.data
                    this.currentPage = rs.message.current_page
                    this.total = rs.message.total

                }

            })

        },
        downresult(tid) {

            var api = this.COMMON.httpUrl + "chatgptexport"
            var data = {
                tid: tid
            }
            this.axios.post(api, data).then((response) => {
                var rs = response.data
                if (rs.code) {
                    this.$message.error(rs.message);
                } else {
                    //    alert(this.COMMON.httpUrl+rs.message)
                    window.location.href = this.COMMON.httpUrl + rs.message
                }

            })

            // window.location.href = this.COMMON.httpUrl + "storage/"+pname
        },
        beforeAvatarUpload(file) {
            this.loading = true
            let fileName = file.name.substring(file.name.lastIndexOf('.') + 1)

            //    alert(fileName)
            const extension = fileName === 'xls'
            const extension2 = fileName === 'xlsx'
            if (!extension && !extension2) {
                this.$message({
                    message: '上传文件只能是 xls、xlsx格式!',
                    type: 'warning'
                });
                this.loading = false
                return false
            }

        },
        handleCurrentChange(page) {
            var api = this.COMMON.httpUrl + "sms"
            var data = {
                page: page
            }
            this.axios.post(api, data).then((response) => {
                var rs = response.data
                if (rs.code) {
                    this.$message.error(rs.message);
                } else {

                    this.tableData = rs.message.data
                    this.currentPage = rs.message.current_page
                    this.total = rs.message.total

                }

            })
        },
        onSuccess(res) {
            if (res.code) {
                this.$message.error(res.message)
            } else {
                this.tableData = res.message.data
                this.currentPage = res.message.current_page
                this.total = res.message.total
                // this.$message.success("项目数据上传成功！")

            }
            this.loading = false

        }


    }
}
</script>
  
<style scoped>
#content {
    background-color: white;
    width: 1200px;
    text-align: left;
    padding-left: 0px;

}

.op {
    padding: 20px 0;
}

.fenye {
    margin-top: 20px;
}

.cell a {
    color: #666;
    text-decoration: none;
}

.cell a:hover {
    color: rgb(125, 163, 234);
    text-decoration: underline;
}

.caozuo {
    color: #409EFF !important;
    margin-right: 10px;
}

.result {
    color: #409EFF !important;
    margin-left: 0px;

}

.upload {
    display: inline-block;
    margin-left: 15px;
}</style>