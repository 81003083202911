<template>
  <div>
    <div id="content">
      <el-row :gutter="0">
        <el-col :span="12">
          <el-form
            :model="pdata"
            label-width="100px"
            style="width: 460px"
            ref="rev"
          >
            <el-form-item
              label="所属项目"
              prop="pid"
              :rules="[{ required: true, message: '所属项目不能为空' }]"
            >
              <el-select
              filterable 
                v-model="pdata.pid"
                placeholder="请选择所属项目"
                style="width: 100%"
              >
                <el-option
                  v-for="item in options"
                  :key="item.id"
                  :label="item.pname"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="目标总量"
              prop="totalCount"
              :rules="[
                { required: true, message: '目标总量不能为空' },
                { type: 'number', message: '目标总量必须为数字值' },
              ]"
            >
              <el-input
                v-model.number="pdata.totalCount"
                placeholder="请输入目标总量"
              ></el-input>
            </el-form-item>
            <el-form-item label="">
              <el-upload
                v-loading="loading"
                :show-file-list="false"
                class="upload-demo"
                drag
                :action="pdata.upurl"
                :headers="{ Authorization: token }"
                name="reverseData"
                accept=".xls,.xlsx"
                :limit="20"
                :before-upload="beforeAvatarUpload"
                :on-success="onSuccess"
              >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                  将<em>项目数据</em>表格拖到此处，或<em>点击上传</em>
                </div>
                <div class="el-upload__tip" slot="tip">
                  只能上传.xls .xlsx文件
                </div>
              </el-upload>
            </el-form-item>
            <el-form-item v-if="pdata.fileName.length">
              <div style="font-size: 14px; margin-bottom: 5px">
                <img
                  src="@/assets/excel.jpg"
                  style="vertical-align: middle; width: 25px; margin-right: 5px"
                />{{ pdata.fileName }}
              </div>
              <el-button
                type="success"
                size="small"
                @click="reverse"
                :loading="loading2"
                >数据处理</el-button
              >
              <el-button
                plain
                size="small"
                @click="pdowndata(pdata.fileName, 'pvReverseDown')"
                :loading="loading2"
                icon="el-icon-download"
                >数据下载</el-button
              >
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
  <script>
export default {
  name: "PvReverse",
  data() {
    return {
      loading: false,
      loading2: false,
      token: "Bearer " + localStorage.getItem("token"),
      options: [],
      pdata: {
        fileName: "",
        upUrl: "",
        pid: "",
        totalCount: "",
      },
    };
  },
  created() {
    this.getData();
    this.pdata.upurl = this.COMMON.httpUrl + "pvReverseUpload";
  },
  methods: {
    getData() {
      var api = this.COMMON.httpUrl + "pvReverseList";
      var data = {};
      this.axios.post(api, data).then((response) => {
        var rs = response.data;
        if (rs.code == 0) {
          this.options = rs.data;
        }
      });
    },
    beforeAvatarUpload(file) {
      // if (this.pdata.fileName !== "") {
      //   this.$message({
      //     message: "请先删除原数据后再上传",
      //     type: "warning",
      //   });
      //   return false;
      // }
      let fileName = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = fileName === "xls";
      const extension2 = fileName === "xlsx";
      if (!extension && !extension2) {
        this.$message({
          message: "上传文件只能是 xls、xlsx格式!",
          type: "warning",
        });
        return false;
      }
      this.loading = true;
    },
    onSuccess(rs) {
      this.loading = false;
      if (rs.code) {
        this.$message.error(rs.message);
      } else {
        this.pdata.fileName = rs.data;
      }
    },

    reverse() {
      this.$refs["rev"].validate((valid) => {
        if (valid) {
          this.loading2 = true;
          var api = this.COMMON.httpUrl + "pvReverse";
          var data = {
            pid: this.pdata.pid,
            totalCount: this.pdata.totalCount,
            fileName: this.pdata.fileName,
          };
          this.axios.post(api, data).then((response) => {
            var rs = response.data;
            this.loading2 = false;
            if (rs.code) {
              this.$message.error(rs.message);
            } else {
              this.$message.success(rs.message);
            }
          });
        } else {
          return false;
        }
      });
    },
    // 文件下载
    pdowndata(fname, url) {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: this.token,
        },
        body: JSON.stringify({ fileName: fname }),
      };

      let src = this.COMMON.httpUrl + url;
      fetch(src, requestOptions)
        .then((response) => {
          console.log("response.ok:", response);
          if (!response.ok) {
            throw new Error("项目数据不存在，或其他请求异常！");
          }
          return response.blob();
        })
        // .then((resp) => resp.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          // 文件名称可以在这里更改
          a.download = fname;
          a.href = url;
          a.click();
        })
        .catch((error) => {
          // alert(error)
          this.$message.error(error);
        });
    },
  },
};
</script>
  <style scoped>
#content {
  background-color: white;
  width: 1200px;
  text-align: left;
  padding-top: 100px;
  padding-left: 50px;
}

.el-input {
  width: 360px;
}

.el-upload__tip {
  color: rgb(202, 194, 194);
}
</style>
  