<template>
  <div>

    <div id="content" :style="{ height: dynamicHeight + 'px' }">
      <div class="part1">
        <el-row style="padding: 0; margin: 0; background-color: white">
          <el-col :span="7">
            <el-select
              v-model="oldPid"
              filterable 
              placeholder="请选择被关联的项目"
              @change="matchCount"
              size="small"
              :disabled="olded"
            >
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.pcode + '--' + item.pname"
                :value="item.id"
              >
              </el-option>
            </el-select>

            <el-button
              type="success"
              size="small"
              @click="makeGuan"
              :loading="loading1"
              >生成报告</el-button
            > &nbsp;<el-button
              size="small"
              type="primary"
              @click="getGuanData"
              :loading="loading2"
              >展示 / 刷新</el-button
            >
          </el-col>
          <el-col :span="2">
            <div style="padding-top:4px"><el-checkbox v-model="fentou">分表头</el-checkbox>  </div>
              </el-col>
              <el-col :span="15">
            <div class="count" v-show="count.show">
              <span class="sp">匹配数量:</span
              ><span>{{ count.accurate }}</span>              
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="part2">
        <div v-for="(tb, index) in tbs" :key="index" class="tbs">
          <table class="tit">
            <tr>
              <td>
                <span class="numb">[{{ index + 1 }}]</span
                ><b>[{{ tb.tp }}] {{ tb.tkey }} {{ tb.qlable }}</b>
              </td>
            </tr>
          </table>

          <div>
            <table class="hh">
              <!--表头 -->
              <thead>
                <tr v-for="(hs, index) in tb.head" :key="index">
                  <th v-for="(h, index) in hs" :key="index">{{ h }}</th>
                </tr>
              </thead>
              <!-- 表体 -->
              <tbody>
                <tr v-for="(hs, row) in tb.trs" :key="row">
                  <td v-for="(h, col) in hs" :key="col" v-html="h">                    
                  </td>
                </tr>
              </tbody>
            </table>
            <table style="border: 0px !important">
              <tr>
                <td colspan="20" style="border: 0px !important">&nbsp;</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "KpiGuan",
  props: ["kid", "pid"],
  data() {
    return {
      loading1: false,
      loading2: false,
      fentou:false,
      olded:true,
      count: {
        show: false,
        accurate: 0,        
      },
      oldPid: "",
      options: [],
      tou: [],
      tbs: [],
      dynamicHeight: window.innerHeight - 207,
     };
  },
  methods: {
 
    makeGuan() {
      this.loading1=true
      var api = this.COMMON.httpUrl + "kpiGuan";
      var data = {
        pid: this.pid,
        kid: this.kid,
        opid: this.oldPid,
      };
      this.axios.post(api, data).then((response) => {
        this.loading1=false
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        }else{
          this.$message.success(rs.message);
        }
      });
    },
    getGuanData() {
      this.loading2 = true;
      var api = this.COMMON.httpUrl + "kpiGuanData";
      var data = {        
        pid: this.pid,
        kid: this.kid,        
        fentou:this.fentou
      };
      this.axios.post(api, data).then((response) => {
        var rs = response.data;
        this.loading2 = false;
        if (rs.code) {
          this.$message.error(rs.message);      
        } else {
          this.tbs = rs.data.da;
          this.tou = rs.data.tou;          
        }
      });
    },
    getData() {
      var api = this.COMMON.httpUrl + "oldtonewList";
      var data = {};
      this.axios.post(api, data).then((response) => {
        var rs = response.data;
        if (rs.code == 0) {
          this.options = rs.data
          this.olded=false
        }
      });
    },
    matchCount() {
      this.olded=true
      var api = this.COMMON.httpUrl + "oldtonewCount";
      var data = {
        newPid: this.pid,
        oldPid: this.oldPid,
      };
      this.axios.post(api, data).then((response) => {
        this.olded=false
        var rs = response.data;
        if (rs.code == 0) {
          this.count.show = true;
          this.count.accurate = rs.data;          
        }
      });
    },
 
  },

  created() {
    localStorage.setItem("kpitag","two")
    this.getData();
  },
};
</script>
<style scoped>
#content {
  overflow: auto;
  background-color: rgb(255, 255, 255);
}
.el-button {
  margin-left: 10px;
}
.count {
  padding-top: 4px;
  font-size: 16px;
  font-weight: 800;
  color: #67c23a;
}
.count span {
  margin-right: 10px;
}
span.sp {
  margin-left: 20px;
  font-weight: 500;
  color: #999;
}
.part2 {
  margin-top: 20px;
}
.xuhao {
  margin-bottom: 4px;
}
.xuhao span {
  background-color: rgb(237, 177, 103);
  color: white;
  font-size: 13px;
  padding: 3px 8px;
  border-radius: 3px;
}
/* 表格样式 */
table {
  border: 1px solid rgb(176, 175, 175);
  border-collapse: collapse;
}
th,
td {
  border: 1px solid rgb(176, 175, 175);
  border-collapse: collapse;
  min-width: 40px;
  font-size: 13px;
  padding: 2px 0px;
  padding-left: 15px;
  margin: 0;
}
.new th {
  text-align: right;
  padding: 10px 10px;
}
.el-row {
  margin-bottom: 20px;
  padding: 15px 10px;
  background-color: beige;
}
.anl {
  margin-bottom: 20px;
}
/* codeshow */
.codeL,
.codeZ,
.codeR {
  /* background-color: rgb(229, 229, 223); */
}
.zcode {
  margin-bottom: 10px;
}
.tabcode td {
  padding: 10px 15px;
}
.tabcode tr:first-child {
  background-color: rgb(238, 238, 176);
}
p.cs {
  background-color: #f4f4f5;
  padding: 5px;
  margin-bottom: 3px;
}
.list-item {
  display: inline-block;
  margin-right: 10px;
}
.list-enter-active,
.list-leave-active {
  transition: all 1s;
}
.list-enter, .list-leave-to
/* .list-leave-active for below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
.result{
  font-size:16px;
  font-weight: 800;
  color:rgb(20, 204, 20);
  margin-bottom:10px;
}

/* 表格样式 */
table.tit {
  height: 40px;
  font-size: 16px !important;
  border: 0px !important;
  width: 100% !important;
}

table.tit td {
  height: 40px;
  font-size: 16px !important;
  border: 0px !important;
}
.numb {
  font-size: 15px !important;
  background-color: rgb(107, 111, 243);
  color: rgb(245, 241, 146);
  padding: 3px;
  margin-right: 15px;
}
/* .part{
  margin-bottom: 15px;
} */
.tbs {
  margin-top: 0px;
}
table {
  border: 1px solid rgb(176, 175, 175);
  border-collapse: collapse;
}
th,
td {
  border: 1px solid rgb(176, 175, 175);
  border-collapse: collapse;
  min-width: 40px;
  font-size: 13px;
  padding:2px 4px;
  margin: 0;
}
table.tit td {
  padding: 0;
  margin: 0;
}
thead tr {
  border: none !important;
  outline-color: rgb(210, 209, 209);
  outline-style: solid;
  outline-width: 1px;
}
table thead th {
  /* background-color: #c2daf0; 设置表头背景色 */
  background-color: #c2daf0;
  font-weight: 600;
  text-align: center;
}

table tbody td {
  text-align: center;
}
/* 第二列exp左对齐 */
table tbody td:nth-child(2){
 text-align: left;
}

table tr:nth-child(even) {
  background-color: #f2f2f2; /*设置偶数行背景色*/
}

table tr:nth-child(odd) {
  background-color: #fff; /*设置奇数行背景色*/
}

table.hh td:first-child {
  position: sticky !important;
  left: 0 !important;
  z-index: 10 !important;
  background-color: #f2f2f2;
  min-width: 100px !important;
  max-width: 200px !important;
}
table.hh td:nth-child(2) {
  position: sticky !important;
  left: 100px !important;
  z-index: 10 !important;
  background-color: #f2f2f2;
}
table.hh th:first-child {
  position: sticky !important;
  left: 0 !important;
  z-index: 10 !important;
  min-width: 100px !important;
}
table.hh th:nth-child(2) {
  position: sticky !important;
  left: 100px !important;
  z-index: 10 !important;
}
table.hh thead {
  position: sticky !important;
  top: 0;
  z-index: 20 !important;
  background-color: #c2daf0;
}
</style>