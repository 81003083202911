<template>
  <div>
    <div id="content">
      <el-cascader-panel style="width: 200px" v-model="vs" :options="options"   ></el-cascader-panel>
      <el-cascader-panel style="width: 200px" v-model="vs2" :options="options2"  :props="{ multiple: true, checkStrictly: false }" ></el-cascader-panel>
    </div>
  </div>
</template>
<script>
export default {
  name: "PageXxz",
  data() {
    return {
      vs:[],
      vs2:[],
      options2: [],
      ps:{
        选项1:[{value:"11",label:"aa"},{value:"22",label:"bb"}],
        选项2:[{value:"33",label:"cc"},{value:"44",label:"dd"}],
      },
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],

    };
  },
  watch: {
    vs: {
      handler(val) {
       console.log("vs1:",val);
       this.options2=this.ps[val[0]]
      },
    },
    vs2: {
      handler(val) {
       console.log("vs2:",val);
      },
    },
 
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
#content {
  background-color: white;
  width: 900px;
  text-align: left;
  padding-left: 50px;
  padding-top: 50px;
}
</style>

