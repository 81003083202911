<template>
    <div>

        <el-tabs v-model="activeName" type="card">
           
            <el-tab-pane label="错误对照" name="one">
                <ComWrong></ComWrong>
            </el-tab-pane>

            <el-tab-pane label="敏感词" name="two">
                <ComBlackWord></ComBlackWord>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
import ComBlackWord from './ComBlackWord'
import ComWrong from './ComWrong'
export default {
    name: 'ComData',
    props: {
        type: String
    },
    data() {
        return {
            activeName: 'one',
        };
    },
    components: {
        ComBlackWord,ComWrong
    },
    methods: {
    },
    created() {

    },

}
</script>
<style>

</style>