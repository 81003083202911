<template>
  
  <div>
    
    <el-dialog
  title="提示"
  :visible.sync="dialogVisible"
  width="30%">
  <span>datamap中存在scene列,请选择是否删除?</span>
  <span slot="footer" class="dialog-footer">
    <el-button  type="primary"  @click="tagToSceneOn(2)">不删除</el-button>
    <el-button type="danger" @click="tagToSceneOn(1)">删除</el-button>
  </span>
</el-dialog>
    <div id="goback" style="margin-bottom: 10px; font-size: 14px">
      <i
        class="el-icon-back"
        @click="$router.go(-1)"
        style="cursor: pointer; font-size: 14px"
        >返回</i
      >&nbsp;&nbsp;
      <span style="font-size: 14px; color: rgb(192, 196, 204)">|</span>
      &nbsp;&nbsp;<span style="font-size: 14px; color: rgb(192, 196, 204)"
        >编号：</span
      >{{ ppcode }}
      <span style="font-size: 16px; color: rgb(192, 196, 204)">项目：</span
      >{{ ppname }}
    </div>

    <div id="content">
      <el-row :gutter="0">
        <el-col :span="12">
          <el-form label-width="10px">
            <el-form-item label="" class="filediv">
              <el-upload
              v-loading="loading"
                :show-file-list="false"
                class="upload-demo"
                drag
                :action="pdata.upUrl"
                :headers="{ Authorization: token}"
                name="basedata"
                accept=".xls,.xlsx"
                :limit="20"
                :before-upload="beforeAvatarUpload"
                :on-success="onSuccess"
                :data="{pid: pid }"
              >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                  将<em>项目数据</em>表格拖到此处，或<em>点击上传</em>
                </div>
                <div class="el-upload__tip" slot="tip">
                  只能上传.xls .xlsx文件
                </div>
              </el-upload>
            </el-form-item>
            <el-form-item v-if="pdata.fileName.length">
              <div style="font-size: 14px; margin-bottom: 5px">
                <img
                  src="@/assets/excel.jpg"
                  style="vertical-align: middle; width: 25px; margin-right: 5px"
                />{{ pdata.fileName }}  <span class="upinfo" v-show="pdata.upuser!=null && pdata.upuser!=''">由 {{pdata.upuser}}</span><span class="upinfo" v-show="pdata.upuser!=null && pdata.upuser!=''"> 上传于 {{pdata.uptime}}</span>
              </div>
              <el-button
                type="danger"
                size="small"
                @click="del(pdata.fileName)"
                :loading="loading"
                >数据删除</el-button
              >
              <el-button
                type="success"
                size="small"
                @click="tagToScene"
                :loading="loading"
                >数据处理</el-button
              >
              <el-button
                plain
                size="small"
                @click="pdowndata(pdata.fileName,'projectDataDown')"
                :loading="loading"
                icon="el-icon-download"
                >数据下载</el-button
              >
            </el-form-item>
          </el-form>
        </el-col>        
        <el-col :span="12">
          <el-form  label-width="10px">
            <el-form-item label="" class="filediv">
              <el-upload
              v-loading="loading2"
                 :show-file-list="false"
                class="upload-demo"
                drag
                :action="upUrl2"
                :headers="{ Authorization: token}"
                name="otherdata"
                
                :limit="20"
                :before-upload="beforeAvatarUpload2"
                multiple
                :on-success="onSuccess2"
                :data="{pid: pid }"
              >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                  将<em>备份数据</em>表格拖到此处，或<em>点击上传</em>
                </div>
                <div class="el-upload__tip" slot="tip">
                  支持多个文件同时上传
                </div>
              </el-upload>
            </el-form-item>
          </el-form>
          <div v-if="otherData.length">
            <p
              style="
                margin-bottom: 15px;
                padding-bottom: 3px;
                border-bottom: 1px solid #409eff;
                color: #409eff;
                font-size: 16px;
                font-weight: 800;
              "
            >
              备份数据
            </p>
            
             <table class="other" cellspacing=0 cellpadding=0 width="90%">
                <tr v-for="(item,index) in otherData" :key="index">
              <td width="420"><p style="display:flex;align-items:center;"><i class="el-icon-paperclip"></i> &nbsp;{{item}}</p>
            </td><td><el-button type="text" @click="pdowndata(item,'projectDataOhterDown')">下载</el-button></td><td><el-button type="text" @click="del2(item)">删除</el-button></td>
            </tr>
            </table>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
export default {
  name: "ProjectData",
  props: ["pid", "ppname", "ppcode"],
  data() {
    return {
      loading: false,
      loading2: false,
      token:"Bearer " + localStorage.getItem("token"),
      pdata: {
        fileName: "",
        upuser:"",
        uptime:"",
        upUrl: this.COMMON.httpUrl +"projectDataUpload",
      },
      con:"0",
      dialogVisible:false,
      upUrl2: this.COMMON.httpUrl +"projectOtherUpload",
      otherData:[]
    };
  },
  created() {
    this.getData();
    // this.pdata.upurl =
    //   this.COMMON.httpUrl +"projectDataUpload";
    //   this.upUrl2 =
    //   this.COMMON.httpUrl +"projectOtherUpload";
  },
  methods: {
    getData() {
      var api = this.COMMON.httpUrl + "projectData";
      var data = { pid: this.pid };
      this.axios.post(api, data).then((response) => {
        var rs = response.data;
        if (rs.code == 0) {
          this.pdata.fileName = rs.data.fileName;
          this.pdata.upuser = rs.data.upuser;
          this.pdata.uptime = rs.data.uptime;
          this.otherData=rs.data.others;          
        }
      });
    },
    beforeAvatarUpload(file) {
      if (this.pdata.fileName !== "") {
        this.$message({
          message: "请先删除原数据后再上传",
          type: "warning",
        });
        return false;
      }
      let fileName = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = fileName === "xls";
      const extension2 = fileName === "xlsx";
      if (!extension && !extension2) {
        this.$message({
          message: "上传文件只能是 xls、xlsx格式!",
          type: "warning",
        });
        return false;
      }
      this.loading=true
    },
    beforeAvatarUpload2() {

      // let fileName = file.name.substring(file.name.lastIndexOf(".") + 1);
      // const extension = fileName === "xls";
      // const extension2 = fileName === "xlsx";
      // if (!extension && !extension2) {
      //   this.$message({
      //     message: "上传文件只能是 xls、xlsx格式!",
      //     type: "warning",
      //   });
      //   return false;
      // }
      this.loading2=true
    },
    projectDataDel(name) {
      var api = this.COMMON.httpUrl + "projectDataDel";
      var data = { pid: this.pid, fileName: name};
      this.axios.post(api, data).then((response) => {
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          this.$message.success(rs.message);
          this.pdata.fileName = "";
        }
      });
    },
    projectDataOtherDel(name) {
      var api = this.COMMON.httpUrl + "projectDataOtherDel";
      var data = { pid: this.pid, fileName: name};
      this.axios.post(api, data).then((response) => {
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          this.$message.success(rs.message);
          this.otherData=rs.data      
        }
      });
    },
    del(name) {
      this.$confirm("项目数据删除后不可恢复, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.projectDataDel(name);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    del2(name) {
      this.$confirm("项目数据删除后不可恢复, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.projectDataOtherDel(name);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    onSuccess(rs) {
      this.loading=false
      if (rs.code) {
        this.$message.error(rs.message);
      } else {
        // this.$message.success(rs.message);
        this.pdata.fileName = rs.data.filename;
        this.pdata.uptime=rs.data.uptime;
        this.pdata.upuser=rs.data.upuser;
        
      }
    },
    onSuccess2(rs) {
      if (rs.code) {
        this.$message.error(rs.message);
      } else {
        // this.$message.success(rs.message);
        this.otherData.push(rs.data)
      }
      this.loading2=false
    },
    tagToSceneOn(a) {   
      this.con=a
      this.tagToScene()
      this.dialogVisible=false
      this.con="0"
    },
    tagToScene() {      
      this.loading = true;
      var api = this.COMMON.httpUrl + "tagToScene";
      var data = { pid: this.pid ,con:this.con};
      this.axios.post(api, data).then((response) => {
        var rs = response.data;
        this.loading = false;
        if (rs.code) {
          if(rs.code==10){
           this.dialogVisible=true
          }else{
            this.$message.error(rs.message);
          }
          
        } else {
          this.$message.success(rs.message);
        }
      });
    },
    // 文件下载
    pdowndata(fname,url) {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: this.token,
        },
        body: JSON.stringify({ pid: this.pid,fileName:fname }),
      };

      let src = this.COMMON.httpUrl + url;
      fetch(src, requestOptions)
        .then((response) => {
          console.log("response.ok:",response)
          if (!response.ok) {
            throw new Error("项目数据不存在，或其他请求异常！");
          }
          return  response.blob()
        })
        // .then((resp) => resp.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          // 文件名称可以在这里更改
          a.download = fname;
          a.href = url;
          a.click();
        })
        .catch(error => {
            // alert(error)      
            this.$message.error(error);  
        });
    },
  },
};
</script>
<style scoped>
#content {
  background-color: white;
  width: 1200px;
  text-align: left;
  padding-top: 100px;
  padding-left: 100px;
}

.upload-demo {
  width: 300px;
}

.el-input {
  width: 360px;
}

.el-upload__tip {
  color: rgb(202, 194, 194);
}

.filediv {
  /* background-color: bisque; */
  height: 300px;
}

#sceneTipShow {
  background-color: #fff6f7;
  color: #666;
  padding: 10px;
  line-height: 25px;
  font-size: 13px;
}

.hd {
  color: #fe6c6f;
  font-size: 16px;
  font-weight: 800;
  margin: 10px 5px;
}

.hy ul li {
  list-style: none;
  margin-left: 15px;
}

.description {
  font-size: 12px;
  color: rgb(185, 181, 181);
}
.other td{
  border-bottom:1px dashed #e8ecf1;
  height:40px;
  line-height: 40px;
}
.other tr:hover{
  background-color:#f5f7fa;
}
.upinfo{
  color:#afafaf;
  margin-left:10px;
  font-size:12px;
}
</style>
