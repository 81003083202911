<template>
    <div>
        
        <div id="content">
            <el-row>
                <el-col :span="12">
                    <el-form ref="form" label-width="10px">

                        <el-form-item label="" class="filediv">
                            <el-upload v-loading="loading" class="upload-demo" drag :action="upurl" name="dt"
                                accept=".xls,.xlsx" :limit="1" :before-upload="beforeAvatarUpload" multiple
                                :on-success="onSuccess"  :before-remove="beforeRemove" :on-remove="onRemove"
                                ref="upload" >
                                <i class="el-icon-upload"></i>
                                <div class="el-upload__text">将需要处理的表格拖到此处，或<em>点击上传</em></div>
                                <div class="el-upload__tip" slot="tip">只能上传.xls .xlsx文件</div>
                            </el-upload>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="danger" @click="infotomap" :loading="loading"   v-show="transhow">数据处理</el-button>
                            <el-button plain @click="pdowndata" :loading="loading" v-show="pdownshow">数据下载
                            </el-button>


                            <!-- <el-button @click="clear">重置</el-button> -->
                        </el-form-item>
                    </el-form>
                    <div id="sceneTipShow" v-show="sceneTipShow">
                        <p class="hd"><i class="el-icon-warning"></i>异常信息</p>
                        <p class="hy">
                            
                            <ul>
                                <li v-for="(item, index) in  sceneTip" :key="index">
                                    [{{index+1}}] {{item}}
                                </li>
                            </ul>
                        </p>
                    </div>
                </el-col>
            </el-row>

        </div>
    </div>
</template>
<script>
export default {

    name: 'DtExcel',
    data() {
        return {
            loading: false,
            upurl: '',
            transrc:'',
            transhow:false,
            pdownshow: false,
            pdownurl: '',
            sceneTipShow:false,
            sceneTip:[]
        }
    },
    created() {
        this.upurl = this.COMMON.httpUrl + "dtupload?token=" + localStorage.getItem('token')
    },
    methods: {
        
        beforeAvatarUpload(file) {
            let fileName = file.name.substring(file.name.lastIndexOf('.') + 1)

            //    alert(fileName)
            const extension = fileName === 'xls'
            const extension2 = fileName === 'xlsx'
            if (!extension && !extension2) {
                this.$message({
                    message: '上传文件只能是 xls、xlsx格式!',
                    type: 'warning'
                });
                return false
            }

        },
        beforeRemove(file) {
            return this.$confirm(`确定删除数据表格 ${file.name}？`);
        },
        onRemove(){
            this.pdownshow=false
            this.transhow=false
            this.sceneTipShow=false
        },
        infotomap(){
            this.sceneTipShow=false
            this.loading=true
            var api = this.COMMON.httpUrl + "dt"
            var data = { transrc: this.transrc }
            this.axios.post(api, data).then((response) => {
                var rs = response.data
                
                this.loading=false                
                if (rs.code) {                    
                    this.$message.error(rs.message.tips)
                    this.sceneTip=rs.message.logs
                    this.sceneTipShow=true
                  
                } else {
                    this.pdownshow=true
                    this.pdownurl=rs.message
                    this.$message.success("处理完成!")
                }

            })
        },
        onSuccess(res) {
            if (res.code) {
                this.$message.error(res.message)
            } else {
                 this.transrc = res.message
                 this.transhow =true
                // this.$message.success("项目数据上传成功！")
            }

        },
      
        pdowndata() {
            window.location.href = this.COMMON.httpUrl + this.pdownurl
        }

    }

}
</script>
<style scoped>
#content {
    background-color: white;
    width: 900px;
    text-align: left;
    padding-top: 100px;
    padding-left: 100px;

}

.upload-demo {
    width: 300px;
}

.el-input {
    width: 360px;
}

.el-upload__tip {
    color: rgb(202, 194, 194);

}

.filediv {
    /* background-color: bisque; */
    height: 300px;
}
#sceneTipShow{
    background-color:#fff6f7;
    color:#666;
    padding:10px;
    line-height: 25px;
    font-size:13px;
}
.hd{
    color:#fe6c6f;
    font-size:16px;
    font-weight: 800;
    margin:10px 5px;
}
.hy ul li{
    list-style: none;
    margin-left:15px;
}
</style>