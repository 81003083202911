<template>

    <div id="content">


        <div class="login">

            <p class="tit">sign in</p>
            <el-input v-model="name" placeholder="用户名" class="inp"></el-input>
            <el-input v-model="password" placeholder="密码" class="inp" show-password></el-input>
            <el-button type="primary" class="bt" @click="login">登录系统</el-button>



        </div>

        <div class="bei">
            <a href="https://beian.miit.gov.cn/" target="_blank">沪ICP备2023011979号</a>
        </div>


    </div>

</template>
<script>
export default {
    name: 'PageLogin',
    props: {
        type: String
    },
    data() {
        return {
            name: '',
            password: ''
        }
    },
    methods: {

        login() {

            var api = this.COMMON.httpUrl + "checklogin"
            var data = { name: this.name, password: this.password }
            this.axios.post(api, data).then((response) => {
                var rs = response.data

                if (rs.code) {
                    this.$message.error(rs.message);
                } else {

                    const loading = this.$loading({
                        lock: true,
                        text: 'Loading',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    });
                       this.$message.success("登录成功!");
                    
                    var permission=rs.message["permission"]
                    // console.log(typeof perms)
                    // var jsobj=this.jsonx.
                    // var perm=JSON.parse(perms)
                    // pm.projectinfo.pname.create
                    // var projectinfo=pm.projectinfo
                    // console.log(projectinfo.pname.create)
                    localStorage.setItem("permission", permission)
                                    
                       var token=rs.message["token"]
                       var nickname=rs.message["nickname"]
                       var name=rs.message["name"]
                       var levels=rs.message["levels"]
                         localStorage.setItem("token", token)
                         localStorage.setItem("nickname", nickname)
                         localStorage.setItem("name", name)
                         localStorage.setItem("levels", levels)
                    setTimeout(() => {
                        loading.close();
                        this.$router.push('/index');
                    }, 1000)


                }

            })
        },
    }

}
</script>
<style scoped>
#content {
background:  url(@/assets/b1.jpg) no-repeat;
height:100%;
width:100%;
overflow: hidden;
background-size:cover
}

.login {
    width: 500px;
    height: 350px;
    background: red;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -250px 0 0 -250px;

    border-radius: 14px;
    background-color: rgba(255, 255, 255, 0.2);
    box-shadow: 1px 1px 1px #1d5d8a;

    text-align: center;
}

.tit {
    color: white;
    font-size: 30px;
    font-weight: 800;
    padding-top: 40px;
}

.inp {
    width: 300px;
    margin-top: 30px;
}

.bt {
    width: 300px;
    margin-top: 30px;
}
.bei{
    position: fixed; /* 固定在页面上 */  
  bottom:0px; /* 距离页面底部20px */  
  left: 50%; /* 水平居中的基础设置 */  
  transform: translateX(-50%); /* 向左偏移自身宽度的50%，实现完全居中 */  
  width: 100%; /* 设置宽度 */  
  height: 30px; /* 设置高度 */  
  display: flex; /* 使用flex布局 */  
  justify-content: center; /* 水平居中内容 */  
  align-items: center; /* 垂直居中内容 */  
  background-color: rgba(0,0, 0, 0.5);
  
}
.bei a{
    color:#f56c6c;
}

</style>