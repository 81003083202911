<template>
  <div>
    <!-- scene提示框 -->
    <el-dialog title="提示" :visible.sync="dialogCon" width="30%">
      <span>datamap中存在scene列,请选择是否删除?</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="zhuanyiOn(2)">不删除</el-button>
        <el-button type="danger" @click="zhuanyiOn(1)">删除</el-button>
      </span>
    </el-dialog>
    <div id="goback" style="margin-bottom: 10px; font-size: 14px">
      <i
        class="el-icon-back"
        @click="$router.go(-1)"
        style="cursor: pointer; font-size: 14px"
        >返回</i
      >&nbsp;&nbsp;
      <span style="font-size: 14px; color: rgb(192, 196, 204)">|</span>
      &nbsp;&nbsp;<span style="font-size: 16px; color: rgb(192, 196, 204)"
        >编号：</span
      >{{ pcode }}
      <span style="font-size: 14px; color: rgb(192, 196, 204)">项目：</span
      >{{ pname }}
    </div>

    <div id="content">
      <div class="part1">
        <el-upload
          class="upload"
          name="biaozhu"
          :action="upurl"
          :headers="{ Authorization: token }"
          :data="{ pid: pid }"
          auto-upload
          :show-file-list="false"
          :before-upload="beforeAvatarUpload"
          :on-success="onSuccess"
          ><el-button type="info" plain size="small"
            >导入标注模板</el-button
          ></el-upload
        >
        <span style="font-size: 13px; margin-left: 20px">{{ bzname }}</span>
      </div>
      <div class="part1">
        <el-row style="padding: 0; margin: 0; background-color: white">
          <el-col :span="24">
            <el-select
              v-model="oldPid"
              filterable
              placeholder="请选择old项目"
              @change="getRepotal"
              size="small"
            >
              <el-option
                v-for="item in oldPs"
                :key="item.id"
                :label="item.pcode + '--' + item.pname"
                :value="item.id"
              >
              </el-option>
            </el-select>

            &nbsp;
            <el-button type="primary" size="small" @click="accurateMatch"
              >开始匹配</el-button
            >&nbsp;
            <el-button type="info" plain size="mini" @click="delall"
              >清除匹配</el-button
            >&nbsp;
            <el-button
              type="info"
              plain
              size="mini"
              @click="pdowndata('oldtonew.xlsx')"
              >导出</el-button
            ><span style="font-size: 14px; margin: 0 20px"
              >匹配数量:&nbsp;{{ count }}</span
            >
          </el-col>
        </el-row>
      </div>
      <div class="part1">
        <el-row style="padding: 0; margin: 0; background-color: white">
          <el-col :span="24">
            <el-select
              v-model="kid"
              filterable
              placeholder="请选择reportal"
              size="small"
            >
              <el-option
                v-for="item in kis"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option> </el-select
            >&nbsp;&nbsp;
            <el-button type="primary" size="small" @click="transferPv"
              >转移 p 值</el-button
            >&nbsp;
            <el-button type="primary" size="small" @click="transferLogic"
              >转移logic</el-button
            >
            <el-button type="primary" size="small" @click="transferScene"
              >转移scene</el-button
            >
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "OldToNew",
  props: ["pid", "pname", "pcode"],
  data() {
    return {
      upurl: this.COMMON.httpUrl + "oldtonewBiaoImport",
      token: "Bearer " + localStorage.getItem("token"),
      oldPid: "",
      oldPs: [],
      bzname: "",
      kid: "",
      kis: [],
      count: 0,
      con: "0",
      dialogCon: false,
    };
  },
  methods: {
    getData() {
      var api = this.COMMON.httpUrl + "oldtonewList";
      var data = {
        newPid: this.pid,
        oldPid: this.oldPid,
      };
      this.axios.post(api, data).then((response) => {
        var rs = response.data;
        if (rs.code == 0) {
          this.oldPs = rs.data;
        }
      });
    },

    getRepotal() {
      var api = this.COMMON.httpUrl + "oldtonewReportal";
      var data = {
        newPid: this.pid,
        oldPid: this.oldPid,
      };
      this.axios.post(api, data).then((response) => {
        var rs = response.data;
        if (rs.code == 0) {
          this.kis = rs.data.rp;
          this.kid = "";
          this.count = rs.data.z;
        }
      });
    },
    accurateMatch() {
      var api = this.COMMON.httpUrl + "oldtonewAccurate";
      var data = {
        newPid: this.pid,
        oldPid: this.oldPid,
        bzname: this.bzname,
      };
      this.axios.post(api, data).then((response) => {
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          this.$message.success(rs.message);
          this.count = rs.data;
        }
      });
    },

    beforeAvatarUpload(file) {
      let fileName = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = fileName === "xls";
      const extension2 = fileName === "xlsx";
      if (!extension && !extension2) {
        this.$message({
          message: "上传文件只能是 xls、xlsx格式!",
          type: "warning",
        });
        return false;
      }
    },
    onSuccess(res) {
      if (res.code) {
        this.$message.error(res.message);
      } else {
        this.bzname = res.data;
      }
    },
    delall() {
      var api = this.COMMON.httpUrl + "oldtonewClear";
      var data = {
        newPid: this.pid,
        oldPid: this.oldPid,
      };
      this.axios.post(api, data).then((response) => {
        var rs = response.data;
        if (rs.code == 0) {
          this.$message.success(rs.message);
          this.count = 0;
        } else {
          this.$message.error(rs.message);
        }
      });
    },
    transferPv() {
      var api = this.COMMON.httpUrl + "oldtonewTransferPv";
      var data = {
        newPid: this.pid,
        oldPid: this.oldPid,
        kid: this.kid,
      };
      this.axios.post(api, data).then((response) => {
        var rs = response.data;
        if (rs.code == 0) {
          this.$message.success(rs.message);
        } else {
          this.$message.error(rs.message);
        }
      });
    },
    transferLogic() {
      var api = this.COMMON.httpUrl + "oldtonewTransferLogic";
      var data = {
        newPid: this.pid,
        oldPid: this.oldPid,
      };
      this.axios.post(api, data).then((response) => {
        var rs = response.data;
        if (rs.code == 0) {
          this.$message.success(rs.message);
        } else {
          this.$message.error(rs.message);
        }
      });
    },
    zhuanyiOn(a) {
      this.con = a;
      this.transferScene();
      this.dialogCon = false;
      this.con = "0";
    },
    transferScene() {
      if (this.oldPid == "") {
        this.$message.error("请先选择old项目");
        return;
      }
      
      var api = this.COMMON.httpUrl + "oldtonewTransferScene";
      var data = {
        newPid: this.pid,
        oldPid: this.oldPid,
        con: this.con,
      };
      this.axios.post(api, data).then((response) => {
        
        var rs = response.data;
        if (rs.code) {
          if (rs.code == 10) {
            this.dialogCon = true;
          } else {
            this.$message.error(rs.message);
          }
        } else {          
          this.$message.success(rs.message);
        }
      });
    },
    // 文件下载
    pdowndata(fname) {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: this.token,
        },
        body: JSON.stringify({ newPid: this.pid, oldPid: this.oldPid }),
      };

      let src = this.COMMON.httpUrl + "oldtonewDown";
      fetch(src, requestOptions)
        .then((response) => {
          if (!response.ok) {
            // 如果响应不是成功的，尝试读取JSON格式的错误信息
            return response.json().then((errorData) => {
              // 假设错误数据是一个包含code和message的对象
              if (errorData.code && errorData.message) {
                // 抛出错误以便后续的.catch可以捕获
                throw new Error(errorData.message);
              } else {
                // 如果格式不符合预期，可以抛出一个通用的错误
                throw new Error("下载异常");
              }
            });
          }
          return response.blob();
        })
        // .then((resp) => resp.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          // 文件名称可以在这里更改
          a.download = fname;
          a.href = url;
          a.click();
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },
  },

  created() {
    this.getData();
  },
};
</script>
<style scoped>
#content {
  background-color: white;
  padding-top: 20px;
  padding-left: 20px;
}

.part1 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.upload {
  display: inline-block;
}
</style>