<template>
  <div>
    <div id="content">
      <el-row>
        <el-col :span="12"
          ><div class="dleft">
            <div class="anl" style="text-align: right; margin-right: 30px">
              <el-button type="info" plain size="small" @click="reset"
                >重置</el-button
              >
              <el-button size="small" type="primary" @click="add"
                >添加</el-button
              >
            </div>
            <div class="anl">
              <el-row>
                <el-col :span="12">
                  <el-select
                    v-model="openv"
                    placeholder="请选择 open 题"
                    filterable
                    clearable
                    style="width: 90%"
                  >
                    <el-option
                      v-for="item in opens"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="12">
                  <el-select
                    v-model="lpv"
                    placeholder="请选择 loop 题"
                    filterable
                    clearable
                    style="width: 90%"
                  >
                    <el-option
                      v-for="item in lps"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select> </el-col
              ></el-row>
            </div>
            <div class="anl">
              <el-input
                v-model="keywordTop"
                placeholder="角色"
                clearable
                size="mini"
                style="width: 184px; margin-right: 150px"
              ></el-input>
              <el-button type="info" plain size="mini" @click="sync"
                >同类题选项处理</el-button
              >
              <el-button type="info" plain size="mini" @click="selectAll"
                >全选</el-button
              ><el-button
                type="info"
                plain
                size="mini"
                @click="valueQidTop = []"
                >全不选</el-button
              >
            </div>
            <div style="width: 600px">
              <el-cascader-panel
                v-model="valueQidTop"
                :options="optionsFilTop"
                :props="{ multiple: true, checkStrictly: false }"
              ></el-cascader-panel>
            </div>
            <div class="anl">
              <el-input
                v-model="keywordL"
                placeholder="条件"
                clearable
                size="mini"
                style="width: 184px; margin-right: 280px"
              ></el-input>
              <el-button type="info" plain size="mini" @click="selectAllR"
                >全选</el-button
              ><el-button type="info" plain size="mini" @click="valueQidR = []"
                >全不选</el-button
              >
            </div>
            <div>
              <table>
                <tr>
                  <td width="200">
                    <el-cascader-panel
                      v-model="valueQidL"
                      :options="optionsFilL"
                      :props="{ checkStrictly: false }"
                    ></el-cascader-panel>
                  </td>
                  <td td width="400">
                    <el-cascader-panel
                      v-model="valueQidR"
                      :options="optionsFilR"
                      :props="{ multiple: true, checkStrictly: false }"
                    ></el-cascader-panel>
                  </td>
                </tr>
              </table>
            </div>
            <div style="margin-top: 20px">
              <el-button-group>
                <el-button size="mini" type="primary" plain @click="parse(1)"
                  >任一选中</el-button
                >
                <el-button size="mini" type="primary" plain @click="parse(2)"
                  >均未选中</el-button
                >
                <el-button size="mini" type="warning" plain @click="parse(3)"
                  >单项选中</el-button
                >
                <el-button size="mini" type="warning" plain @click="parse(4)"
                  >大于</el-button
                >
                <el-button size="mini" type="warning" plain @click="parse(5)"
                  >大于等于</el-button
                >
                <el-button size="mini" type="warning" plain @click="parse(6)"
                  >小于</el-button
                >
                <el-button size="mini" type="warning" plain @click="parse(7)"
                  >小于等于</el-button
                >
                <el-button size="mini" type="success" plain @click="parse(8)"
                  >and</el-button
                >
                <el-button size="mini" type="success" plain @click="parse(9)"
                  >or</el-button
                >
              </el-button-group>
            </div>
            <div class="part3">
              <el-input
                spellcheck="false"
                placeholder="表达式生成,可编辑"
                type="textarea"
                v-model="product"
                class="product"
                rows="3"
              ></el-input>
            </div></div
        ></el-col>
        <el-col :span="12"
          ><div class="dright">
            <div class="anl">
              <span style="margin-right: 20px; font-size: 13px; color: #afafaf"
                >已创建模板，共 {{ configs.length }} 条</span
              >
              <el-button
                type="danger"
                size="small"
                @click="delall"
                >清空</el-button
              >
              <el-button
                type="primary"
                size="small"
                v-loading="loading3"
                @click="pdowndata('oe.xlsx', 'oeTempMakeDown', '下载异常')"
                >下载OE模板</el-button
              ><el-upload
                class="upload"
                name="oe"
                :action="upurl"
                :headers="{ Authorization: token }"
                :data="{ pid: pid }"
                auto-upload
                :show-file-list="false"
                :before-upload="beforeAvatarUpload"
                :on-success="onSuccess"
                ><el-button  type="info" plain size="small" 
                  >导入逻辑编辑模板</el-button
                ></el-upload
              ><el-link type="primary" :href="tempurl"  style="margin-left:10px">下载导入模板</el-link>
            </div>
            <div
              v-show="configs.length > 0"
              style="height: 600px; width: 750px; overflow: auto"
            >
              <el-table size="mini" row-key="id" :data="configs" fit border>
                <el-table-column prop="open" label="open题" width="80">
                </el-table-column>
                <el-table-column prop="loop" label="loop题" width="80">
                </el-table-column>
                <el-table-column prop="tiaojian" label="条件">
                </el-table-column>
                <el-table-column prop="juese" label="角色"> </el-table-column>
                <el-table-column label="操作" width="80">
                  <template slot-scope="scope">
                    <el-button
                      type="text"
                      size="mini"
                      @click="del(scope.$index, scope.row.id)"
                      >删除</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div></el-col
        >
      </el-row>
    </div>
  </div>
</template>
  <script>
export default {
  name: "OeSentenceTemp",
  props: ["pid"],
  data() {
    return {
      upurl: this.COMMON.httpUrl + "oeTempImport",
      tempurl:this.COMMON.httpUrl + "storage/temp/oeTempImport.xlsx",
      token: "Bearer " + localStorage.getItem("token"),
      loading3: false,

      opens: [],
      openv: "",
      lps: [],
      lpv: "",
      keywordL: "",
      keywordTop: "",
      optionsTop: [], //select选项
      optionsFilTop: [], //筛选后的select选项
      valueQidTop: [], //select选中的值

      optionsL: [], //select选项
      optionsFilL: [], //筛选后的select选项
      valueQidL: [], //select选中的值

      optionsFilR: [], //筛选后的select选项
      valueQidR: [], //select选中的值
      ns: [],

      qidcode: [], //select选中的值对应的属性
      configs: [], //右侧用于存储的配置

      pick: {
        dan: [3, 4, 5, 6, 7],
        duo: [1, 2],
        fu: [8, 9],
        entity: {
          1: "==",
          2: "!=",
          3: "==",
          4: ">",
          5: ">=",
          6: "<",
          7: "<=",
          8: " and ",
          9: " or ",
        },
      },
      valueNew: {
        firstKey: "",
        firstArray: [],
      },
      product: "", //最终解析后的表达式
    };
  },
  watch: {
    keywordTop: {
      handler(val) {
        val = val.toLowerCase();
        this.optionsFilTop = this.optionsTop.filter((p) => {
          return p.label.toLowerCase().indexOf(val) !== -1;
        });
      },
    },
    keywordL: {
      handler(val) {
        val = val.toLowerCase();
        this.optionsFilL = this.optionsL.filter((p) => {
          return p.label.toLowerCase().indexOf(val) !== -1;
        });
      },
    },
    valueQidL: {
      handler(val) {
        this.optionsFilR = this.ns[val[0]];
        this.valueQidR = [];
      },
    },
  },
  methods: {
    parse(sign) {
      // 逻辑运算符
      if (this.pick.fu.includes(sign)) {
        this.product += this.pick.entity[sign];
        return;
      }
      // 是否有选中了
      if (this.valueQidR.length == 0) {
        return;
      }
      this.resetValueQid();
      let firsts = this.valueNew.firstArray;
      let firstk = this.valueNew.firstKey;
      let count = this.valueNew.firstArray.length;

      // 选项数量与运算符数量不匹配
      if (count > 1 && this.pick.dan.includes(sign)) {
        alert("所选运算只能有一个选项值");
        // this.$message.error("所选运算只能有一个选项值");
        return;
      }
      // 组装表达式，只选中了一个的情况
      if (count == 1) {
        let acode = firsts[0];
        let ds = this.qidcode[firstk + "@" + acode];
        // alert(this.valueNew.firstKey+"@"+acode)
        let tp = ds.tp;
        let vid = ds.vid;
        switch (tp) {
          case "multi":
            if (sign == 1 || sign == 3) {
              //单项选中，任一选中（== 1 ）
              this.product += vid + `==1`;
            } else if (sign == 2) {
              // 2024-7-8
              //均未选中 （由 == 0 修改为 !=1 ）
              this.product += vid + `!=1`;
            } else {
              return;
            }
            break;
          case "single":
          case "grid":
            this.product += vid + this.pick.entity[sign] + acode;
            break;
          case "range":
          case "numericlist":
            this.product += vid + this.pick.entity[sign];
            break;
        }
      }
      // 组装表达式，只选中了2个以上的情况
      if (count >= 2) {
        let acode = firsts[0];
        let ds = this.qidcode[firstk + "@" + acode];
        let tp = ds.tp;

        // 任一选中
        if (sign == 1) {
          let exps = [];
          let vid2;
          switch (tp) {
            case "multi":
              firsts.forEach((acode) => {
                let ds = this.qidcode[this.valueNew.firstKey + "@" + acode];
                let vid = ds.vid;
                exps.push(vid + `==1`);
              });
              this.product += exps.join(" or ");
              break;
            case "grid":
            case "single":
              firsts.forEach((acode) => {
                let ds = this.qidcode[this.valueNew.firstKey + "@" + acode];
                vid2 = ds.vid;
                exps.push(acode);
              });
              this.product += vid2 + ` in [` + exps.join() + `]`;
              break;
          }
        }

        // 均未选中
        if (sign == 2) {
          let exps = [];
          switch (tp) {
            case "multi":
              firsts.forEach((acode) => {
                let ds = this.qidcode[this.valueNew.firstKey + "@" + acode];
                let vid = ds.vid;
                // 2024-7-8
              //均未选中 （由 == 0 修改为 !=1 ）
                exps.push(vid + `!=1`);
              });
              this.product += exps.join(" and ");
              break;
            case "grid":
            case "single":
              firsts.forEach((acode) => {
                let ds = this.qidcode[this.valueNew.firstKey + "@" + acode];
                let vid = ds.vid;
                exps.push(vid + `!=` + acode);
              });
              this.product += exps.join(" and ");
              break;
          }
        }
      }

      // 重置选中值
      this.valueQidL = [];
      this.valueNew.firstKey = "";
      this.valueNew.firstArray = [];
    },
    // 根据key把选中值做下分组
    resetValueQid() {
      this.valueNew.firstKey = this.valueQidL[0];
      this.valueNew.firstArray = this.valueQidR;
    },
    resetValueQid22() {
      // console.log("valueQid:",this.valueQid)
      this.valueQid.forEach((ele) => {
        let k = ele[0];
        let v = ele[1];
        if (this.valueNew.firstKey == "") {
          this.valueNew.firstKey = k;
        }
        if (this.valueNew.objQid[k] !== undefined) {
          this.valueNew.objQid[k].push(v);
        } else {
          this.valueNew.objQid[k] = [v];
        }
      });
      this.valueNew.firstArray = this.valueNew.objQid[this.valueNew.firstKey];
      delete this.valueNew.objQid[this.valueNew.firstKey];
    },
    getQid() {
      // this.$message.warning("数据加载中，请稍候");
      var api = this.COMMON.httpUrl + "oeTempOption";
      var data = {
        pid: this.pid,
      };
      this.axios.post(api, data).then((response) => {
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          this.opens = rs.data.opens;
          this.lps = rs.data.lps;
          this.optionsTop = rs.data.bs;
          this.optionsFilTop = rs.data.bs;

          this.optionsL = rs.data.ms;
          this.optionsFilL = rs.data.ms;

          this.ns = rs.data.ns;
          this.qidcode = rs.data.ds;
          this.configs = rs.data.cfg;
        }
      });
    },
    // 文件下载
    pdowndata(fname, url, er) {
      this.loading3=true
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: this.token,
        },
        body: JSON.stringify({ pid: this.pid}),
      };

      let src = this.COMMON.httpUrl + url;
      fetch(src, requestOptions)
        .then((response) => {
          this.loading3=false
          if (!response.ok) {
            // 如果响应不是成功的，尝试读取JSON格式的错误信息
            return response.json().then((errorData) => {
              // 假设错误数据是一个包含code和message的对象
              if (errorData.code && errorData.message) {
                // 抛出错误以便后续的.catch可以捕获
                throw new Error(errorData.message);
              } else {
                // 如果格式不符合预期，可以抛出一个通用的错误
                throw new Error(er || "未知错误");
              }
            });
          }
          return response.blob();
        })
        // .then((resp) => resp.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          // 文件名称可以在这里更改
          a.download = fname;
          a.href = url;
          a.click();
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },
    selectAll() {
      this.valueQidTop = [];
      for (let v1 of this.optionsFilTop) {
        for (let v2 of v1.children) {
          this.valueQidTop.push([v1.value, v2.value]);
        }
      }
    },
    selectAllR() {
      let x = [];
      for (let v1 of this.optionsFilR) {
        x.push([v1.value]);
      }
      this.valueQidR = x;
    },
    sync() {
      if (this.valueQidTop.length == 0) {
        alert("当前题选项不能为空");
        return;
      }
      // //筛选出第一个Qid及选项acode
      let qidFirst = "";
      let codes = [];
      let qidT = [];
      for (let v of this.valueQidTop) {
        let qid = v[0];
        let acode = v[1];
        if (qidFirst == "") {
          qidFirst = qid;
        }
        if (qidFirst == qid) {
          // 第一个题，收集acode
          codes.push(acode);
        } else {
          // 后面的题,收集qid
          if (!qidT.includes(qid)) {
            qidT.push(qid);
          }
        }
      }
      // 重置acode
      let vs = [];
      for (let a of codes) {
        vs.push([qidFirst, a]);
      }
      for (let q of qidT) {
        for (let a of codes) {
          vs.push([q, a]);
        }
      }
      this.valueQidTop = vs;
    },
    add() {
      if (this.openv == "") {
        alert("open题不能为空");
        return;
      }
      // if (this.valueQidTop.length == 0) {
      //   alert("角色不能为空");
      //   return;
      // }
      // if (this.product.trim() == "") {
      //   alert("条件不能为空");
      //   return;
      // }
      // 角色，每个acode都对应一个表达式，用#分割
      let juese = [];
      for (let j of this.valueQidTop) {
        let qid = j[0];
        let acode = j[1];
        let ds = this.qidcode[qid + "@" + acode];
        let tp = ds.tp;
        let vid = ds.vid;

        let jue = "";
        switch (tp) {
          case "multi":
            jue = vid + `==1`;
            break;
          case "single":
          case "grid":
            jue = vid + `==` + acode;
            break;
        }
        juese.push(jue.trim());
      }
      let cfg = {};
      cfg.pid = this.pid;
      cfg.open = this.openv;
      cfg.loop = this.lpv;
      cfg.juese = juese.join("#");
      cfg.tiaojian = this.product;
      this.save(cfg);
    },

    // 提交保存
    save(cfg) {
      this.loading = true;
      var api = this.COMMON.httpUrl + "oeTempAdd";
      var data = cfg;
      this.axios.post(api, data).then((response) => {
        this.loading = false;
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          this.$message.success(rs.message);
          cfg.id=rs.data;
          this.configs.push(cfg);
        }
      });
    },
    downtemp() {
      this.loading = true;
      var api = this.COMMON.httpUrl + "oeTempDown";
      var data = { pid: this.pid };
      this.axios.post(api, data).then((response) => {
        this.loading = false;
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          this.$message.success(rs.message);
        }
      });
    },
    reset() {
      // 重置
      this.openv = "";
      this.lpv = "";
      this.valueQidTop = [];
      this.product = "";
      this.keywordTop = "";
      this.keywordL = "";
    },
    del(index, tid) {
      var api = this.COMMON.httpUrl + "oeTempDel";
      var data = {
        pid: this.pid,
        tid: tid,
      };
      this.axios.post(api, data).then((response) => {
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          this.configs.splice(index, 1);
          this.$message.success(rs.message);
        }
      });
    },
    delall() {
      var api = this.COMMON.httpUrl + "oeTempClear";
      var data = {
        pid: this.pid,
      };
      this.axios.post(api, data).then((response) => {
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          this.configs = [];
          this.$message.success(rs.message);
        }
      });
    },
    beforeAvatarUpload(file) {
      this.loading = true;
      let fileName = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = fileName === "xls";
      const extension2 = fileName === "xlsx";
      if (!extension && !extension2) {
        this.$message({
          message: "上传文件只能是 xls、xlsx格式!",
          type: "warning",
        });
        this.loading = false;
        return false;
      }
    },
    onSuccess(res) {
      if (res.code) {
        this.$message.error(res.message);
      } else {
        this.$message.success(res.message);
        this.configs = res.data;
      }
      this.loading = false;
    },
  },
  created() {
    this.getQid();
  },
};
</script>
<style scoped>
#content {
  background-color: white;
  padding-top: 0px;
}
.dleft {
  width: 630px;
}
.anl {
  margin-bottom: 10px;
  margin-top: 25px;
}

.part3 {
  margin-top: 15px;
}
.product {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  width: 600px;
}
::v-deep .el-cascader-menu__wrap {
  height: 220px;
}
.upload {
  display: inline-block;
  margin: 0 8px;
}
</style>
 