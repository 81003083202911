<template>
    <div>
        <div id="content">            
            <el-button plain size="small" @click="jietu">截图</el-button>
            <div class="image" v-show="imgshow">
                <el-upload :action="upurl" name="img" :data="params" list-type="picture-card" :auto-upload="true" :file-list="filelist">
                    <i slot="default" class="el-icon-plus"></i>
                    <div slot="file" slot-scope="{file}">
                        <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
                        <span class="el-upload-list__item-actions">
                            <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                                <i class="el-icon-zoom-in"></i>
                            </span>                                                        
                        
                        </span>
                    </div>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible">
                    <img width="100%" :src="dialogImageUrl">
                </el-dialog>
            </div>

            <div class="part1">
                <el-row :gutter="20">
                    <el-col :span="6">
                        <div>
                            <el-statistic title="号码总数（条）">
                                <template slot="formatter"> {{ total.count }} </template>
                            </el-statistic>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div>
                            <el-statistic title="当前速率（条/秒）">
                                <template slot="formatter"> {{ total.speed }} <span class="fm">繁忙</span></template>

                            </el-statistic>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div>
                            <el-statistic title="预计耗时">
                                <template slot="formatter"> {{ total.haoshi }} </template>
                            </el-statistic>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div>
                            <el-statistic title="当前进度">
                                <template slot="formatter">已完成 {{ total.jindu }}% </template>
                            </el-statistic>
                        </div>
                    </el-col>
                </el-row>

            </div>
            <div class="part2">
                <div class="gd">
                    <el-table :data="tableData" border height="300" ref="table" cell-class-name="table_cell">
                        <el-table-column v-for="item in tableHead" :label="item.label" :key="item.label"
                            :property="item.property"></el-table-column>
                    </el-table>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: 'SmsShow',
    props: ['sid'],
    data() {
        return {
            upurl:'',
            params:{},
            imgshow:false,            
            filelist:[],
            dialogImageUrl: '',
            dialogVisible: false,
            disabled: false,
            total: {
                count: 0,
                speed: 0,
                haoshi: 0,
                jindu: 0
            },
            tableHead: [],
            tableData: []
        }
    },
    mounted() {
        this.getInfo()
        this.scroll()
        this.upurl = this.COMMON.httpUrl + "smsimgupload?token=" + localStorage.getItem('token')
        this.params={tid:this.sid}
    },
    methods: {
        jietu(){
            if (this.imgshow){
                this.imgshow=false
            }else{
                this.imgshow=true
            }
            
        },
        handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
      },
        scroll() {
            // 拿到表格挂载后的真实DOM
            const table = this.$refs.table
            // 拿到表格中承载数据的div元素
            const divData = table.bodyWrapper
            // 拿到元素后，对元素进行定时增加距离顶部距离，实现滚动效果(此配置为每100毫秒移动1像素)
            setInterval(() => {
                // 元素自增距离顶部1像素
                divData.scrollTop += 1
                // 判断元素是否滚动到底部(可视高度+距离顶部=整个高度)
                if (divData.clientHeight + divData.scrollTop == divData.scrollHeight) {
                    // 重置table距离顶部距离
                    divData.scrollTop = 0
                }
            }, 100)
        },
        getInfo() {
            let api = this.COMMON.httpUrl + "smsinfo"
            let data = { sid: this.sid }
            this.axios.post(api, data).then((response) => {
                let rs = response.data
                if (rs.code) {
                    this.$message.error(rs.message);
                } else {
                    this.total = rs.message.total
                    this.tableHead = rs.message.tableHead
                    this.tableData = rs.message.tableData
                    this.filelist=rs.message.urls
                }

            })
        }
    }
}
</script>
  
<style scoped>
#content {
    background-color: white;
    width: 1450px;
    text-align: left;
    padding-left: 50px;

}
.image{
    margin-top:20px;
}
.part1 {
    margin-top: 30px;
    width: 90%;
    border: 0px solid red;
    background-color: antiquewhite;
    padding: 30px;
}

.part2 {
    margin-top: 30px;
    width: 90%;
    height: 300px;
    overflow: hidden;
    border: 0px solid red;
    background-color: rgba(250, 246, 246, 0.887);
    padding: 30px;
}

.fm {
    border-radius: 3px;
    font-size: 10px;
    padding: 2px;
    margin-top: -10px !important;
    color: rgb(255, 255, 255);
    background-color: rgb(224, 68, 89);
}

/* /deep/ .el-table__body-wrapper {

    background-color: #fff !important;
    overflow-y: hidden !important;

} */
</style>
<style >
.table_cell .cell {
    white-space: nowrap;
}
</style>
 