import Vue from 'vue'
import App from './App.vue'
//引入axios
import axios from 'axios'
import VueAxios from 'vue-axios'
//引入组件库
import jvuewheel from '@jyeontu/jvuewheel'
//引入样式
import '@jyeontu/jvuewheel/lib/jvuewhell.css'
Vue.use(jvuewheel);
Vue.use(VueAxios, axios)
    //引入elementUi组件库
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import global from './components/PageCommon.vue'
Vue.prototype.COMMON = global

//引入 vuerouter1组件
import VueRouter from 'vue-router'
//引入自己定义的路由器
import router from './router'
//关闭提示
Vue.config.productionTip = false

//应用element插件
Vue.use(ElementUI);

//应用路由插件
Vue.use(VueRouter)


// 请求在到达服务器之前，先会调用use中的这个回调函数来添加请求头信息
axios.interceptors.request.use(config => {

    // 为请求头对象，添加token验证的token字段
    // 获取token
    const token = localStorage.getItem('token')
    if (token) {
        // 如果存在令牌这添加token请求头
        config.headers.Authorization = 'Bearer ' + token;
    }
    return config;
})

// 响应拦截器
axios.interceptors.response.use(null, err => {

    if (err.response.status === 507) {
        localStorage.removeItem('token')
        alert("登录已经过期，请重新登录！")
        router.push("/login");
    }
    if (err.response.status === 500) {
        alert(err.response.data.message)
    }
    return Promise.reject(err);
});


new Vue({
    render: h => h(App),
    router: router
}).$mount('#app')