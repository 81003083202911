<template>
  <div>
    <div id="goback" style="margin-bottom: 10px; font-size: 14px">
      <i
        class="el-icon-back"
        @click="$router.go(-1)"
        style="cursor: pointer; font-size: 14px"
        >返回</i
      >&nbsp;&nbsp;
      <span style="font-size: 14px; color: rgb(192, 196, 204)">|</span>
      &nbsp;&nbsp;<span style="font-size: 16px; color: rgb(192, 196, 204)"
        >编号：</span
      >{{ pcode }}
      <span style="font-size: 14px; color: rgb(192, 196, 204)">项目：</span
      >{{ pname }}
    </div>

    <div id="content">

      <div class="part1">
        <div class="con">          
          <span class="t">项目状态:</span>&nbsp;&nbsp;<span> test</span>          
          <span>远程ftp目录名：jinan023</span>

          <el-divider></el-divider>
        </div>        
        
        <div class="con">          
          <span class="t">Links:</span>

          &nbsp;&nbsp;&nbsp; &nbsp; &nbsp;<el-button  type="info" size="small" plain   @click="pdowndata('ai句子.xlsx', 'oeAiTextDown', '下载异常',scope.row.id)">下载</el-button>
          &nbsp;&nbsp;&nbsp;
          <el-upload
          class="upload"
          name="biaozhu"
          :action="upurl"
          :headers="{ Authorization: token }"
          :data="{ pid: pid }"
          auto-upload
          :show-file-list="false"
          :before-upload="beforeAvatarUpload"
          :on-success="onSuccess"
          ><el-button type="info" plain   size="small"
            >导入标注模板</el-button
          ></el-upload> &nbsp;&nbsp;&nbsp;
          <span>老王 于2024-12-04 上传</span>
          <el-divider></el-divider>
        </div>   

        <div class="con">          
          <span class="t">项目数据:</span>
          &nbsp;&nbsp;<el-button  type="info" plain  size="small">datamap 同步</el-button>          
          <el-button  type="info" plain  size="small">logic 同步</el-button>            
          <el-divider></el-divider>       
        </div>   
        
        <div class="con">          
          <span class="t">deversity:</span>
          <el-input   placeholder="请输入内容" style="width:400px"  size="small"></el-input>&nbsp;&nbsp;
          <el-button  type="info" plain  size="small" >生成并处理</el-button>       
          <el-divider></el-divider>
        </div>   

        <div class="con">          
          <span class="t">跑数程序:</span>&nbsp;&nbsp;&nbsp;<el-input v-model="paoparam.total" placeholder="总数:"  size="small" style="width:200px"></el-input>
          <div style="padding-left:100px"><br>
          <el-input v-model="paoparam.threads" placeholder="并发数:"  size="small" style="width:200px"></el-input><br><br>
          <el-input v-model="paoparam.step" placeholder="震荡步长:" size="small" style="width:200px"></el-input><br><br>
          <el-button  type="primary"  @click="remoteRun"  size="small">运行程序</el-button>
          <span style="margin-left:50px">当前进度：{{jindu}}</span>
          <span style="margin-left:50px"><router-link to="23">远程账号密码设置</router-link></span>
          <span style="margin-left:20px"><router-link to="23">运行日志</router-link></span>      
        </div>
          
          <el-divider></el-divider>
        </div>   
      </div>
      

      

    <div class="part1"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "RemoteSet",
  props: ["pid", "pname", "pcode"],
  data() {
    return {
      upurl: this.COMMON.httpUrl + "oldtonewBiaoImport",
      token: "Bearer " + localStorage.getItem("token"),
      paoparam:{
        total:"",
        threads:"",
        delay:"0",
        step:""
      },
      jindu:"0/0"
    };
  },
  methods: {
    remoteRun() {
      if (this.paoparam.total.trim().length==0 ||this.paoparam.threads.trim().length==0){
        alert("总数和并发数不能为空！")
        return
      }      
      var api = this.COMMON.httpUrl + "remoteRun";
      var data = this.paoparam
         data.pid=this.pid        
      this.axios.post(api, data).then((response) => {
        var rs = response.data
        if (rs.code) {
          this.$message.error(rs.message)
        } else {
          this.$message.success(rs.message)
          this.jindu=rs.data
        }
      });
    },
    getjindu() {      
      var api = this.COMMON.httpUrl + "remoteJinDu";
      var data = {pid:this.pid}               
      this.axios.post(api, data).then((response) => {
        var rs = response.data
        if (rs.code) {
          this.$message.error(rs.message)
        } else {
          this.jindu=rs.data
        }
      });
    },
    getRepotal() {
      var api = this.COMMON.httpUrl + "oldtonewReportal";
      var data = {
        newPid: this.pid,
        oldPid: this.oldPid,
      };
      this.axios.post(api, data).then((response) => {
        var rs = response.data;
        if (rs.code == 0) {
          this.kis = rs.data.rp;
          this.kid = "";
          this.count = rs.data.z;
        }
      });
    },
    accurateMatch() {
      var api = this.COMMON.httpUrl + "oldtonewAccurate";
      var data = {
        newPid: this.pid,
        oldPid: this.oldPid,
        bzname: this.bzname,
      };
      this.axios.post(api, data).then((response) => {
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          this.$message.success(rs.message);
          this.count = rs.data;
        }
      });
    },

    beforeAvatarUpload(file) {
      let fileName = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = fileName === "xls";
      const extension2 = fileName === "xlsx";
      if (!extension && !extension2) {
        this.$message({
          message: "上传文件只能是 xls、xlsx格式!",
          type: "warning",
        });
        return false;
      }
    },
    onSuccess(res) {
      if (res.code) {
        this.$message.error(res.message);
      } else {
        this.bzname = res.data;
      }
    },
    delall() {
      var api = this.COMMON.httpUrl + "oldtonewClear";
      var data = {
        newPid: this.pid,
        oldPid: this.oldPid,
      };
      this.axios.post(api, data).then((response) => {
        var rs = response.data;
        if (rs.code == 0) {
          this.$message.success(rs.message);
          this.count = 0;
        } else {
          this.$message.error(rs.message);
        }
      });
    },
    transferPv() {
      var api = this.COMMON.httpUrl + "oldtonewTransferPv";
      var data = {
        newPid: this.pid,
        oldPid: this.oldPid,
        kid: this.kid,
      };
      this.axios.post(api, data).then((response) => {
        var rs = response.data;
        if (rs.code == 0) {
          this.$message.success(rs.message);
        } else {
          this.$message.error(rs.message);
        }
      });
    },
    transferLogic() {
      var api = this.COMMON.httpUrl + "oldtonewTransferLogic";
      var data = {
        newPid: this.pid,
        oldPid: this.oldPid,
      };
      this.axios.post(api, data).then((response) => {
        var rs = response.data;
        if (rs.code == 0) {
          this.$message.success(rs.message);
        } else {
          this.$message.error(rs.message);
        }
      });
    },
    zhuanyiOn(a) {
      this.con = a;
      this.transferScene();
      this.dialogCon = false;
      this.con = "0";
    },
    transferScene() {
      if (this.oldPid == "") {
        this.$message.error("请先选择old项目");
        return;
      }
      
      var api = this.COMMON.httpUrl + "oldtonewTransferScene";
      var data = {
        newPid: this.pid,
        oldPid: this.oldPid,
        con: this.con,
      };
      this.axios.post(api, data).then((response) => {
        
        var rs = response.data;
        if (rs.code) {
          if (rs.code == 10) {
            this.dialogCon = true;
          } else {
            this.$message.error(rs.message);
          }
        } else {          
          this.$message.success(rs.message);
        }
      });
    },
    // 文件下载
    pdowndata(fname) {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: this.token,
        },
        body: JSON.stringify({ newPid: this.pid, oldPid: this.oldPid }),
      };

      let src = this.COMMON.httpUrl + "oldtonewDown";
      fetch(src, requestOptions)
        .then((response) => {
          if (!response.ok) {
            // 如果响应不是成功的，尝试读取JSON格式的错误信息
            return response.json().then((errorData) => {
              // 假设错误数据是一个包含code和message的对象
              if (errorData.code && errorData.message) {
                // 抛出错误以便后续的.catch可以捕获
                throw new Error(errorData.message);
              } else {
                // 如果格式不符合预期，可以抛出一个通用的错误
                throw new Error("下载异常");
              }
            });
          }
          return response.blob();
        })
        // .then((resp) => resp.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          // 文件名称可以在这里更改
          a.download = fname;
          a.href = url;
          a.click();
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },
  },

  created() {
     this.getjindu()
  },
};
</script>
<style scoped>
#content {
  background-color: white;
  padding-top: 20px;
  padding-left: 20px;
}

.part1 {
  margin-top: 20px;
  margin-bottom: 20px;
}
.con{
  font-size:14px;
  width:1000px;
}
.con span.t {
  font-size:16px;
  font-weight: 800;
}
.con span{
  margin-right:20px;
}
.upload {
  display: inline-block;
}
</style>