<template>

    <div>
        <div id="content">
            <div class="part1">


                <el-input style="width:500px" type="textarea" :rows="10" v-model="words" placeholder="请输入句子中需要检测的敏感词,一行一个">
                </el-input><br><br>

                <el-button type="primary" @click="onSubmit" :loading="btload">提交保存</el-button>
            </div>

        </div>
    </div>
</template>
<script>
export default {
    name: 'ComBlackWord',
    data() {
        return {
            btload: false,
            words: ''
        }
    },
    methods: {
 
         onSubmit() {
            this.btload = true
            var api = this.COMMON.httpUrl + "blackadd"
            var data = {
                words: this.words
            }
            this.axios.post(api, data).then((response) => {
                this.btload = false
                var rs = response.data
                if (rs.code) {
                    this.$message.error(rs.message);
                } else {
                    this.$message.success(rs.message)

                }

            })

        }

    },
  
    created() {


        var api = this.COMMON.httpUrl + "blackget"
        var data = {}

        this.axios.post(api, data).then((response) => {
            var rs = response.data
            if (rs.code) {
                this.$message.error(rs.message);
            } else {
                this.words = rs.message
            }
        })
    },
}
</script>
<style scoped>
  #content {
background-color: white;
    padding-top: 100px;
    padding-left: 100px;
}

.inp {
    width: 180px;
    margin-right: 10px;
}

.op {
    padding-bottom: 20px;
}

.part2 {
    margin-top: 100px;
}

.el-descriptions {
    color: #409EFF;
}
</style>