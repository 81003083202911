<template>
    <div>
        <div id="content">
            <div class="hello"><i>欢迎使用晨星平台</i></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HomeIndex',
    data() {
        return {
            
        }
    },
    created() {
               
    },
    methods: {
     
    }
}
</script>
  
<style scoped>
#content {
    
    /* background: url('@/assets/home.png') left top no-repeat; */
    /* background-size: 500px auto; */
    width: 1200px;
    height:900px;
   
    text-align: left;
    padding-left: 0px;
    background-color: white;

}
.hello{
    font-family: '微软雅黑';
    color:rgb(241 225 229);
    font-size:38px;
    text-align: left;
    padding-left:300px;
    margin-top:150px;

}
 
</style>