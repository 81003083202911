<template>

    <div>
  
        <div id="content">

            <el-form ref="form" :model="form"   label-width="120px" style="width:600px">
                <el-form-item label="用户名" prop="name">
                    <el-input v-model="form.name" disabled></el-input>
                </el-form-item>

                <el-form-item label="昵称" prop="nickname">
                    <el-input v-model="form.nickname" disabled></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="password">
                    <el-input v-model="form.password"  show-password placeholder="如需修改，请输入新的密码">
                    </el-input>
                </el-form-item>
              
                <el-form-item>
                    <el-button type="primary" @click="submitForm()" :loading="loading">提交保存</el-button>
                    <el-button @click="$router.back()">取消</el-button>
                </el-form-item>
            </el-form>

        </div>
    </div>
</template>
<script>
export default {

    name: 'PageSet',
    data() {
        return {
            loading: false,
            form: {
                name: '',
                nickname:'',
                password: ''
            },

           
           
        }
    },
    mounted() {
       
        this.form.nickname= localStorage.getItem('nickname')
        this.form.name= localStorage.getItem('name')
    },
    methods: {

      

        submitForm() {

            var api = this.COMMON.httpUrl + "userset"
            var data = { password: this.form.password}
            this.axios.post(api, data).then((response) => {
                var rs = response.data

                if (rs.code) {
                    this.$message.error(rs.message);
                } else {                    
                    this.$message.success("修改成功!");
            
                }

            })



        }

    }

}
</script>
<style scoped>
#content {
    background-color: white;
    width: 900px;
    text-align: left;
    padding-left: 50px;
    padding-top:50px;

}
</style>