<template>
    <div>
        <div id="content">
            <div class="op">                
                <el-button @click="$router.back()" size="small">返回</el-button>
                <el-upload class="upload" name="reportal" :data="params" :action="upurl" :limit="1" auto-upload :show-file-list="false"
                    :before-upload="beforeAvatarUpload" :on-success="onSuccess">
                    <el-button  size="small" type="danger" icon="el-icon-upload2">导入</el-button>
                </el-upload>
            </div>

            <div class="reportal">
                <el-descriptions class="margin-top" title="项目报告" :column="3" border>

                    <template slot="extra">
                        <el-button type="warning" size="small" icon="el-icon-delete" @click="clear">清除预览</el-button>
                    </template>
                    <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-house"></i>
                            项目名称
                        </template>
                        {{ info.pname }}
                    </el-descriptions-item>
                  
                    <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-date"></i>
                            创建日期
                        </template>
                        {{ info.reportal_time }}
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-s-data"></i>
                            预览
                        </template>
                      
                        <el-link type="primary" :href="info.view" target="_blank">  <el-tag size="small">预览</el-tag></el-link>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-office-building"></i>
                            预览地址
                        </template>
                        {{ info.view }}
                    </el-descriptions-item>
                </el-descriptions>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: 'ReportalShow',
    props: ['sid'],
    data() {
        return {
            params:{},
            upurl: '',
            info: {
                pname: '',
                pcode: '',
                reportal_time: '',
                view: ''
            }

        }
    },
    mounted() {
        this.getInfo()
        this.upurl = this.COMMON.httpUrl + "reportalup?token=" + localStorage.getItem('token')
        this.params = { sid: this.sid }
    },
    methods: {
        beforeAvatarUpload(file) {

            let fileName = file.name.substring(file.name.lastIndexOf('.') + 1)

            //    alert(fileName)
            const extension = fileName === 'xls'
            const extension2 = fileName === 'xlsx'
            if (!extension && !extension2) {
                this.$message({
                    message: '上传文件只能是 xls、xlsx格式!',
                    type: 'warning'
                });
                return false
            }

        },
        clear() {
            let api = this.COMMON.httpUrl + "reportalclear"
            let data = { sid: this.sid }
            this.axios.post(api, data).then((response) => {
                let rs = response.data
                if (rs.code) {
                    this.$message.error(rs.message);
                } else{
                    this.info.reportal_time=""
                }

            })
        },
 
        getInfo() {
            let api = this.COMMON.httpUrl + "reportal"
            let data = { sid: this.sid }
            this.axios.post(api, data).then((response) => {
                let rs = response.data
                if (rs.code) {
                    this.$message.error(rs.message);
                } else {
                    this.info = rs.message
                }

            })
        },
        onSuccess(res) {
            if (res.code) {
                this.$message.error(res.message)
            } else {               
                 this.$message.success("导入成功！")
                 this.info.reportal_time=res.message

            }

        }
    }
}
</script>
  
<style scoped>
#content {
    background-color: white;
    width: 1200px;
    text-align: left;
    padding-left: 50px;

}

.op {
    padding: 20px 0;
}

.upload {
    display: inline-block;
    margin-left: 15px;
}

.reportal {
    margin-top: 40px;
}
</style>
 
 