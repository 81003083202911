<template>
  <div>
    <div id="content">
      <div id="head">
        <div class="part1">
          <el-select v-model="filter" placeholder="请选择类型" size="small">
            <el-option
              v-for="item in tps"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
          &nbsp;
          <el-button v-loading="load" type="info" plain  size="small" @click="getLgs">刷新</el-button
          >  &nbsp;  &nbsp;  &nbsp;
          <el-select v-model="csd" placeholder="请选择同步的子项目" size="small" clearable multiple>
            <el-option
              v-for="item in cs"
              :key="item.id"
              :label="item.pname"
              :value="item.id"
            >
            </el-option>
          </el-select>  &nbsp;
          <el-button type="info" plain v-loading="load" size="small" @click="tongbu">同步</el-button
          >  &nbsp;  &nbsp;  &nbsp;
          <el-upload class="upload"  v-loading="load" name="lgs" :action="upurl" auto-upload :show-file-list="false"
                            :before-upload="beforeAvatarUpload" :on-success="onSuccess"><el-button type="success"
                                size="small" >导入</el-button></el-upload>
          
          <el-button type="warning"  v-loading="load" size="small" @click="down">导出</el-button
          >
         
            <span style="font-size:12px;color:#999;margin-left:20px">当前共 <b>{{count}}</b> 条记录</span>
            <span class="upinfo" v-show="upinfo.upuser!='' && upinfo.upuser!=null">由 {{upinfo.upuser}} 上传于 {{upinfo.uptime}}</span>
        </div>
        <div class="part2">
            <el-input type="text" v-model="web.id" placeholder="ID" class="c1" size="small"></el-input>
            <el-input type="text" v-model="web.key" placeholder="KEY"  class="c1" size="small"></el-input>
            <el-input type="text" v-model="web.values" placeholder="VALUES" class="c1" size="small"></el-input>
            <el-select v-model="web.type" placeholder="type" size="small">
            <el-option
              v-for="item in tps2"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select> &nbsp;
          <el-button type="primary" size="small" @click="add"
            >添加</el-button>
            &nbsp; &nbsp;
            <el-input type="text" v-model="start" placeholder="start aid" class="c1" size="small"></el-input>
            <el-input type="text" v-model="end" placeholder="end aid"  class="c1" size="small"></el-input>
            
          <el-button type="danger" size="small" @click="del"
            >删除</el-button>
        </div>
      </div>
      <div id="tabs">

        <template>
  <el-table
    :data="lgs"
    stripe
    border
    size="mini"
    style="width: 100%">
    <el-table-column
      prop="aid"
      sortable
      label="AID"
      width="100">
    </el-table-column>
    <el-table-column
    sortable
      prop="type"
      label="TYPE"
      width="170">
    </el-table-column>
    <el-table-column
    sortable
      prop="id"
      label="ID"
      width="100">
    </el-table-column>
    <el-table-column
    sortable
      prop="key"
      label="KEY"
      width="300">
    </el-table-column>
    <el-table-column
      sortable
      prop="values"
      label="VALUES"
      width="319">
    </el-table-column>
    <el-table-column
      prop="source"
      label="SOURCE"
      width="160">
    </el-table-column>
  
  </el-table>
</template>

      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "LogicList",
  props: ["pid"],
  data() {
    return {
      upurl: "",
      load:false,
      web:{id:"",key:"",values:"",type:""},  
      start: "",
      end:"",
      tps: ["全部", "前因选择", "同题互斥", "宏", "文字配比","数字配比", "最多", "最少","至少选一","打分跟随","根据网页文字识别场景"],
      filter: "全部",
      tps2: [ "前因选择", "同题互斥", "宏", "文字配比","数字配比", "最多", "最少","至少选一","打分跟随","根据网页文字识别场景"],
      lgs: [],
      count:0,
      upinfo:{
        upuser:"",
        uptime:""
      },
      cs:[],
      csd:[]
    };
  },
  watch:{
    filter(){
       this.getLgs()
    },
    lgs(){
        this.count = this.lgs.length
    }
  },
  methods: {
    getLgs() {
      this.$message.warning("数据加载中，请稍候");
      var api = this.COMMON.httpUrl + "logic";
      var data = {
        pid: this.pid,
        filter: this.filter,
      };
      this.axios.post(api, data).then((response) => {
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          this.lgs = rs.data.ls;        
          this.upinfo.upuser=rs.data.upuser
          this.upinfo.uptime=rs.data.uptime
          this.cs=rs.data.cs
        }
      });
    },
    tongbu(){
       if (this.pid=="" || this.csd.length==0){
        return
       }
     var api = this.COMMON.httpUrl + "logicTongBu";
      var data = {
        pid:this.pid,
        csd:this.csd
      };
      this.axios.post(api, data).then((response) => {
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message)
        } else {
          this.$message.success(rs.message)
          this.csd=[]
        }        
      });

    },
    down() {
      let durl=this.COMMON.httpUrl + "logicDown?pid="+this.pid+"&token=" + localStorage.getItem('token');
      window.location.href=durl
    },
    add(){
        // console.log(this.web)
        this.web["pid"]=this.pid
        this.web["source"]="web"
        if (this.web.key == "" &&this.web.id=="" && this.web.values=="" || this.web.type==""){
            return
        }

        var api = this.COMMON.httpUrl + "logicAdd";
      var data = {
        data: this.web,
      };
      this.axios.post(api, data).then((response) => {
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          this.lgs = rs.data    
          this.web={id:"",key:"",values:"",type:""}
        }
      });

    },
    del() {
      if (this.start=="" || this.end==""){
        return
      }      
      var api = this.COMMON.httpUrl + "logicDel";
      var data = {
        pid: this.pid,
        start:this.start,
        end:this.end
      };
      this.axios.post(api, data).then((response) => {
        var rs = response.data
        if (rs.code) {
          this.$message.error(rs.message)
        } else {
            this.$message.success(rs.message)
          this.lgs = rs.data      
          this.start=""
          this.end=""
        }
      });
    },
    beforeAvatarUpload(file) {
        
            let fileName = file.name.substring(file.name.lastIndexOf('.') + 1)

            //    alert(fileName)
            const extension = fileName === 'xls'
            const extension2 = fileName === 'xlsx'
            if (!extension && !extension2) {
                this.$message({
                    message: '上传文件只能是 xls、xlsx格式!',
                    type: 'warning'
                });
                // this.loading = false
                return false
            }
            this.load = true
        },
        onSuccess(res) {
            this.load = false
            if (res.code) {
                this.$message.error(res.message)
            } else {
              this.$message.success(res.message)
                this.lgs = res.data.ls   
                this.upinfo.upuser=res.data.upuser
                this.upinfo.uptime=res.data.uptime
            }

        }
  },
  created() {    
    this.upurl = this.COMMON.httpUrl + "logicImport?pid="+this.pid+"&token=" + localStorage.getItem('token')
    this.getLgs()
  },
};
</script>
<style scoped>
#content {
  background-color: white;
  padding-top: 20px;
  padding-left: 50px;
}
.part2{
    margin-top: 10px;
}
.c1{
    width:130px;
    margin-right: 15px;
}
#head{
    
}
#tabs{
    margin-top:50px;
    padding-bottom:50px;
    width:1150px;
}
.upload {
  display: inline-block;
  margin: 0 8px;
}
.upinfo{
  color:#999;
  margin-left:10px;
  font-size:12px;
}
</style>
 