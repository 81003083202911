<template>
    <div style="overflow:hidden">
        <div id="goback" style="margin-bottom:30px;font-size:14px;">
            <i class="el-icon-back"  @click="$router.go(-1)" style="cursor:pointer;">返回</i>&nbsp;&nbsp; <span style="font-size:14px;color:rgb(192, 196, 204)">|</span> &nbsp;&nbsp;<span style="font-size:14px;color:rgb(192, 196, 204)">编号：</span>{{pcode}} <span style="font-size:14px;color:rgb(192, 196, 204)">项目：</span>{{pname}}&nbsp;&nbsp;
        </div>
        <el-tabs v-model="activeName" type="card">
            <el-tab-pane label="句子贴入" name="one" :lazy="true">
                <OeRun  :pid="pid"></OeRun>
            </el-tab-pane>
            <el-tab-pane label="句子导入" name="two" :lazy="true">
                <OeSentenceManage  :pid="pid" :current="activeName==='two'"></OeSentenceManage>
            </el-tab-pane>
           
            <el-tab-pane label="句子模板" name="three" :lazy="true">
                <OeSentenceTemp  :pid="pid"></OeSentenceTemp>
            </el-tab-pane>
            <el-tab-pane label="文本逻辑" name="four" :lazy="true">
                <OeText  :pid="pid"></OeText>
            </el-tab-pane>
            <el-tab-pane label="特殊文本" name="five" :lazy="true">
                <OeSuiji></OeSuiji>
            </el-tab-pane>
            <el-tab-pane label="Ai模板" name="six" :lazy="true">
                <OeAiTemp :pid="pid"></OeAiTemp>
            </el-tab-pane>

           
           
        </el-tabs>
    </div>
</template>
<script>
import OeRun from './OeRun'
import OeSentenceManage from './OeSentenceManage'
import OeSentenceTemp from './OeSentenceTemp'
import OeAiTemp from './OeAiTemp'
import OeText from './OeText'
import OeSuiji from './OeSuiji'



export default {
    name: 'OeIndex',
    props: ['pid','pname','pcode'],
    data() {
        return {
            activeName: 'one',
        };
    },
    components: {
        OeRun,OeSentenceManage,OeSentenceTemp,OeText,OeSuiji,OeAiTemp
    },
    methods: {
    },


}
</script>
