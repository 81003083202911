<template>

    <div>
        <div id="content">
            <div class="part1">
                <el-input style="width:500px;margin-right: 20px;"   v-model="words" placeholder="请输入关键词">
                </el-input><el-button type="primary" @click="onSubmit" :loading="btload">查询</el-button>
            </div>
            <div class="part2">
                <div v-for="item in infos" :key="item.id" class="info">
                    <p class="i" ><span>{{item.info}}</span></p>
                    <p  class="i">{{item.zhs}}</p>
                    <p style="clear:both"></p>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ComWrong',
    data() {
        return {
            btload: false,
            words: '',
            infos:[]
        }
    },
    methods: {
 
         onSubmit() {
            this.btload = true
            var api = this.COMMON.httpUrl + "wrong"
            var data = {
                wd: this.words
            }
            this.axios.post(api, data).then((response) => {
                this.btload = false
                var rs = response.data
                if (rs.code) {
                    this.$message.error(rs.message);
                } else {
                    this.infos=rs.message

                }

            })

        }

    }
  
 
}
</script>
<style scoped>
  #content {
background-color: white;
    padding-top: 50px;
    padding-left: 100px;
}

.inp {
    width: 180px;
    margin-right: 10px;
}

.op {
    padding-bottom: 20px;
}

.part2 {
    margin-top: 30px;
}
.info p.i{
    float:left;
   width:400px;    
    margin-right:50px;
}
.info p.i span{
    color:rgb(184, 111, 111);
}
.info{
    padding-top:20px;
    padding-bottom:20px;
    border-bottom:1px solid #ecebeb;
    
}
.el-descriptions {
    color: #409EFF;
}
</style>