<template>
    <div>
        <div id="content">
            <el-dialog title="新增用户" :visible.sync="dialogFormVisible" width="650px" :closeOnClickModal="false" >
                <el-form :model="form">
                    <el-form-item label="用户名" :label-width="formLabelWidth">
                        <el-input v-model="form.name" placeholder="用户名必须在 2-15个字符之间" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="密码" :label-width="formLabelWidth">
                        <el-input v-model="form.password" placeholder="密码必须在 6-15个字符之间" autocomplete="off"  show-password></el-input>
                    </el-form-item>
                    <el-form-item label="昵称" :label-width="formLabelWidth">
                        <el-input v-model="form.nickname" placeholder="不超过30个字符，登录后显示的名称" autocomplete="off"></el-input>
                    </el-form-item>

                    <el-form-item label="角色" :label-width="formLabelWidth">
                        <el-select v-model="jsvalue" placeholder="请选择">
                            <el-option v-for="item in jiaose" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>



                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="dialogFormVisible = false">取 消</el-button>
                    <el-button type="primary" @click="onSubmit">确 定</el-button>
                </div>
            </el-dialog>


            <el-dialog title="用户编辑" :visible.sync="dialogFormEdit" width="650px">
                <el-form :model="edit">
                    <el-form-item label="用户名" :label-width="formLabelWidth">
                        {{ edit.name }}
                    </el-form-item>
                    <el-form-item label="密码" :label-width="formLabelWidth">
                        <el-input v-model="edit.password" placeholder="密码必须在 6-15个字符之间" autocomplete="off"  show-password></el-input>
                    </el-form-item>
                    <el-form-item label="昵称" :label-width="formLabelWidth">
                        <el-input v-model="edit.nickname" placeholder="不超过30个字符，登录后显示的名称" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="角色" :label-width="formLabelWidth">
                        <el-select v-model="jsvalue" placeholder="请选择">
                            <el-option v-for="item in jiaose" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="dialogFormEdit = false">取 消</el-button>
                    <el-button type="primary" @click="onEdit">确 定</el-button>
                </div>
            </el-dialog>
            <div class="part1">
                <el-button type="warning" size="small" icon="el-icon-zoom-in"
                    @click="dialogFormVisible = true; clear()">新增</el-button>
            </div>

            <div class="part2">
                <el-table :data=" tableData " style="width: 100%">

                    <el-table-column label="用户名" prop="name">
                    </el-table-column>
                    <el-table-column label="昵称" prop="nickname">
                    </el-table-column>
                    <el-table-column label="角色" prop="levels">
                    </el-table-column>
                    <el-table-column label="创建时间" prop="created_time">
                    </el-table-column>
                    <el-table-column align="right">

                        <template slot-scope="scope">
                            <el-button size="mini" round @click=" handleEdit(scope.$index, scope.row) ">修改</el-button>
                            <el-button size="mini" round type="danger" plain @click=" del(scope.$index, scope.row)">删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>


            </div>
            <div class="fenye">
                <el-pagination @current-change=" handleCurrentChange " :current-page.sync=" currentPage " :page-size=" 10 "
                    layout="total, prev, pager, next" :total= total >
                </el-pagination>
            </div>



        </div>
    </div>
</template>
<script>
export default {

    name: 'PageUser',
    props: {
        type: String
    },
    data() {
        return {
            jsvalue:'2',
            jiaose: [{
                value: '1',
                label: '管理员'
            }, {
                value: '2',
                label: 'PM'
            }, {
                value: '3',
                label: 'QC'
            }, {
                value: '4',
                label: 'Support'
            }, {
                value: '5',
                label: '财务'
            }, {
                value: '6',
                label: '销售'
            }],
            dialogFormVisible: false,
            dialogFormEdit: false,
            form: {
                name: '',
                nickname:'',
                password: ''
            },
            edit: {
                id: '',
                name: '',
                nickname:'',
                password: ''
            },
            formLabelWidth: '60px',
            tableData: [],
            currentPage: 1,
            total: 1

        }
    },
    created() {
        this.handleCurrentChange(1)
    },
    methods: {
        handleEdit(index, row) {
            this.clear()
            this.dialogFormEdit = true
            this.edit.name = row.name
            this.edit.id = row.id
            this.edit.nickname=row.nickname
            this.jsvalue=row.level.toString()

        },
        onEdit() {

            var api = this.COMMON.httpUrl + "edituser"
            var data = { id: this.edit.id, jsvalue:this.jsvalue,nickname: this.edit.nickname,password: this.edit.password }
            this.axios.post(api, data).then((response) => {
                var rs = response.data

                if (rs.code) {
                    this.$message.error(rs.message);
                } else {
                    this.dialogFormEdit = false
                    this.$message.success("修改成功!");
                    this.tableData = rs.message.data
                    this.currentPage = rs.message.current_page
                    this.total = rs.message.total
                }

            })

        },
        del(index, row) {

            this.$confirm('此操作将永久删除该用户, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.handleDelete(row.id)
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        handleDelete(uid) {

            var api = this.COMMON.httpUrl + "deluser"
            var data = { id: uid }
            this.axios.post(api, data).then((response) => {
                var rs = response.data

                if (rs.code) {
                    this.$message.error(rs.message);
                } else {
                    this.$message.success("删除成功!");
                    this.tableData = rs.message.data
                    this.currentPage = rs.message.current_page
                    this.total = rs.message.total
                }

            })
        },
        handleCurrentChange(page) {
            var api = this.COMMON.httpUrl + "userlist"
            var data = {
                page: page
            }
            this.axios.post(api, data).then((response) => {
                var rs = response.data
                if (rs.code) {
                    this.$message.error(rs.message);
                } else {

                    this.tableData = rs.message.data
                    this.currentPage = rs.message.current_page
                    this.total = rs.message.total
                }

            })

        },

        clear() {
            this.form.name = ''
            this.form.nickname = ''
            this.form.password = ''
            this.edit.id = ''
            this.edit.password = ''
        },
        onSubmit() {

            var api = this.COMMON.httpUrl + "adduser"
            var data = { name: this.form.name, nickname: this.form.nickname, jsvalue:this.jsvalue,password: this.form.password }
            this.axios.post(api, data).then((response) => {
                var rs = response.data

                if (rs.code) {
                    this.$message.error(rs.message);
                } else {
                    this.dialogFormVisible = false
                    this.$message.success('恭喜您,添加成功');
                    this.tableData = rs.message.data
                    this.currentPage = rs.message.current_page
                    this.total = rs.message.total
                }

            })
        }

    }

}
</script>
<style scoped>
#content {
    background-color: white;
    width: 900px;
    text-align: left;
    padding-top: 20px;
    

}


.upload-demo {
    width: 300px;
}

.el-input {
    width: 360px;
}

.part2 {
    padding-top: 20px;
}

.fenye {
    margin-top: 20px;
}
</style>