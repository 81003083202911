<template>
    <div>
        <div id="goback" style="margin-bottom:30px;font-size:14px;">
            <i class="el-icon-back"  @click="$router.go(-1)" style="cursor:pointer;font-size:14px;">返回</i>&nbsp;&nbsp; <span style="font-size:16px;color:rgb(192, 196, 204)">|</span> &nbsp;&nbsp;<span style="font-size:14px;color:rgb(192, 196, 204)">编号：</span>{{pcode}} <span style="font-size:14px;color:rgb(192, 196, 204)">项目：</span>{{pname}}       
        </div>
        <el-tabs v-model="activeName" type="card">
           
            <el-tab-pane label="Logic" name="one" :lazy="true">
                <LogicList :pid="pid"></LogicList>
            </el-tab-pane>                    
            <el-tab-pane label="宏" name="two" :lazy="true">
                <LogicHong :pid="pid"></LogicHong>
            </el-tab-pane>
            <el-tab-pane label="出示逻辑" name="three" :lazy="true">
                <LogicShow :pid="pid"></LogicShow>
            </el-tab-pane>
           
        </el-tabs>
    </div>
</template>
<script>
import LogicList from './LogicList'
import LogicHong from './LogicHong'
import LogicShow from './LogicShow'

export default {
    name: 'LogicIndex',
    props: ['pid','pcode','pname'],
    data() {
        return {
            activeName: 'one',
        };
    },
    components: {
        LogicList,LogicHong,LogicShow
    },
    methods: {
    },
    created() {

    },

}
</script>
