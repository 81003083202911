<template>
  <div>
    <div id="goback" style="margin-bottom:30px;font-size:14px;">
      <i class="el-icon-back"  @click="$router.go(-1)" style="cursor:pointer;font-size:14px;">返回</i>&nbsp;&nbsp; <span style="font-size:16px;color:rgb(192, 196, 204)">|</span> &nbsp;&nbsp;<span style="font-size:14px;color:rgb(192, 196, 204)">编号：</span>{{pcode}} <span style="font-size:14px;color:rgb(192, 196, 204)">项目：</span>{{pname}}      
    </div>
    <div id="content">
      <div class="part1">
        <div>
          <el-select
            v-model="qidTop"
            filterable
            clearable
            collapse-tags
            placeholder="请选择Question ID"
            :disabled="seleDisabled"
          >
            <el-option
              v-for="(q, index) in qidTopSelect"
              :key="index"
              :label="q"
              :value="q"
            ></el-option> </el-select
          >&nbsp;&nbsp;&nbsp;&nbsp;
          <el-button type="primary" @click="run">执行</el-button>&nbsp;&nbsp;

          <el-button @click="reset">重置</el-button>
        </div>
        <div style="height: 50px; margin-top: 25px">
          <el-radio-group v-model="topRadio.weizhi" fill="#f55dda">
            <el-radio-button
              :label="i"
              v-for="(w, i) of topRadio.zu"
              :key="i"
              >{{ w }}</el-radio-button
            >
          </el-radio-group>
         
          <el-select v-if="vidTopSelect.length>0" style="margin-left:50px"
                v-model="vidTop"
                multiple
                filterable
                clearable
                collapse-tags
                placeholder="请选择Variable ID"
                :disabled="seleDisabled"
              >
                <el-option
                  v-for="(vid, index) in vidTopSelect"
                  :key="index"
                  :label="vid"
                  :value="vid"
                ></el-option> </el-select
            >
        </div>
      </div>
      <div class="part2">
        <div class="zl">
          <el-row :gutter="20">
            <el-col :span="4"><b>sameloop score</b></el-col>
            <el-col :span="6"
              ><el-select
                v-model="score"
                filterable
                clearable
                collapse-tags
                placeholder="请选择Question ID"
                :disabled="seleDisabled"
              >
                <el-option
                  v-for="(q, index) in qidTopSelect"
                  :key="index"
                  :label="q"
                  :value="q"
                ></el-option> </el-select
            ></el-col>
            <el-col :span="3">
              <el-checkbox v-model="notscore" class="ckbox"
                >反向score</el-checkbox
              >
            </el-col>
            <el-col :span="9">
              <el-radio-group v-model="scoreRadio.weizhi" fill="#f55dda">
                <el-radio-button
                  :label="i"
                  v-for="(w, i) of scoreRadio.zu"
                  :key="i"
                  >{{ w }}</el-radio-button
                >
              </el-radio-group>
            </el-col>
            <el-col :span="2">
              <transition name="el-zoom-in-center"
                ><p class="count" v-if="count.score >= 0">
                  生成{{ count.score }}条
                </p></transition
              >
            </el-col>
          </el-row>
        </div>
        <div class="zl">
          <el-row :gutter="20">
            <el-col :span="4"><b>openpick</b></el-col>
            <el-col :span="6"
              ><el-select
                v-model="openpick"
                filterable
                clearable
                collapse-tags
                placeholder="请选择Question ID"
                :disabled="seleDisabled"
              >
                <el-option
                  v-for="(q, index) in qidTopSelect"
                  :key="index"
                  :label="q"
                  :value="q"
                ></el-option> </el-select
            ></el-col>
            <el-col :span="12">
              <el-checkbox v-model="notpick" class="ckbox">notpick</el-checkbox>
            </el-col>
            <el-col :span="2">
              <transition name="el-zoom-in-center"
                ><p class="count" v-if="count.openpick >= 0">
                  生成{{ count.openpick }}条
                </p></transition
              >
            </el-col>
          </el-row>
        </div>
        <div class="zl">
          <el-row :gutter="20">
            <el-col :span="3"><b>openexclusion</b></el-col>
            <el-col :span="7">
              <div style="text-align: center">
                <el-checkbox v-model="openexclusion">标注</el-checkbox>
              </div>
            </el-col>
            <el-col :span="12">&nbsp; </el-col>
            <el-col :span="2">
              <transition name="el-zoom-in-center"
                ><p class="count" v-if="count.openexclusion >= 0">
                  生成{{ count.openexclusion }}条
                </p></transition
              >
            </el-col>
          </el-row>
        </div>
        <div class="zl">
          <el-row :gutter="20">
            <el-col :span="3"><b>match</b></el-col>
            <el-col :span="7"
              ><el-select
                v-model="match"
                filterable
                clearable
                collapse-tags
                placeholder="请选择Question ID"
                :disabled="seleDisabled"
              >
                <el-option
                  v-for="(q, index) in qidTopSelect"
                  :key="index"
                  :label="q"
                  :value="q"
                ></el-option> </el-select
            ></el-col>
            <el-col :span="12">&nbsp;</el-col>
            <el-col :span="2">
              <transition name="el-zoom-in-center"
                ><p class="count" v-if="count.match >= 0">
                  生成{{ count.match }}条
                </p></transition
              >
            </el-col>
          </el-row>
        </div>
        <div class="zl">
          <el-row :gutter="20">
            <el-col :span="3"><b>follow</b></el-col>
            <el-col :span="7"
              ><el-select
                v-model="follow"
                filterable
                clearable
                collapse-tags
                placeholder="请选择Question ID"
                :disabled="seleDisabled"
              >
                <el-option
                  v-for="(q, index) in qidTopSelect"
                  :key="index"
                  :label="q"
                  :value="q"
                ></el-option> </el-select
            ></el-col>
            <el-col :span="12">
              
              <el-select
              v-if="vidTopSelect.length>0"
                v-model="folVid"
                filterable
                clearable
                collapse-tags
                placeholder="请选择Variable ID"
                :disabled="seleDisabled"
              >
                <el-option
                  v-for="(vid, index) in vidTopSelect"
                  :key="index"
                  :label="vid"
                  :value="vid"
                ></el-option> </el-select
            >

              </el-col>
            <el-col :span="2">
              <transition name="el-zoom-in-center"
                ><p class="count" v-if="count.follow >= 0">
                  生成{{ count.follow }}条
                </p></transition
              ></el-col
            >
          </el-row>
        </div>
        <div class="zl">
          <el-row :gutter="20">
            <el-col :span="3"><b>max</b></el-col>
            <el-col :span="7">
              <el-input
                v-model="max"
                placeholder="请输入数字"
                style="width: 220px"
              ></el-input>
            </el-col>
            <el-col :span="12">&nbsp;</el-col>
            <el-col :span="2">
              <transition name="el-zoom-in-center"
                ><p class="count" v-if="count.max >= 0">
                  生成{{ count.max }}条
                </p></transition
              ></el-col
            >
          </el-row>
        </div>
        <div class="zl">
          <el-row :gutter="20">
            <el-col :span="3"><b>min</b></el-col>
            <el-col :span="7">
              <el-input
                v-model="min"
                placeholder="请输入数字"
                style="width: 220px"
              ></el-input
            ></el-col>
            <el-col :span="12">&nbsp;</el-col>
            <el-col :span="2">
              <transition name="el-zoom-in-center"
                ><p class="count" v-if="count.min >= 0">
                  生成{{ count.min }}条
                </p></transition
              ></el-col
            >
          </el-row>
        </div>
        <div class="zl">
          <el-row :gutter="20">
            <el-col :span="3"><b>atleastone</b></el-col>
            <el-col :span="7"
              ><el-select
                v-model="atleastone"
                multiple
                filterable
                clearable
                collapse-tags
                placeholder="请选择Variable ID"
                :disabled="seleDisabled"
              >
                <el-option
                  v-for="(q, index) in atleastVid"
                  :key="index"
                  :label="q.vid"
                  :value="q.vid"
                ></el-option> </el-select
            ></el-col>
            <el-col :span="12">&nbsp;</el-col>
            <el-col :span="2">
              <transition name="el-zoom-in-center"
                ><p class="count" v-if="count.atleastone >= 0">
                  生成{{ count.atleastone }}条
                </p></transition
              ></el-col
            >
          </el-row>
        </div>
        <div class="zl">
          <el-row :gutter="20">
            <el-col :span="3"><b>samepartrevise</b></el-col>
            <el-col :span="7">
              <div style="text-align: center">
                <el-checkbox v-model="samepartrevise">标注</el-checkbox>
              </div>
            </el-col>
            <el-col :span="12">&nbsp; </el-col>
            <el-col :span="2">
              <transition name="el-zoom-in-center"
                ><p class="count" v-if="count.samepartrevise >= 0">
                  执行{{ count.samepartrevise }}条
                </p></transition
              >
            </el-col>
          </el-row>
        </div>
        <div class="zl">
          <el-row :gutter="20">
            <el-col :span="4"><b>wordwithbrand</b></el-col>
            <el-col :span="6">
              <el-select
                v-model="wordwithbrand"
                filterable
                clearable
                collapse-tags
                placeholder="请选择Question ID"
                :disabled="seleDisabled"
              >
                <el-option
                  v-for="(q, index) in qidTopSelect"
                  :key="index"
                  :label="q"
                  :value="q"
                ></el-option> </el-select
            >
            <div style="margin-top:20px">
            <p style="height:50px" v-for="(item, index) in qidXs" :key="index">{{item.vid}} <el-input
                v-model="item.dang"
                size="mini"
                placeholder="档位"
                style="width: 60px"
              ></el-input
            ></p> 
          </div>
            </el-col>
            <el-col :span="12">
              &nbsp;
            
          
            </el-col>
            <el-col :span="2">
              <transition name="el-zoom-in-center"
                ><p class="count" v-if="count.wordwithbrand >= 0">
                  执行{{ count.wordwithbrand }}条
                </p></transition
              >
            </el-col>
          </el-row>
        </div>
        <div class="zl">
          <el-row :gutter="20">
            <el-col :span="4"><b>rank</b></el-col>
            <el-col :span="6">
              <p style="height:50px" v-for="(item, index) in rank" :key="index">{{item.vid}} <el-input
                v-model="item.dang"
                size="mini"
                placeholder="档位"
                style="width: 60px"
              ></el-input
            ></p> 
          
            </el-col>
            <el-col :span="12">&nbsp;</el-col>
            <el-col :span="2">
              <transition name="el-zoom-in-center"
                ><p class="count" v-if="count.rank >= 0">
                  执行{{ count.rank }}条
                </p></transition
              >
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PageInstruct",
  props: ['pid','pname','pcode'],
  data() {
    return {
      seleDisabled: true,
      qidTop: "",
      qidTopSelect: [],
      qidTopSub: {},
      topRadio: {},
      vidTopSelect: [],
      vidTop:[],
      atleastVid: [],
      qidXs:[],//[{vid:"a",dang:"5"}]
      rank:[],
      count: {
        score: "a",
        openpick: "a",
        openexclusion: "a",
        match: "a",
        follow: "a",
        max: "a",
        min: "a",
        atleastone: "a",
        samepartrevise:"a",
        rank:"a"
      },

      //指令
      score: "",
      scoreRadio: {},
      notscore: false,
      openpick: "",
      notpick: false,
      openexclusion: false,
      match: "",
      follow: "",
      folVid:"",
      max: "",
      min: "",
      atleastone: [],
      samepartrevise:false,
      wordwithbrand:""
    };
  },
  watch: {
    qidTop(qid) {
      // console.log("qidTop", this.qidTopSub[qid].vid);
      this.vidTopSelect=[]
        this.vidTop=[]
        this.folVid=""
        this.follow=""

      if (qid == "") {
        this.topRadio = {}
        this.qidXs=[]
        this.rank=[]
        return;
      }
      const vid = this.qidTopSub[qid][0].vid;
      const ks = vid.split("_");
      this.topRadio = {
        qid: qid,
        weizhi: "",
        zu: ks,
      };
      this.atleastVid = this.qidTopSub[qid]
      this.dangwei()
      this.dangweiRank()
      
    },
    score(qid) {
      if (qid == "") {
        this.scoreRadio = {};
        return;
      }
      const vid = this.qidTopSub[qid][0].vid;
      const ks = vid.split("_");
      this.scoreRadio = {
        qid: qid,
        weizhi: "",
        zu: ks,
      };
    },
    follow(qid){
      if (qid == this.qidTop && qid.length>0) {
        // this.vidTopSelect=this.qidTopSub[qid]
        for(let item of this.qidTopSub[qid]){
          if(this.vidTopSelect.indexOf(item.vid)==-1){
            this.vidTopSelect.push(item.vid)
          }
        }
        
      }else{
        this.vidTopSelect=[]
        this.vidTop=[]
        this.folVid=""
      }
    }
  },
  methods: {
    getQid() {
      this.$message.warning("数据加载中，请稍候");
      var api = this.COMMON.httpUrl + "instructSelect";
      var data = {
        pid: this.pid,
      };
      this.axios.post(api, data).then((response) => {
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          this.qidTopSelect = rs.data.qArray;
          this.qidTopSub = rs.data.qidTopSub;
          this.seleDisabled = false;
        }
      });
    },
    run() {
      if (this.qidTop == "") {
        this.$message.error("请选择顶部题号");
        return;
      }
      this.resetCount();
      let config = {};
      config.qidTop = this.qidTop;
      config.biao = {
        qidTop: this.topRadio.weizhi,
        qidScore: this.scoreRadio.weizhi,
      };
      config.vidTop = this.vidTop;
      config.score = this.score;      
      config.notscore = this.notscore;
      config.openpick = this.openpick;
      config.notpick = this.notpick;
      config.openexclusion = this.openexclusion;
      config.match = this.match;
      config.follow = this.follow;
      config.folVid = this.folVid;
      config.max = this.max.trim();
      config.min = this.min.trim();
      config.atleastone = this.atleastone;
      config.samepartrevise = this.samepartrevise;
      config.wordwithbrand = this.wordwithbrand;
      config.qidXs = this.qidXs;
      config.rank = this.rank;
      if (isNaN(config.max)||isNaN(config.min)){
        this.$message.error("max、min只能输入数字");
        return;
      }
     
      var api = this.COMMON.httpUrl + "instructMake";
      var data = {
        config: config,
        pid: this.pid,
      };
      this.axios.post(api, data).then((response) => {
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          this.$message.success("执行完成！");
          this.count = rs.data;
        }
      });
    },
    reset() {
      this.qidTop = ""
      this.score = ""
      this.notscore = false
      this.openpick = ""
      this.notpick = false
      this.openexclusion= false
      this.match= ""
      this.follow= ""
      this.max= ""
      this.min= ""
      this.atleastone= []
      this.samepartrevise=false
      this.wordwithbrand=""
      this.qidXs=[]
      this.rank=[]
      this.resetCount()
      this.vidTopSelect=[]
        this.vidTop=[]
        this.folVid=""
    },
    resetCount() {
      this.count = {
        score: "a",
        openpick: "a",
        openexclusion: "a",
      };
    },
    dangwei(){
      this.qidXs=[]
      let qx=this.qidTop.split("_")[0]
      for(let key in this.qidTopSub){
        let qq=key.split("_")[0]
        if(qq==qx){
          let vs=this.qidTopSub[key]
          for(let v of vs){            
          this.qidXs.push({vid:v["vid"],dang:""})
          }
          
        }

      }
      // console.log(this.qidXs)
    },
    dangweiRank(){
      this.rank=[]
      let ar =[]
      for(let key in this.qidTopSub){
        if(key==this.qidTop){
          let vs=this.qidTopSub[key]
          for(let v of vs){           
            if(ar.indexOf(v["vid"])==-1){
              this.rank.push({vid:v["vid"],dang:""})
              ar.push(v["vid"])
            }         
          }
          
        }
      }
      // console.log(this.qidXs)
    },

  },
  created() {
    this.getQid();
  },
};
</script>
<style scoped>
#content {
  background-color: rgb(255, 255, 255);
  padding-top: 0px;
  padding-left: 50px;
  width: 1200px;
}

.part1 {
  margin-left: 30px;
}

.part2 {
  margin-left: 30px;
  margin-top: 20px;
}
b {
  margin: 0 5px;
  font-size: 18px;
  color: #666;
}
.zl {
  border-top: 1px solid #efefef;
  padding-top: 30px;
  padding-bottom: 30px;
}
.ckbox {
  margin-top: 10px;
}
.count {
  font-size: 12px;
  color: rgb(37, 190, 37);
  padding-top: 10px;
}
</style>
 