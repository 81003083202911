<template>
  <div>
    <div id="goback" style="margin-bottom: 10px; font-size: 14px">
      <i class="el-icon-back" @click="$router.go(-1)" style="cursor: pointer"
        >返回</i
      >&nbsp;&nbsp;
      <span style="font-size: 14px; color: rgb(192, 196, 204)">|</span>
      &nbsp;&nbsp;<span style="font-size: 14px; color: rgb(192, 196, 204)"
        >编号：</span
      >{{ pcode }}
      <span style="font-size: 14px; color: rgb(192, 196, 204)">项目：</span
      >{{ pname }}&nbsp;&nbsp;<span
        style="font-size: 14px; color: rgb(192, 196, 204)"
        >报告：</span
      >{{ bname }}
    </div>

    <div id="content">
      <el-row :gutter="20">
        <el-col :span="12">
          <div class="part1">
            <el-button size="small" @click="reset">重置</el-button>
            <el-button type="primary" size="small" @click="addTag"
              >添加Table</el-button
            >
          </div>

          <el-row :gutter="20">
            <el-col :span="12">
              <div class="part4">
                <div style="margin-top: 60px">
                  <b>表头：</b
                  ><el-select
                    v-model="headSelect"
                    multiple
                    filterable
                    clearable
                    collapse-tags
                    placeholder="请选择 QuestionID"
                    :disabled="seleDisabled"
                  >
                    <el-option
                      v-for="qid in qids"
                      :key="qid"
                      :label="qid"
                      :value="qid"
                    ></el-option>
                  </el-select>
                </div>
              </div>
              <div class="part3">
                <div class="weizhi">
                  <div v-for="(item, index) of headRadio" :key="index">
                    <table>
                      <tr>
                        <td>
                          <el-radio-group v-model="item.weizhi" fill="#f55dda">
                            <el-radio-button
                              :label="i"
                              v-for="(w, i) of item.zu"
                              :key="i"
                              >{{ w }}</el-radio-button
                            >
                          </el-radio-group>
                        </td>
                        <td width="20"></td>
                        <td>
                          <el-input-number
                            v-model="item.count"
                            :min="1"
                            :max="10"
                          ></el-input-number>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="hong">
                <b>&nbsp;&nbsp;&nbsp;宏：</b
                ><el-select
                  v-model="hongSelect"
                  multiple
                  filterable
                  clearable
                  collapse-tags
                  placeholder="请选择 宏"
                  :disabled="seleDisabled"
                >
                  <el-option
                    v-for="(ho, index) in hong"
                    :key="index"
                    :label="ho"
                    :value="ho"
                  ></el-option>
                </el-select>
              </div>
              <div class="part2">
                <div>
                  <b>表侧：</b
                  ><el-select
                    v-model="sideSelect"
                    multiple
                    filterable
                    clearable
                    collapse-tags
                    placeholder="请选择 QuestionID"
                    :disabled="seleDisabled"
                  >
                    <el-option
                      v-for="qid in qids"
                      :key="qid"
                      :label="qid"
                      :value="qid"
                    ></el-option>
                  </el-select>
                </div>
              </div>
              <div class="part3">
                <div class="weizhi">
                  <div v-for="(item, index) of sideRadio" :key="index">
                    <table>
                      <tr>
                        <td>
                          <el-radio-group v-model="item.weizhi" fill="#f55dda">
                            <el-radio-button
                              :label="i"
                              v-for="(w, i) of item.zu"
                              :key="i"
                              >{{ w }}</el-radio-button
                            >
                          </el-radio-group>
                        </td>
                        <td width="20"></td>
                        <td>
                          <el-input-number
                            v-model="item.count"
                            :min="1"
                            :max="10"
                          ></el-input-number>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <div class="part0">
            <el-button
              type="success"
              size="small"
              v-loading="loading"
              @click="saveTemp"
              >保存模板</el-button
            >
            <el-button
              type="warning"
              size="small"
              v-loading="loading"
              @click="down"
              >下载模板</el-button
            >
            <el-upload
              class="upload"
              name="quotatab"
              :action="upurl"
              :headers="{ Authorization: token }"
              auto-upload
              :show-file-list="false"
              :before-upload="beforeAvatarUpload"
              :on-success="onSuccess"
              :data="{ pid: pid, kid:kid}"
              ><el-button type="primary" size="small" v-loading="loading"
                >导入生成</el-button
              ></el-upload
            ><el-button
              type="danger"
              size="small"
              v-loading="loading"
              @click="tagClear"
              >清空模板</el-button
            >
          </div>
          <div class="tags">
            <el-tag
              v-for="(tag,index) of tags"
              :key="index"
              closable
              :type="'info'"
              @close="tagDel(index)"
              effect="light"
            >
              {{ tag }}
            </el-tag>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
  <script>
export default {
  name: "QuotaTab",
  props: ["pid","kid","pname", "pcode", "bname"],
  data() {
    return {
      upurl: this.COMMON.httpUrl + "quotatabTempMake",
      loading: false,
      token: "Bearer " + localStorage.getItem("token"),
      con: "0",
      dialogVisible: false,
      isrc: "",
      seleDisabled: true,
      batch: false,
      headRadio: [],
      sideRadio: [],
      headSelect: [],
      sideSelect: [],
      hongSelect: [], //多值
      qids: [],
      hong: [],
      hs:{},
      qidsub: {},
      tags: [],
      configs: [],
    };
  },
  watch: {
    // 表头为多选
    headSelect(val) {
     
      let linshi = new Array();
      for (let qid of val) {
        const vid = this.qidsub[qid].vid;
        const ks = vid.split("_");
        linshi.push({
          qid: qid,
          weizhi: "",
          zu: ks,
          count: "1",
        });
      }
      this.headRadio = linshi;
    },
    sideSelect(val) {
      
      let linshi = new Array();
      for (let qid of val) {
        const vid = this.qidsub[qid].vid;
        const ks = vid.split("_");
        linshi.push({
          qid: qid,
          weizhi: "",
          zu: ks,
          count: "1",
        });
      }
      this.sideRadio = linshi;
      // console.log(this.sideSelect)
        // console.log(this.sideRadio)
    },
  },
  methods: {
    getQid() {
      // this.$message.warning("数据加载中，请稍后");
      var api = this.COMMON.httpUrl + "quotatabDataQid";
      var data = {
        kid: this.kid,
        pid: this.pid,
      };
      this.axios.post(api, data).then((response) => {
        var rs = response.data;
        if (rs.code) {
          // this.$message.error(rs.message);
        } else {
          this.qids = rs.data.qid;
          this.qidsub = rs.data.qidsub;
          this.hong = rs.data.hong;
          this.seleDisabled = false;
          this.hs=rs.data.hs;
          if (rs.data.configs !=null && rs.data.configs !="") {
            this.configs = JSON.parse(rs.data.configs);
            // console.log("conf:",this.configs)
            for (let k of this.configs) {
              this.tags.push(k.key);
            }
          }
          
        }
      });
    },
    reset() {
      this.headSelect = [];
      this.sideSelect = "";
      this.hongSelect = [];
      this.batch = false;
    },

    addTag1(v) {
      // 1,保存到配置对象configs
      if (v === "") {
        return;
      }
      for (let side of this.sideRadio) {
        let configKey = v + "##" + side.qid;
        let configValue = { batch: this.batch };
        let pos = {};
        configValue["two"] = 1;
        configValue["head"] = v.replaceAll("#",",");
        configValue["side"] = side.qid;

        for (let ob of this.headRadio) {
          pos[ob.qid] = ob.weizhi;
        }
        pos[side.qid] = side.weizhi;
        configValue["pos"] = pos;
        // 为了解决表侧端的同题多选，需要在key增加标识 @i，他们value是完全一样的，生成的table也是完全一样的
        // this.configs[configKey] = configValue;
        for(let i=1;i<=side.count;i++){          
          
          configValue["key"] =configKey;
          this.configs.push(configValue);
          //添加到右侧tags框用于展示
          this.tags.push(configKey);
        }

      }
    },
    addTag2(v) {      
      if (v === "") {
        return;
      }
      for (let hong of this.hongSelect) {
        // 1,保存到配置对象configs
        const configKey = v + "##" + hong;
        let configValue = { batch: this.batch };
        let pos = {};
        configValue["two"] = 2;
        configValue["head"] = v.replaceAll("#",",");
        configValue["hong"] = this.hs[hong];

        for (let ob of this.headRadio) {
          pos[ob.qid] = ob.weizhi;
        }
        configValue["pos"] = pos;
        configValue["key"] =configKey;
          this.configs.push(configValue);
          //添加到右侧tags框用于展示
          this.tags.push(configKey);
      }
    },
    addTag3() {
      console.log("headSelect:",this.headSelect)
      console.log("headRadio:",this.headRadio)
      },
    addTag() {
      if (
        (this.sideSelect.length == 0 && this.hongSelect.length == 0)) {
        this.$alert("请先选取表侧变量或宏", "提示", {
          confirmButtonText: "确定",
        });
        return;
      }
      // 表头处理，如果没选，自动填充 Lastplete
      if( this.headRadio.length==0 || this.headSelect.length==0){
        this.headSelect=["Lastplete"];
        let linshi = new Array();
        linshi.push({
          qid: "Lastplete",
          weizhi: "",
          count: "1",
        });
        this.headRadio = linshi;
      }
      var hs= [];
      for (let v of this.headRadio) {
         for(let i=1;i<=v.count;i++){
          hs.push(v.qid);
         }
      }
      const hn = hs.join("#")
      this.addTag1(hn);

      // 宏
      this.addTag2(hn);
      this.reset();
    },
    tagDel(index) {
      // 删除框内tag
      this.tags.splice(index, 1);
      // 删除配置对象configs
      this.configs.splice(index, 1);
      
    },
    tagClear() {
      // 删除框内tag
      this.tags.splice(0, this.tags.length);
      // 删除配置对象configs
      this.configs.splice(0, this.configs.length);    
    },
    saveTemp() {
      this.loading = true;
      var api = this.COMMON.httpUrl + "quotatabTempSave";
      var data = {
        kid: this.kid,
        pid: this.pid,
        configs: this.configs,
      };
      this.axios.post(api, data).then((response) => {
        this.loading = false;
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          this.$message.success(rs.message);
        }
      });
    },
    down() {
      this.loading = true;
      var api = this.COMMON.httpUrl + "quotatabTempDown";
      var data = {
        pid: this.pid,
        kid: this.kid,
      };
      this.axios.post(api, data).then((response) => {
        this.loading = false;
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          this.$message.success("logic模板下载中");
          window.location.href = rs.data;
        }
      });
    },
    beforeAvatarUpload(file) {
      this.loading = true;
      let fileName = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = fileName === "xls";
      const extension2 = fileName === "xlsx";
      if (!extension && !extension2) {
        this.$message({
          message: "上传文件只能是 xls、xlsx格式!",
          type: "warning",
        });
        this.loading = false;
        return false;
      }
    },

    rescene(con) {
      var api = this.COMMON.httpUrl + "infoReScene";
      var data = {
        isrc: this.isrc,
        pid: this.pid,
        con: con,
      };
      this.axios.post(api, data).then((response) => {
        this.loading = false;
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          this.$message.success(rs.message);
        }
      });
      this.dialogVisible = false;
    },
    onSuccess(rs) {
      this.loading = false;
      if (rs.code) {
          this.$message.error(rs.message);
        } else {
          this.$message.success("配额表下载中");
          window.location.href = rs.data;
        }
    },
  },
  created() {
    this.getQid();
  },
};
</script>
  <style scoped>
#content {
  background-color: white;
  padding-top: 30px;
}

.part0 {
  margin-left: 50px;
}

.part1 {
  text-align: left;
}

.part4 {
  margin-top: 180px;
}

.part2 {
  margin-top: 50px;
}
.hong {
  margin-top: 90px;
}

.weizhi {
  margin-top: 50px;
  margin-left: 50px;
}

.weizhi div {
  margin-top: 10px;
}

.tags {
  border: 2px dashed #e0e5f0;
  width: 500px;
  height: 550px;
  margin-left: 50px;
  margin-top: 20px;
  padding: 10px;
  overflow: auto;
}

.tags span {
  margin-left: 10px;
  margin-top: 10px;
}

.upload {
  display: inline-block;
  margin: 0 8px;
}
::v-deep .el-input-number {
  width: 120px !important;
}
</style>
   