<template>

    <div>
        <div id="goback" style="margin-bottom:10px;font-size:14px;">
            <i class="el-icon-back"  @click="$router.go(-1)" style="cursor:pointer;font-size:14px;">返回</i>&nbsp;&nbsp; <span style="font-size:14px;color:rgb(192, 196, 204)">|</span> &nbsp;&nbsp;<span style="font-size:16px;color:rgb(192, 196, 204)">编号：</span>{{pcode}} <span style="font-size:14px;color:rgb(192, 196, 204)">项目：</span>{{pname}}      
        </div>
        <div id="content">
            <div class="part1">


                <el-input style="width:500px" type="textarea" :rows="10" v-model="words" placeholder="请输入Variable ID ,一行一个">
                </el-input><br><br>

                <el-button type="primary" @click="onSubmit" :loading="btload">保存修改</el-button>
                <el-button type="warning" @click="onRun" :loading="btload2" >运行</el-button>
                <el-button @click="words=''"  >清空</el-button>
                <span style="margin-left:100px;font-size: 14px;color:#999">已执行  <span style="font-weight:800;color:#333">{{ count }}</span> 条</span>
            </div>

        </div>
    </div>
</template>
<script>
export default {
    name: 'ErrWord',
    props: ['pid','pname','pcode'],
    data() {
        return {
            count:0,
            btload: false,
            btload2: false,
            words: ''
        }
    },
    methods: {
 
         onSubmit() {
            this.btload = true
            var api = this.COMMON.httpUrl + "errwordadd"
            var data = {
                pid: this.pid,
                words: this.words
            }
            this.axios.post(api, data).then((response) => {
                this.btload = false
                var rs = response.data
                if (rs.code) {
                    this.$message.error(rs.message);
                } else {
                    this.$message.success(rs.message)
                }

            })

        },
        onRun() {
            this.btload2 = true
            var api = this.COMMON.httpUrl + "errwordrun"
            var data = {
                pid: this.pid
            }
            this.axios.post(api, data).then((response) => {
                this.btload2 = false
                var rs = response.data
                if (rs.code) {
                    this.$message.error(rs.message);
                } else {
                    this.$message.success(rs.message)
                    this.count = rs.count
                }

            })

        }

    },
  
    created() {
        var api = this.COMMON.httpUrl + "errwordget"
        var data = { pid: this.pid }

        this.axios.post(api, data).then((response) => {
            var rs = response.data
            if (rs.code) {
                this.$message.error(rs.message);
            } else {
                this.words = rs.message
                this.count = rs.count
            }
        })
    }

}
</script>
<style scoped>
  #content {
    background-color: white;
    padding-top: 100px;
    padding-left: 100px;
}

.inp {
    width: 180px;
    margin-right: 10px;
}

.op {
    padding-bottom: 20px;
}

.part2 {
    margin-top: 100px;
}

.el-descriptions {
    color: #409EFF;
}
</style>