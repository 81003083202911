import VueRouter from "vue-router"

//引入组件
import HomeIndex from '../components/HomeIndex'
import PageIndex from '../components/PageIndex'
import PageLogin from '../components/PageLogin'
import ProjectList from '../components/ProjectList'
import ProjectData from '../components/ProjectData'
import TransformExcel from '../components/TransformExcel'
import OldToNew from '../components/OldToNew'
import AoExcel from '../components/AoExcel'
import InfotomapExcel from '../components/InfotomapExcel'
import ProjectAdd from '../components/ProjectAdd'
import ProjectEdit from '../components/ProjectEdit'
import ProjectChild from '../components/ProjectChild'
import PageUser from '../components/PageUser'
import PageSet from '../components/PageSet'
import ComData from '../components/ComData'
import SmsExcel from '../components/SmsExcel'
import DtExcel from '../components/DtExcel'
import SmsShow from '../components/SmsShow'
import RepShow from '../components/ReportalShow'
import ReportList from '../components/ReportList'
import ErrWord from '../components/ErrWord'
import InfoIndex from '../components/InfoIndex'
import InfoLogic from '../components/InfoLogic'
import InfoPv from '../components/InfoPv'
import InfoScene from '../components/InfoScene'
import PageInstruct from '../components/PageInstruct'
import LogicHong from '../components/LogicHong'
import LogicShow from '../components/LogicShow'
import LogicIndex from '../components/LogicIndex'
import LogicList from '../components/LogicList'
import PageXxz from '../components/PageXxz'
import ChangeLog from '../components/ChangeLog'
import KpiIndex from '../components/KpiIndex'
import KpiHead from '../components/KpiHead'
import KpiSide from '../components/KpiSide'
import KpiShow from '../components/KpiShow'
import KpiZuhe from '../components/KpiZuhe'
import KpiGeshi from '../components/KpiGeshi'
import KpiPv from '../components/KpiPv'
import KpiOpen from '../components/KpiOpen'
import KpiGuan from '../components/KpiGuan'
import PvReverse from '../components/PvReverse'
import PageMessage from '../components/PageMessage'
import QuotaTab from '../components/QuotaTab'
import QuotaRun from '../components/QuotaRun'
import OeIndex from '../components/OeIndex'
import OeRun from '../components/OeRun'
import OeAiTemp from '../components/OeAiTemp'
import OeSentenceManage from '../components/OeSentenceManage'
import OeSentenceTemp from '../components/OeSentenceTemp'
import OeText from '../components/OeText'
import OeSuiji from '../components/OeSuiji'
import RemoteSet from '../components/RemoteSet'

//创建并暴露一个路由器

const router = new VueRouter({

    routes: [{
            path: '/',
            redirect: '/index'

        },
        {
            path: '/main',
            name: 'main',
            component: PageIndex,
            children: [{
                    path: '/index',
                    name: 'index',
                    component: HomeIndex
                },

                {
                    path: '/padd',
                    name: 'padd',
                    component: ProjectAdd,
                    meta: { title: '创建项目' }
                },
                {
                    path: '/pedit/:pid/:ppname/:ppcode',
                    name: 'pedit',
                    component: ProjectEdit,
                    props: true,
                    meta: { title: '项目编辑' }

                },
                {
                    path: '/pchild/:pid/:pname/:pcode',
                    name: 'pchild',
                    component: ProjectChild,
                    props: true,
                    meta: { title: '子项目' }

                },
                {
                    path: '/plist',
                    name: 'plist',
                    component: ProjectList,
                    meta: { title: '项目列表' }
                },
                {
                    path: '/pdata/:pid/:ppname/:ppcode',
                    name: 'pdata',
                    component: ProjectData,
                    props: true,
                    meta: { title: '项目数据' }
                },
                {
                    path: '/transform',
                    name: 'transform',
                    component: TransformExcel

                },
                {
                    path: '/oldtonew/:pid/:pname/:pcode',
                    name: 'oldtonew',
                    component: OldToNew,
                    props: true,
                    meta: { title: 'OldToNew' }

                },
                {
                    path: '/infotomap',
                    name: 'infotomap',
                    component: InfotomapExcel,
                    meta: { title: 'infotomap' }

                },
                {
                    path: '/dt',
                    name: 'dt',
                    component: DtExcel,
                    meta: { title: 'dt' }

                },
                {
                    path: '/ao',
                    name: 'ao',
                    component: AoExcel,
                    meta: { title: 'ao' }

                },
                {
                    path: '/sms',
                    name: 'sms',
                    component: SmsExcel,
                    meta: { title: 'sms' }

                }, {
                    path: '/smshow/:sid',
                    name: 'smshow',
                    component: SmsShow,
                    props: true

                }, {
                    path: '/repshow/:sid',
                    name: 'repshow',
                    component: RepShow,
                    props: true

                }, {
                    path: '/comdata',
                    name: 'comdata',
                    component: ComData,
                    meta: { title: '通用数据' }

                },

                {
                    path: '/set',
                    name: 'set',
                    component: PageSet,
                    meta: { title: '设置' }
                },

                {
                    path: '/user',
                    name: 'user',
                    component: PageUser,
                    meta: { title: '用户管理' }
                },
                {
                    path: '/reportlist/:pid/:pname/:pcode',
                    name: 'reportlist',
                    component: ReportList,
                    props: true,
                    meta: { title: 'reportal' }
                },
                {
                    path: '/errword/:pid/:pname/:pcode',
                    name: 'errword',
                    component: ErrWord,
                    props: true
                },
                {
                    path: '/info/:pid/:pname/:pcode',
                    name: 'info',
                    component: InfoIndex,
                    props: true
                },
                {
                    path: '/infologic/:pid',
                    name: 'infologic',
                    component: InfoLogic,
                    props: true
                },
                {
                    path: '/infopv/:pid',
                    name: 'infopv',
                    component: InfoPv,
                    props: true
                },
                {
                    path: '/infoscene/:pid',
                    name: 'infoscene',
                    component: InfoScene,
                    props: true
                },
                {
                    path: '/logic/:pid/:pname/:pcode',
                    name: 'logic',
                    component: LogicIndex,
                    props: true,
                    meta: { title: 'logic' }
                },
                {
                    path: '/logiclist/:pid',
                    name: 'logiclist',
                    component: LogicList,
                    props: true,
                    meta: { title: 'logic' }
                },
                {
                    path: '/logicshow',
                    name: 'logicshow',
                    component: LogicShow,
                    props: true,
                    meta: { title: 'logic' }
                },
                {
                    path: '/kpi/:pid/:kid/:pname/:bname/:pcode',
                    name: 'kpi',
                    component: KpiIndex,
                    props: true,
                    meta: { title: 'kpi' }
                },
                {
                    path: '/kpihead',
                    name: 'kpihead',
                    component: KpiHead,
                    props: true
                },
                {
                    path: '/kpiside',
                    name: 'kpiside',
                    component: KpiSide,
                    props: true
                },
                {
                    path: '/kpishow',
                    name: 'kpishow',
                    component: KpiShow,
                    props: true
                },
                {
                    path: '/kpigeshi',
                    name: 'kpigeshi',
                    component: KpiGeshi,
                    props: true
                },
                {
                    path: '/kpizuhe',
                    name: 'kpizuhe',
                    component: KpiZuhe,
                    props: true
                },
                {
                    path: '/kpipv/:pid/:pname/:pcode',
                    name: 'kpipv',
                    component: KpiPv,
                    props: true
                },
                {
                    path: '/kpiopen',
                    name: 'kpiopen',
                    component: KpiOpen,
                    props: true
                },
                {
                    path: '/kpiguan',
                    name: 'kpiguan',
                    component: KpiGuan,
                    props: true
                },
                {
                    path: '/pvreverse',
                    name: 'pvreverse',
                    component: PvReverse,
                    props: true
                },
                {
                    path: '/instruct/:pid/:pname/:pcode',
                    name: 'instruct',
                    component: PageInstruct,
                    props: true
                },
                {
                    path: '/hong/:pid',
                    name: 'hong',
                    component: LogicHong,
                    props: true
                },
                {
                    path: '/message',
                    name: 'message',
                    component: PageMessage
                },
                {
                    path: '/xxz',
                    name: 'xxz',
                    component: PageXxz
                },
                {
                    path: '/changelog',
                    name: 'changelog',
                    component: ChangeLog
                },
                {
                    path: '/quotatab/:pid/:kid/:pname/:bname/:pcode',
                    name: 'quotatab',
                    component: QuotaTab,
                    props: true
                },
                {
                    path: '/quotarun/:pid/:kid/:pname/:bname/:pcode',
                    name: 'quotarun',
                    component: QuotaRun,
                    props: true
                }, {
                    path: '/oeindex/:pid/:pname/:pcode',
                    name: 'oeindex',
                    component: OeIndex,
                    props: true,
                    meta: { title: 'oe' }
                }, {
                    path: '/oerun',
                    name: 'oerun',
                    component: OeRun,
                    props: true
                }, {
                    path: '/oesentencemanage',
                    name: 'oesentencemanage',
                    component: OeSentenceManage,
                    props: true
                }, {
                    path: '/oesentencetemp',
                    name: 'oesentencetemp',
                    component: OeSentenceTemp,
                    props: true
                }, {
                    path: '/oetext',
                    name: 'oetext',
                    component: OeText,
                    props: true
                }, {
                    path: '/oeaitemp',
                    name: 'oeaitemp',
                    component: OeAiTemp,
                    props: true
                }, {
                    path: '/oesuiji',
                    name: 'oesuiji',
                    component: OeSuiji
                }, {
                    path: '/remoteset/:pid/:pname/:pcode',
                    name: 'remoteset',
                    component: RemoteSet,
                    props: true,
                    meta: { title: '远程管理' }
                }
            ]
        },
        {
            path: '/login',
            name: 'login',
            component: PageLogin
        },


    ]


})

router.beforeEach((to, from, next) => {
    if (to.path === '/login') {
        // 登录页
        let token = localStorage.getItem('token');
        if (token === null || token === '') {
            next();
        } else {
            router.push("/index")
        }
    } else {
        // 业务
        // const permission = localStorage.getItem('permission')
        // if (permission === null || permission === '') {
        //     next('/login');
        // } else {
        //     let perm = JSON.parse(permission)
        //     let menu = perm.menu
        //     let key = to.name
        //     if (!menu[key]) {
        //         // 无权限
        //         next('/index');
        //     }
        //     // console.log(to.name)
        // }

        let token = localStorage.getItem('token');
        if (token === null || token === '') {
            next('/login');
        } else {
            next();
        }
        if (to.meta.title) {
            document.title = to.meta.title + "-Morningstar"
        } else {
            document.title = "Morningstar"
        }
    }
});
export default router