<template>
  <div>
    <div id="goback" style="margin-bottom: 10px; font-size: 14px">
      <i
        class="el-icon-back"
        @click="$router.go(-1)"
        style="cursor: pointer; font-size: 14px"
        >返回</i
      >&nbsp;&nbsp;
      <span style="font-size: 14px; color: rgb(192, 196, 204)">|</span>
      &nbsp;&nbsp;<span style="font-size: 16px; color: rgb(192, 196, 204)"
        >编号：</span
      >{{ pcode }}
      <span style="font-size: 14px; color: rgb(192, 196, 204)">项目：</span
      >{{ pname }}
    </div>
    <div id="content">
      <div class="op">
        <el-button
          type="danger"
          size="small"
          icon="el-icon-delete"
          :loading="shuaxin"
          @click="del"
          >删除</el-button
        >
      </div>
      <div class="plist">
        <el-table row-key="id" :data="tableData" ref="multipleTable" size="medium">
          <el-table-column type="selection"> </el-table-column>
          <el-table-column sortable prop="pcode" label="项目编号" width="150">
            <template slot-scope="scope">
              <span  v-if="scope.row.pcode=='null'" style="color:#c0c4cc">null</span>
              <span  v-else>{{scope.row.pcode}}</span>
            </template>
          </el-table-column>
          <el-table-column sortable prop="pname" label="项目名称" width="300">
          </el-table-column>
          <el-table-column  prop="remote_state" label="项目状态" width="100">
          </el-table-column>
          <el-table-column label="操作" width="550">
            <template slot-scope="scope">
              <!-- <router-link
                :to="{
                  name: 'remoteset',
                  params: {
                    pid: scope.row.id,
                    ppcode: scope.row.pcode,
                    ppname: scope.row.pname,
                  },
                }"
                class="caozuo"
                >远程</router-link
              > -->
              <router-link
                :to="{
                  name: 'pdata',
                  params: {
                    pid: scope.row.id,
                    ppcode: scope.row.pcode,
                    ppname: scope.row.pname,
                  },
                }"
                class="caozuo"
                >项目数据</router-link
              >
              <router-link
                :to="{
                  name: 'pedit',
                  params: {
                    pid: scope.row.id,
                    ppcode: scope.row.pcode,
                    ppname: scope.row.pname,
                  },
                }"
                class="caozuo"
                >编辑</router-link
              >
              <router-link
                :to="{
                  name: 'oldtonew',
                  params: {
                    pid: scope.row.id,
                    pcode: scope.row.pcode,
                    pname: scope.row.pname,
                  },
                }"
                class="caozuo"
                >Oldtonew</router-link
              >
              <router-link
              v-if="perm.oe"
                :to="{
                  name: 'oeindex',
                  params: {
                    pid: scope.row.id,
                    pcode: scope.row.pcode,
                    pname: scope.row.pname,
                  },
                }"
                class="caozuo"
                >OE</router-link
              >
              <router-link
                :to="{
                  name: 'info',
                  params: {
                    pid: scope.row.id,
                    pcode: scope.row.pcode,
                    pname: scope.row.pname,
                  },
                }"
                class="caozuo"
                >Info</router-link
              >
              <router-link
                :to="{
                  name: 'logic',
                  params: {
                    pid: scope.row.id,
                    pcode: scope.row.pcode,
                    pname: scope.row.pname,
                  },
                }"
                class="caozuo"
                >Logic</router-link
              >
              <router-link
                :to="{
                  name: 'instruct',
                  params: {
                    pid: scope.row.id,
                    pcode: scope.row.pcode,
                    pname: scope.row.pname,
                  },
                }"
                class="caozuo"
                >Instruct</router-link
              >
              <router-link
                :to="{
                  name: 'reportlist',
                  params: {
                    pid: scope.row.id,
                    pcode: scope.row.pcode,
                    pname: scope.row.pname,
                  },
                }"
                class="caozuo"
                >Kpi</router-link
              >
            </template>
          </el-table-column>

          <el-table-column
            sortable
            prop="created_time"
            label="创建日期"
            width="160"
          >
            <template slot-scope="scope">
              <div :style="{ color: '#c0c4cc', 'font-size': '12px' }">
                {{ scope.row.created_time }}
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ProjectChild",
  props: ["pid", "pname", "pcode"],
  data() {
    return {
      shuaxin: false,
      tableData: [],
      perm: {
        delete: true,
        check: true,
        quota: true,
        dc: true,
        data: true,
        result: true,
        oe:true,
      }
    };
  },
  created() {
    const permission = localStorage.getItem("permission");
    var perm = JSON.parse(permission);
    this.perm = perm.project;
    //
    var api = this.COMMON.httpUrl + "projectChild";
    var data = { pid: this.pid };
    this.axios.post(api, data).then((response) => {
      var rs = response.data;
      if (rs.code) {
        this.$message.error(rs.message);
      } else {
        this.tableData = rs.data;
      }
    });
  },
  methods: {    
 
    del() {
      var s = [];
      this.$refs.multipleTable.selection.forEach((Ele) => {
        s.push(Ele.id);
      });
      if (s.length == 0) {
        return;
      }

      this.$confirm(
        "删除将会影响该项目运行中和待运行的任务, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.savedel();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    savedel() {
      this.shuaxin=true
      var s = [];
      this.$refs.multipleTable.selection.forEach((Ele) => {
        s.push(Ele.id);
      });

      var api = this.COMMON.httpUrl + "projectChildDel";
      var data = {
        pids: s,
        fid:this.pid
      };
      this.axios.post(api, data).then((response) => {
        var rs = response.data;
        this.shuaxin=false
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          this.$message.success(rs.message);
          this.tableData = rs.data;
        }
      });
    },
  },


};
</script>
<style scoped>
#content {
  background-color: white;
  width: 1360px;
  text-align: left;
  padding-left: 0px;
}

.bianhao {
  color: red;
}

.op {
  padding: 20px 0;
}

.fenye {
  margin-top: 20px;
}

.cell a {
  color: #666;
  text-decoration: none;
}

.cell a:hover {
  color: rgb(125, 163, 234);
  text-decoration: underline;
}

.caozuo {
  color: #409eff !important;
  margin-right: 10px;
}

.result {
  color: #409eff !important;
  margin-left: 10px;
}
.zxm {
}
.zxm p {
  margin-top: 15px;
}
</style>