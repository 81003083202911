<template>
  <div>
    <div id="goback" style="margin-bottom: 10px; font-size: 14px">
      <i class="el-icon-back" @click="$router.go(-1)" style="cursor: pointer"
        >返回</i
      >&nbsp;&nbsp;
      <span style="font-size: 14px; color: rgb(192, 196, 204)">|</span>
      &nbsp;&nbsp;<span style="color: rgb(192, 196, 204)">编号：</span
      >{{ pcode }} <span style="color: rgb(192, 196, 204)">项目：</span
      >{{ pname }}
    </div>
    <div id="content">
      <div class="op">
        <el-button size="small" icon="el-icon-refresh" :loading="load" type="primary"  @click="make">生成 PV</el-button>

        <template :loading="load">
  <el-radio v-model="biao" label="1" style="margin-left:30px" @input="change" >全部</el-radio>
  <el-radio v-model="biao" label="2"  @input="change">标色</el-radio>
  <el-button
              size="small"
              type="primary"
              @click="copyElement"
              plain
              >复制表格</el-button
            >
            <el-checkbox v-model="scenes" @change="scene" style="margin-left:30px">隐藏scene</el-checkbox>
</template>

      </div>
      <div v-if="tbs.length==0" style="color:#999;text-align:center">暂无数据</div>
      <div id="plist" v-if="tbs.length>0">
        <table  cellspacing="0"  cellpadding="0" align="center"   class="tbs">
          <thead>
            <tr>
              <th>QuestionID</th>
              <th>VariableID</th>
              <th>AnswerCode</th>
              <th>AnswerLabel</th>
              <th>P</th>
              <th>Pnow</th>
              <th>CountNow</th>
              <th>CountBase</th>
              <th v-if="sceneShow">Scene</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(r,index) in tbs" :key="index">
              <td>{{r.qid}}</td>              
              <td>{{r.vid}}</td>
              <td>{{r.acode}}</td>
              <td>{{r.alabel}}</td>
              <td>{{r.pv}}</td>              
              <td  v-html="r.pnow" class="pnow"></td>              
              <td>{{r.countnow}}</td>
              <td>{{r.countbase}}</td>
              <td  v-if="sceneShow" style="min-width:500px">
                <div v-html="r.scene"></div>
              </td>            
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "KpiPv",
  props: ["pid", "pname", "pcode"],
  data() {
    return {
      biao:"1",
      load:false,
      scenes:false,
      sceneShow:true,
      tbs: [],            
    };
  },
  created() {
    this.getpv()  
  },
  methods: {
    scene(){
      if(this.scenes){
        this.sceneShow=false
      }else{
        this.sceneShow=true
      }
    },
    copyElement() {
      let sourceElement = document.getElementById("plist");
      let range = document.createRange();
      range.selectNode(sourceElement);
      let selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      document.execCommand("copy");
      selection.removeRange(range);
      this.$message.success("已复制表格到剪贴板");
    },
    change(){
      this.getpv()  
    },
    getpv(){
      this.load=true
    var api = this.COMMON.httpUrl + "pvShow";
    var data = {
      pid: this.pid,
      biao:this.biao
    };
    this.axios.post(api, data).then((response) => {
      this.load=false
      var rs = response.data;
      if (rs.code) {
        this.$message.warning(rs.message);
      } else {
        this.tbs = rs.data;        
      }
    });    
    },
    make(){
   
     this.load=true
    var api = this.COMMON.httpUrl + "pvMake";
    var data = {
      pid: this.pid,
      biao:this.biao
    };
    this.axios.post(api, data).then((response) => {
      this.load=false
      var rs = response.data;
      if (rs.code) {
        this.$message.error(rs.message);
      } else {
        this.tbs = rs.data;        
      }
    }); 
    }
  },
};
</script>
  
<style scoped>
#content {
  background-color: white;
  min-width:1200px;
  width: auto;
  text-align: left;
  padding-left: 0px;
}
.op {
    padding: 20px 0;
}
.tbs{
  margin-top:20px;
  border: 1px solid #ccc;
  border-collapse: collapse;
}

.tbs th{
	background-color:#e6e3e3;
	height:40px;
	line-height:40px;
  min-width: 80px;
  width:auto!important;
  text-align: left;
  padding:0 15px;
  border: 1px solid #ccc;
}
.tbs td{
	height:25px;
	line-height:150%;
  min-width: 80px;
  width:auto!important;
  text-align: left;
  padding:0 15px;
  border: 1px solid #ccc;
}
.tbs thead{
  position: sticky !important;
  top:-22px!important;
  z-index: 10 !important;
}
.tbs tbody tr:hover {
  background-color:#e6e3e3!important;
}
td.pnow{
  /* background-color:#95d947!important; */
  background-color:#0099ff;
  color:white;
}
</style>
<style>
p.biao,p.biao2{
  background-color:#d36a6a!important;
  color:white;
}
</style>