<template>
  <div>
    <div id="content">
      <div class="anl">        
        <el-upload
          class="upload"
          name="oe"
          :action="upurl"
          :headers="{ Authorization: token }"
          auto-upload
          :show-file-list="false"
          :before-upload="beforeAvatarUpload"
          :on-success="onSuccess"
          ><el-button type="primary"  size="small">导入</el-button></el-upload
        ><el-link type="primary" :href="tempurl" style="margin-left: 10px"
          >下载导入模板</el-link
        >
      </div>
      <div class="oelist">
        <el-table size="mini" row-key="id" :data="configs" fit border>                        
                <el-table-column prop="lang" label="语言" width="100">
                </el-table-column>
                <el-table-column prop="teshu" label="特殊文本" >
                </el-table-column>       
                <el-table-column prop="suiji" label="随机文本">                                  
                </el-table-column>                               
              </el-table>
      </div>

    </div>
  </div>
</template>
  <script>
export default {
  name: "OeSuiji",
  data() {
    return {
      upurl: this.COMMON.httpUrl + "oeSuijiImport",
      tempurl:this.COMMON.httpUrl + "storage/temp/oeSuijiImport.xlsx",
      token: "Bearer " + localStorage.getItem("token"),
      configs:[],
    };
  },
 
  methods: {
    getQid() {
      var api = this.COMMON.httpUrl + "oeSuiji";
      var data = {};
      this.axios.post(api, data).then((response) => {
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          this.configs = rs.data;
        }
      });
    },
    
  
    beforeAvatarUpload(file) {
      this.loading = true;
      let fileName = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = fileName === "xls";
      const extension2 = fileName === "xlsx";
      if (!extension && !extension2) {
        this.$message({
          message: "上传文件只能是 xls、xlsx格式!",
          type: "warning",
        });
        return false;
      }
    },
    onSuccess(res) {
      if (res.code) {
        this.$message.error(res.message);
      } else {
        this.$message.success(res.message);
        this.configs = res.data;
      }

    },
  },
  created() {
    this.getQid();
  },
};
</script>
  <style scoped>
#content {
  background-color: white;
  padding-top: 40px;
  padding-left: 50px;
  width:1000px;
}

.anl {
 border:0px solid red;
  width: 600px!important;
}
.oelist{
  margin-top:50px;
}
.upload {
  display: inline-block;
  margin: 0 8px;
}
</style>
 
   