<template>
  <div>
    <div id="content">
      <el-row :gutter="50">
        <el-col :span="13">
          <div>
            <el-button
              type="primary"
              style="float: right"
              @click="onSubmit"
              size="small"
              :loading="btload"
              >添加</el-button
            >
          </div>
          <div class="part0">
            <el-divider content-position="left">KEY</el-divider>
          </div>

          <div class="part1" style="padding-top: 30px">
            <el-input
              v-model="hname"
              placeholder="请输入宏名称"
              @blur="keycheck"
            ></el-input>
          </div>
          <div class="yan">
            <p>{{ yan }}</p>
          </div>

          <div class="part0">
            <el-divider content-position="left">VALUES</el-divider>
          </div>
          <div class="part1">
            <div style="height: 30px">
              <p class="tips" v-if="qtype != ''">
                Question Type:<span>{{ qtype }}</span>
              </p>
            </div>

            <el-select
              class="inp"
              v-model="valueq"
              clearable
              filterable
              @change="changeselect()"
              placeholder="请选取Question ID/ Variable ID"
            >
              <el-option
                v-for="(item, index) in optionq"
                :key="index"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>

            <el-select
              class="inp"
              v-model="valuea"
              clearable
              :multiple="multiple"
              filterable
              allow-create
              collapse-tags
              default-first-option
              placeholder="请选取Answer Label"
            >
              <el-option
                v-for="(item, index) in optiona"
                :key="index"
                :label="item.alable"
                :value="item.ax"
              >
              </el-option>
            </el-select>
          </div>
          <div class="part2">
            <el-radio-group v-model="expression" size="mini">
              <el-radio-button label="1" :disabled="able[1]"
                >单项选中</el-radio-button
              >
              <el-radio-button label="2" :disabled="able[2]"
                >任一选中</el-radio-button
              >
              <el-radio-button label="3" :disabled="able[3]"
                >均未选中</el-radio-button
              >
              <el-radio-button label="4" :disabled="able[4]"
                >大于</el-radio-button
              >
              <el-radio-button label="5" :disabled="able[5]"
                >大于等于</el-radio-button
              >
              <el-radio-button label="6" :disabled="able[6]"
                >小于</el-radio-button
              >
              <el-radio-button label="7" :disabled="able[7]"
                >小于等于</el-radio-button
              >
              <el-radio-button label="8">null</el-radio-button>
              <el-radio-button label="9">!null</el-radio-button>
              <el-radio-button label="10">&</el-radio-button>
              <el-radio-button label="11">or</el-radio-button>
            </el-radio-group>
          </div>
          <div class="part3">
            <el-input
              spellcheck="false"
              placeholder="表达式生成,可编辑"
              type="textarea"
              v-model="product"
              class="product"
              rows="3"
            ></el-input>
          </div>
          <div class="part4">
            <el-button type="info" size="small" @click="reset2()"
              >重置</el-button
            >
            <el-button type="warning" size="small" @click="make"
              >解析表达式</el-button
            >
          </div>
        </el-col>
        <el-col :span="11">
          <div class="op">
            <el-button
              type="success"
              icon="el-icon-circle-plus-outline"
              size="small"
              @click="save"
              >提交保存</el-button
            >
          </div>
          <el-divider content-position="left"></el-divider>
          <template>

          <el-table :data="tables" size="mini">
            <el-table-column prop="type" label="Type" width="60">
            </el-table-column>
            <el-table-column prop="id" label="Id" width="60"> </el-table-column>
            <el-table-column sortable prop="key" label="Key" width="140">
            </el-table-column>
            <el-table-column prop="values" label="Values"> </el-table-column>
            <el-table-column fixed="right" label="操作" width="120">
              <template slot-scope="scope">
                <el-button
                  @click.native.prevent="deleteRow(scope.$index, tables)"
                  type="text"
                  size="mini"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </template>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
export default {
  name: "LogicHong",
  props: ["pid"],
  data() {
    return {
      // key部分
      hname: "",
      yan: "",
      // values部分
      btload: false,
      tables: [],
      able: [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ],
      expression: "", //表达式
      expres: [
        "0",
        "==",
        "",
        "",
        ">",
        ">=",
        "<",
        "<=",
        "=null",
        "!=null",
        " and ",
        " or ",
      ],
      qtype: "", //当前变量 Type
      multiple: true,
      product: "", //生成的表达式
      optionq: [], //question选项-- 通用
      valueq: "", //question 值
      optiona: [], //anser选项
      valuea: [], //answer值
      qqs: [], //变量对应的Acode Alable
      tps: [], //变量对应的Type集合
    };
  },

  created() {
    this.inita();
  },
  methods: {
    keycheck() {
      var hname = this.hname.trim();
      if (hname == "") {
        return;
      }
      var api = this.COMMON.httpUrl + "hongCheckName";
      var data = {
        hname: hname,
        pid: this.pid,
      };
      this.axios.post(api, data).then((response) => {
        var rs = response.data;
        if (rs.code) {
          // this.$message.error(rs.message);
          this.yan = rs.message;
        } else {
          this.yan = "";
        }
      });
    },

    //改变左侧下拉框,更新右侧下拉选项
    changeselect() {
      this.qtype = this.tps[this.valueq];
      this.optiona = this.qqs[this.valueq];
      this.valuea = "";
      this.chable(this.qtype);
    },

    //初始化加载数据
    inita() {
      // console.log(this.pid);
      var api = this.COMMON.httpUrl + "hongSelect";
      var data = {
        pid: this.pid,
      };
      this.axios.post(api, data).then((response) => {
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          this.optionq = rs.data.sArray;
          this.qqs = rs.data.oArray;
          this.tps = rs.data.tpArray;
        }
      });
    },
    //改变表达式可选
    chable(t) {
      switch (t) {
        case "ranking":
          this.able = [false, false, true, false, false, false, false, false];
          break;
        case "numericlist":
          this.able = [false, false, true, false, false, false, false, false];
          break;
        case "multi":
          this.able = [false, false, false, false, true, true, true, true];
          break;
        default:
          this.able = [false, false, false, false, false, false, false, false];
      }
    },

    //添加暂存
    onSubmit() {
      if (this.hname.trim() == "") {
        this.$message.error("请输入宏名称");
        return;
      }
      if (this.hname.trim() != "" && this.yan.trim() != "") {
        this.$message.error("请更换宏名称");
        return;
      }
      if (this.product.trim() == "") {
        this.$message.error("表达式不能为空");
        return;
      }
      this.tables.push({
        pid: this.pid,
        type: "宏",
        id: "",
        key: this.hname.trim(),
        values: this.product.trim(),
        source: "宏",
      });
      this.reset2();
    },
    deleteRow(index, rows) {
      rows.splice(index, 1);
    },
    //重置
    reset1() {
      this.valueq = "";
      this.valuea = "";
      this.expression = "";
      this.qtype = "";
      this.able = [false, false, false, false, false, false, false, false];
    },
    reset2() {
      this.hname = "";
      this.valueq = "";
      this.valuea = "";
      this.expression = "";
      this.qtype = "";
      this.product = "";
      this.able = [false, false, false, false, false, false, false, false];
    },
    //生成
    make() {
      //1，判断连接符

      if (this.expression == "10" || this.expression == "11") {
        this.product += this.expres[this.expression];
        this.reset();
        return;
      }

      // null !null
      if (this.expression == "8") {
        this.product += this.valueq + " = null";
        this.resets();
        return;
      }
      if (this.expression == "9") {
        this.product += this.valueq + " != null";
        this.resets();
        return;
      }

      //,2,判断是不是都选上了
      if (this.valueq == "") {
        this.$message.error("请选取Question ID/ Variable ID");
        return;
      }
      if (
        this.qtype != "ranking" &&
        this.qtype != "numericlist" &&
        this.valuea == ""
      ) {
        this.$message.error("请选取Answer Label");
        return;
      }
      if (this.expression == "") {
        this.$message.error("请选取表达式");
        return;
      }
      //2，判断单多选，为任一选中/均未选中时才能选多项
      if (
        this.expression != "2" &&
        this.expression != "3" &&
        this.valuea.length > 1
      ) {
        this.$message.error("该条件下Answer Label不能多选，请重新选取");
        return;
      }

      //3,根据不同题型，分别处理

      switch (this.qtype) {
        case "single":
          switch (this.expression) {
            case "2":
              //任一选中
              this.product += this.valueq + " in [" + this.valuea + "]";
              break;
            case "3":
              //均未选中
              var s = "";
              for (let item of this.valuea) {
                if (s == "") {
                  s = this.valueq + "!=" + item;
                } else {
                  s += " and " + this.valueq + "!=" + item;
                }
              }
              this.product += s;
              break;
            default:
              //其他表达式
              this.product +=
                this.valueq + this.expres[this.expression] + this.valuea;
          }

          break;
        case "grid":
          switch (this.expression) {
            case "2":
              //任一选中
              this.product += this.valueq + " in [" + this.valuea + "]";
              break;
            case "3":
              //均未选中
              s = "";
              for (let item of this.valuea) {
                if (s == "") {
                  s = this.valueq + "!=" + item;
                } else {
                  s += " and " + this.valueq + "!=" + item;
                }
              }
              this.product += s;
              break;
            default:
              //其他表达式
              this.product +=
                this.valueq + this.expres[this.expression] + this.valuea;
          }

          break;
        case "multi":
          switch (this.expression) {
            case "2":
              //任一选中

              s = "";
              for (let item of this.valuea) {
                if (s == "") {
                  s = item + "==1";
                } else {
                  s += " or " + item + "==1";
                }
              }
              this.product += s;

              break;
            case "3":
              //均未选中
              s = "";
              for (let item of this.valuea) {
                if (s == "") {
                  s = item + "!=1";
                } else {
                  s += " and " + item + "!=1";
                }
              }
              this.product += s;
              break;
            default:
              //单项选中
              this.product += this.valuea + "==1";
          }

          break;
        default:
          this.product += this.valueq + this.expres[this.expression];
      }

      //重置一下
      this.reset1();
    },

    //提交保存
    save() {
      if (this.tables.length == 0) {
        return;
      }

      var api = this.COMMON.httpUrl + "hongAdd";
      var data = {
        hong: this.tables,
        pid: this.pid,
      };
      this.axios.post(api, data).then((response) => {
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          this.tables = [];
          this.$message.success(rs.message);
        }
      });
    },
  },
};
</script>
<style scoped>
#content {
  background-color: white;
  padding-top: 50px;
  padding-left: 40px;
  width: 90%;
}

.tips {
  font-size: 13px;
  color: #b0b3ae;
}

.tips span {
  font-size: 14px;
  color: green;
  margin-left: 10px;
}

.inp {
  width: 240px;
  margin-right: 10px;
}

.part0 {
  margin-top: 60px;
}

.part2 {
  margin-top: 40px;
}

.part3 {
  margin-top: 40px;
}

.part4 {
  margin-top: 15px;
}

.product {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  width: 600px;
}
.yan {
  height: 20px;
  padding-top: 5px;
  font-size: 12px;
  color: red;
}
</style>