<template>
  <div>

    <div id="content">
      <div class="block">
        <el-button
        class="mar10"
          size="small"
          type="primary"
          @click="preview()"
          :loading="loading"
          >生成预览</el-button
        >
        <el-button
        class="mar10"
          size="small"
          type="warning"
          @click="pdowndata('openedid.xlsx', 'reportOpenedidDown','不存在openedid')"
          >下载openedID</el-button
        >
        <el-button size="small" type="success" @click="save"  class="mar10">保存修改</el-button>     
        <p :class="{'next':nextT.length>10,'nextN':nextT.length<=10}">{{nextT}}</p>        
      </div>
      <div class="block" style="width:80%;margin-top:100px">
      <el-divider content-position="left">任务预览</el-divider>      
    </div>
      <div class="block">
          内部浏览：<span id="preview">{{ preViewUrl }}</span
          ><el-button
            size="mini"
            plain
            style="margin-left: 20px"
            @click="copyElement('preview')"
            >复制</el-button
          >
        </div>
      <div class="block">

        uploadID：
        <span class="tips">{{uploadCount}}</span>
        <el-upload
          class="mar10 upload"
          
            :headers="{ Authorization: token }"
            name="rid"
            :action="upUrl"
            accept=".xls,.xlsx"
            auto-upload
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
            :on-success="onSuccess"
            :data="{pid: pid,kid:kid }"
          >
            <el-button
            round
              size="mini"
              plain
              type="success"
            >上传</el-button>
          </el-upload>

          <el-button
          round
          size="mini"
              plain
              type="danger"                    
            @click="reportRidDel(1)"
            >清除</el-button
          >   
      </div>
      <div class="block">
        quotaID： <span class="tips">{{quotaCount}}</span> 

        <el-button
            round
              size="mini"
              plain
              type="success"
              @click="pdowndata('quotaid.xlsx', 'reportQuotaidDown','不存在quotaid')"
            >下载</el-button>
        <el-button
          round
          size="mini"
              plain
              type="danger"        
              @click="reportRidDel(2)"            
            >清除</el-button
          >   
      </div>
      <div class="block" style="width:80%;margin-top:100px">
      <el-divider content-position="left">任务设置</el-divider>      
    </div>
      <div class="block">
        开放浏览：<el-switch
          v-model="open"
          active-color="#13ce66"
          inactive-color="#bbbbbb"
          :active-value="1"
          :inactive-value="0"
        >
        </el-switch>
      </div>
      <transition name="slide-fade">
        <div class="block" v-if="open == 1">
          外部浏览：<span id="view">{{ viewUrl }}</span
          ><el-button
            size="mini"
            plain
            style="margin-left: 20px"
            @click="copyElement('view')"
            >复制</el-button
          >
        </div>
      </transition>
      <div class="block">
        起止时间：
        <el-date-picker
          v-model="qizhi"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </div>
      <div class="block">
        <div>
          更新时间：<el-button
            type="text"
            icon="el-icon-circle-plus-outline"
            style="margin-left: 20px"
            @click="addgs"
            >新增</el-button
          >
        </div>

        <div class="geng" v-for="(item, index) in gs" :key="index">
          <el-date-picker
            v-model="item.tm"
            type="datetime"
            placeholder="更新时间"
          >
          </el-date-picker>
          <el-input
            clearable
            v-model="item.count"
            placeholder="数量"
            style="width: 90px; margin-left: 10px"
          ></el-input
          ><el-button
            v-if="gs.length > 1"
            type="text"
            icon="el-icon-remove-outline"
            style="color: #f56c6c; margin-left: 20px"
            @click="delgs(index)"
            >删除</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
export default {
  name: "KpiOpen",
  props: ["kid", "pid"],
  data() {
    return {
      loading:false,
      token: "Bearer " + localStorage.getItem("token"),
      upUrl: this.COMMON.httpUrl + "reportRidUpload",
      nextT:"",
      uploadCount:0,
      quotaCount:0,
      viewUrl: "",
      preViewUrl: "",
      open: 0,
      qizhi: "",
      gs: [],
    };
  },

  methods: {

    isPositiveInteger(n) {
      return /^[1-9]\d*$/.test(n);
    },
    addgs() {
      this.gs.push({ tm: new Date(), count: "" });
    },
    delgs(index) {
      if (this.gs.length > 1) {
        this.gs.splice(index, 1);
      }
    },
    preview() {
      this.loading=true
      var api = this.COMMON.httpUrl + "reportRunNextTask";
      var data = {
        pid: this.pid,
        kid: this.kid,
      };
      this.axios.post(api, data).then((response) => {
        this.loading=false
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          this.$message.success("生成预览成功");
        }
      });
    },
    reportRidDel(tp) {
      var api = this.COMMON.httpUrl + "reportRidDel";
      var data = {
        pid: this.pid,
        kid: this.kid,
        tp:tp
      };
      this.axios.post(api, data).then((response) => {
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          if(tp==1){
            this.uploadCount=0
          }else{
            this.quotaCount=0
          }
          
          this.$message.success(rs.message);
        }
      });
    },
    getOpen() {
      var api = this.COMMON.httpUrl + "reportGetOpen";
      var data = {
        pid: this.pid,
        kid: this.kid,
      };
      this.axios.post(api, data).then((response) => {
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          this.nextT=rs.data.nextT,
          this.uploadCount=rs.data.uploadcount;
          this.quotaCount=rs.data.quotacount;
          this.open = rs.data.open;
          this.viewUrl = rs.data.viewurl;
          this.preViewUrl = rs.data.previewurl;
          let start = rs.data.start;
          let end = rs.data.end;
          if ((start == 0 || start === "") && (end == 0 || end === "")) {
            this.qizhi = "";
          } else {
            start = new Date(start * 1000);
            end = new Date(end * 1000);
            this.qizhi = [start, end];
          }

          if (rs.data.geng == "") {
            this.gs.push({ tm: new Date(), count: "" });
          } else {
            let gss = JSON.parse(rs.data.geng);
            for (let a of gss) {
              this.gs.push({
                tm: new Date(a.tm * 1000),
                count: a.count.toString(),
              });
            }
          }
        }
      });
    },
    save() {
    
      if (this.qizhi==null || this.qizhi.length == 0) {
        alert("请选择起止时间");
        return;
      }
      let gss = [];
      for (let a of this.gs) {
        if (!this.isPositiveInteger(a.count.trim())) {
          alert("更新数量必须输入正整数");
          return;
        }
        gss.push({ tm: a.tm.getTime() / 1000, count: a.count.trim() * 1 });
      }

      // 提交
      var api = this.COMMON.httpUrl + "reportOpenSave";
      var data = {
        pid: this.pid,
        id: this.kid,
        open: this.open,
        start: this.qizhi[0].getTime() / 1000,
        end: this.qizhi[1].getTime() / 1000,
        geng: JSON.stringify(gss),
      };
      this.axios.post(api, data).then((response) => {
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          this.nextT=rs.data.nextT;
          this.$message.success(rs.message);
        }
      });
    },
    copyElement(id) {
      let sourceElement = document.getElementById(id);
      let range = document.createRange();
      range.selectNode(sourceElement);
      let selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      document.execCommand("copy");
      selection.removeRange(range);

       // 请求预览权限
       var api = this.COMMON.httpUrl + "reportPreviewAuth";
      var data = {};
      this.axios.post(api, data).then((response) => {
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          this.$message.success("已复制浏览地址到剪贴板");
        }
      });
    },
    beforeAvatarUpload(file) {
      let fileName = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = fileName === "xls";
      const extension2 = fileName === "xlsx";
      if (!extension && !extension2) {
        this.$message({
          message: "上传文件只能是 xls、xlsx格式!",
          type: "warning",
        });

        return false;
      }
    },
    onSuccess(res) {
      if (res.code) {
        this.$message.error(res.message);
      } else {
        this.uploadCount=res.data.uploadcount
        this.$message.success(res.message);
      }
    },
     // 文件下载
    pdowndata(fname, url,er) {

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: this.token,
        },
        body: JSON.stringify({ pid: this.pid, kid: this.kid }),
      };

      let src = this.COMMON.httpUrl + url;
      fetch(src, requestOptions)
        .then((response) => {
          if (!response.ok) {
            throw new Error(er);
          }
          return response.blob();
        })
        // .then((resp) => resp.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          // 文件名称可以在这里更改
          a.download = fname;
          a.href = url;
          a.click();
        })
        .catch((error) => {
          // alert(error)
          this.$message.error(error);
        });
    },
  },
  created() {
    localStorage.setItem("kpitag","seven")
    this.getOpen();
  },
};
</script>
  <style scoped>
#content {
  background-color: white;
  padding-top: 40px;
  padding-left: 50px;
}
.block {
  width: 1200px;
  margin-bottom: 50px;
  border:0px solid red;
}
.anl {
  margin-bottom: 20px;
}
.geng {
  margin-top: 15px;
}
#view,#preview {
  color: #aaa;
  font-style: italic;
}
/* 可以设置不同的进入和离开动画 */
/* 设置持续时间和动画函数 */
.slide-fade-enter-active {
  transition: all 0.6s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.read {
  margin-top: 50px;
}
.read p {
  font-size: 16px;
  font-weight: 800;
  color: #666;
}
.read span {
  margin-left: 30px;
}
.read .rid {
  height: 300px;
  overflow: auto;
  padding:5px 10px;
  background-color: #f6f4f4;
}
.read .tit {
  background-color: #e5e1e1;
  padding: 10px 0;
}
.read .op {
  text-align: right;
  padding-bottom: 15px;
}
.upload {
  display: inline-block;
  margin: 0 8px;
}
.mar10 {
  margin-right: 10px!important;
}
.tips{  
  margin:0 15px;
  font-size:16px;  
  /* color:#67C23A; */
}
.next{
  color:#3ec137;
  font-size:16px;
  font-weight: 500;
  margin-top:50px;
}
.nextN{
  color:#bbbebb;
  font-size:14px;
  margin-top:40px;
}
</style>

   