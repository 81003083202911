<template>
    <div>

        <div id="content">

            <el-row :gutter="20">
                <el-col :span="12">

                    <div class="part1">
                        <el-button size="small" @click="reset">重置</el-button>
                        <el-button type="primary" size="small" @click="addTag">添加Table</el-button>
                    </div>

                    <el-row :gutter="20">
                        <el-col :span="12">
                            <div class="part4">
                                <el-checkbox v-model="batch">同质化批量处理</el-checkbox>
                            </div>


                            <div class="part2">
                                <div>
                                    <b>表头：</b><el-select v-model="headSelect"  filterable clearable collapse-tags
                                        placeholder="请选择 QuestionID" :disabled="seleDisabled">
                                        <el-option v-for="qid in qids" :key="qid" :label="qid" :value="qid"></el-option>
                                    </el-select>
                                </div>
                            </div>
                   

                        </el-col>
                        <el-col :span="12">
                            <div class="part2-2">
                                <div>
                                    <b>表侧：</b><el-select v-model="sideSelect" multiple filterable clearable collapse-tags
                                        placeholder="请选择 QuestionID" :disabled="seleDisabled">
                                        <el-option v-for="qid in qids" :key="qid" :label="qid" :value="qid"></el-option>
                                    </el-select>
                                </div>
                            </div>
                            <div class="part3">
                                <div class="weizhi">
                                    <div v-for="(item, index) of sideRadio" :key="index">
                                        <el-radio-group v-model="item.weizhi" fill="#f55dda">
                                            <el-radio-button :label="i" v-for="(w, i) of item.zu" :key="i">{{ w
                                            }}</el-radio-button>
                                        </el-radio-group>
                                    </div>
                                </div>
                            </div>
                        </el-col>

                    </el-row>


                </el-col>
                <el-col :span="12">
                    <div class="part0">
                        <el-button type="success" size="small" v-loading="loading" @click="saveTemp">保存模板</el-button>
                        <el-button type="warning" size="small" v-loading="loading" @click="down">下载模板</el-button>
                        <el-upload class="upload" name="logicmake" :action="upurl"  :headers="{ Authorization: token}" :data="{pid: pid,op:'pv'}" auto-upload :show-file-list="false"
                            :before-upload="beforeAvatarUpload" :on-success="onSuccess"><el-button type="primary"
                                size="small" v-loading="loading">导入生成</el-button></el-upload><el-button type="danger"
                            size="small" v-loading="loading" @click="tagClear">清空模板</el-button>

                    </div>
                    <div class="tags">
                        <el-tag v-for="tag in tags" :key="tag" closable :type="'info'" @close="tagDel(tag)" effect="light">
                            {{ tag }}
                        </el-tag>
                    </div>

                </el-col>

            </el-row>

        </div>
    </div>
</template>
<script>
export default {
    name: 'InfoPv',
    props: ['pid'],
    data() {
        return {
            upurl: this.COMMON.httpUrl+"infoTempMake",
            token:"Bearer " + localStorage.getItem("token"),
            loading: false,
            seleDisabled: true,
            batch: false,
            sideRadio: [],
            headSelect: "",
            sideSelect: [],
            qids: [],
            qidsub: {},
            tags: [],
            configs: {}
        }
    },
    watch: {
      

        // 表侧为单选
        sideSelect(val) {
            if (val.length == 0) {
                this.sideRadio = []
                return
            }
            let linshi = new Array();
            for (let qid of val) {
                    const vid = this.qidsub[qid].vid
                    const ks = vid.split("_")
                    linshi.push({
                        qid: qid,
                        weizhi: "",
                        zu: ks
                    })
                }
            this.sideRadio = linshi

        }
    },
    methods: {
        getQid() {
            // this.$message.warning("数据加载中，请稍候")
            var api = this.COMMON.httpUrl + "GetDataqid"
            var data = {
                op:'pv',
                pid: this.pid
            }
            this.axios.post(api, data).then((response) => {
                var rs = response.data
                if (rs.code) {
                    // this.$message.error(rs.message);
                } else {
                    this.qids = rs.data.qid
                    this.qidsub = rs.data.qidsub
                    this.seleDisabled = false
                    if(rs.data.configs!=""){
                        this.configs = JSON.parse(rs.data.configs)
                    }
                    if (this.configs !== null) {
                        // console.log("this.configs:", this.configs)
                        let ks = Object.keys(this.configs)
                        for (let k of ks) {
                            this.tags.push(k)
                        }
                    } else {
                        this.configs = {}
                    }


                }
            })
        },
        reset() {
            this.headSelect = ""
            this.sideSelect = ""
            this.batch = false
        },
        // configs对象的键名：表头Qid1#表头Qid2#表头Qid3##表侧Qid
        // 键值：对象{
        // pos:变量对应位置
        // batch:false 是否启用批量操作
        // input:自定义输入内容\
        // }
        // pos:对象{
        // headqid1:位置索引
        // headqid2:位置索引
        // sideqid:位置索引时
        // }
        addTag() {
            if (this.headSelect.trim() === ""|| this.sideSelect.length == 0) {
                this.$alert('请先选取表头表侧变量', '提示', {
                    confirmButtonText: '确定'
                })
                return
            }
           
            // 1,保存到配置对象configs        
            const h = this.sideSelect.join("#")
            const configKey = this.headSelect + "##" +h
            let configValue = { batch: this.batch }
            let pos = {}
            configValue["head"] = this.headSelect
            configValue["side"] = this.sideSelect.join(",")     
            
            for (let ob of this.sideRadio) {
                pos[ob.qid] = ob.weizhi
            }
            configValue["pos"] = pos
            this.configs[configKey] = configValue

            // 2,添加到右侧tags框用于展示,已经存在的标签不添加
            if (this.tags.indexOf(configKey) === -1) {
                this.tags.push(configKey)
            }
            // console.log(this.configs)
            this.reset()

        },
        tagDel(tag) {
            // 删除框内tag
            this.tags.splice(this.tags.indexOf(tag), 1)
            // 删除配置对象configs
            delete this.configs[tag]
        },
        tagClear() {
            // 删除框内tag
            this.tags.splice(0, this.tags.length);
            // 删除配置对象configs

            for (let key in this.configs) {
                // 删除对象的属性
                delete this.configs[key];
            }
        },
        saveTemp() {
            this.loading = true
            var api = this.COMMON.httpUrl + "infoTempSave"
            var data = {
                op:'pv',
                pid: this.pid,
                configs: this.configs
            }
            this.axios.post(api, data).then((response) => {
                this.loading = false
                var rs = response.data
                if (rs.code) {
                    this.$message.error(rs.message);
                } else {
                    this.$message.success(rs.message);
                }
            })
        },
        down() {
            this.loading = true
            var api = this.COMMON.httpUrl + "infoTempDown"
            var data = {
                pid: this.pid,
                op: "pv"
            }
            this.axios.post(api, data).then((response) => {
                this.loading = false
                var rs = response.data
                if (rs.code) {
                    this.$message.error(rs.message);
                } else {
                    this.$message.success("logic模板下载中");
                    window.location.href = rs.data
                }
            })

        },
        beforeAvatarUpload(file) {
            this.loading = true
            let fileName = file.name.substring(file.name.lastIndexOf('.') + 1)

            //    alert(fileName)
            const extension = fileName === 'xls'
            const extension2 = fileName === 'xlsx'
            if (!extension && !extension2) {
                this.$message({
                    message: '上传文件只能是 xls、xlsx格式!',
                    type: 'warning'
                });
                this.loading = false
                return false
            }

        },
        onSuccess(res) {
            if (res.code) {
                this.$message.error(res.message)
            } else {
                this.$message.success("datamap更新完成！");
            }
            this.loading = false

        }


    },
    created() {         
        this.getQid()
    }
}
</script>
<style scoped>
#content {
    background-color: white;
    padding-top: 30px;
    
}

.part0 {
    margin-left: 50px;
}

.part1 {
    text-align: left;
}

.part4 {
    margin-top: 80px;
    padding-left: 40px;
}

.part2 {
    margin-top: 30px;
}

.part2-2 {
    margin-top: 130px;
}

.weizhi {
    margin-top: 50px;
    margin-left: 50px;
}

.weizhi div {
    margin-top: 10px;
}

.tags {
    border: 2px dashed #e0e5f0;
    width: 500px;
    height: 550px;
    margin-left: 50px;
    margin-top: 20px;
    padding: 10px;
    overflow: auto;
}

.tags span {
    margin-left: 10px;
    margin-top: 10px;
}

.upload {
    display: inline-block;
    margin: 0 8px;
}
</style>
 