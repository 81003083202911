<template>
  <div>
    <!-- 返回内容展示 -->
    <el-dialog
      :show-close="true"      
      :destroy-on-close="true"
      :visible.sync="result.resultOpen"
      top="8vh"
      width="70%" height="65%"
    >
    <div class="dialog-content">
    <el-table
      :data="result.tieData"
      style="width: 100%">
      <el-table-column
        prop="Vid"
        label="vid"
        >
      </el-table-column>
      <el-table-column
        prop="Tiaojian"
        label="条件"
        >
      </el-table-column>
      <el-table-column
        prop="Juese"
        label="角色">
      </el-table-column>
      <el-table-column
        prop="Count"
        label="贴入数量">
      </el-table-column>
    </el-table>
  </div>
    </el-dialog>
    <div id="content">
      <div class="part1">
        <el-button type="danger" size="small"  @click="deltie"
          >清空贴入记录</el-button>
        <el-button type="primary" size="small"  
        v-loading="loading"
          @click="oeRunTie"
          >将导入的句子贴入rawdata</el-button>
        <div style="font-size: 14px; margin-top: 50px">
          <span>共贴入 {{ result.totalCountTie }} 条</span>
        </div>
      </div>
      <div class="part2">
        <el-divider content-position="left">多语言</el-divider>
        <div style="padding-top:40px">
        <el-button type="danger" plain size="small" @click="delmulti"
          >清空</el-button
        >
        <el-button type="primary" plain size="small" @click="preMultiTie"  v-loading="loading2">预贴入</el-button>
        <el-button type="primary" plain size="small"  @click="pdowndata('multilingual.xlsx', 'oeMultiPreDown', '下载异常')">下载</el-button>
        <el-upload
          class="upload"
          name="ju"
          :action="upurlmul"
          :headers="{ Authorization: token }"
          :data="{ pid: pid }"
          auto-upload
          :show-file-list="false"
          :before-upload="beforeAvatarUpload3"
          :on-success="onSuccessMul"
          v-loading="loading3"
          ><el-button type="primary" plain size="small"
            >导入</el-button
          ></el-upload>
          <el-button type="primary" plain size="small" v-loading="loading4" @click="oeMultiTie">贴入到rawdata</el-button>
        </div>
        <div style="font-size:14px; margin-top: 30px">
            <span>共生成 {{ countMultiPre }} 条</span>
        </div>
      </div>
      <div class="part2">
        <el-divider content-position="left">respid配置</el-divider>
        <div style="padding-top:40px">
        <el-button type="danger" plain size="small" @click="delrid"
          >清空</el-button
        >
        <el-upload
          class="upload"
          name="rid"
          :action="upurlrid"
          :headers="{ Authorization: token }"
          :data="{ pid: pid }"
          auto-upload
          :show-file-list="false"
          :before-upload="beforeAvatarUpload"
          :on-success="onSuccessRid"
          ><el-button type="primary" plain size="small"
            >导入respid</el-button
          ></el-upload>
          <el-link type="primary" :href="tempridurl"  style="margin-left:10px">下载导入模板</el-link>
        </div>
        <div style="font-size:14px; margin-top: 30px">
            <span>共导入 {{ totalCountRid }} 条</span>
        </div>
      </div>
      <div class="part3">
        <el-divider content-position="left">文字处理配置</el-divider>
        <div style="padding-top:40px">
        <el-button type="danger" plain size="small" @click="delcfg"
          >清空</el-button
        >
        <el-upload
          class="upload"
          name="cfg"
          :action="upurlcfg"
          :headers="{ Authorization: token }"
          :data="{ pid: pid }"
          auto-upload
          :show-file-list="false"
          :before-upload="beforeAvatarUpload"
          :on-success="onSuccessCfg"
          ><el-button type="primary" plain size="small"
            >导入配置</el-button
          ></el-upload>          
          <el-button type="text"  @click="pdowndata('textConfig.xlsx', 'oeCfgTempDown', '下载异常')" style="margin-left:10px">下载导入模板</el-button>
        </div>
        <div class="cfg">
          <el-table size="mini" row-key="id" :data="wenConfig" fit border>
                <el-table-column prop="qid" label="open题" ></el-table-column>
                <el-table-column prop="cuobiezi" label="加入错别字"></el-table-column>
                <el-table-column prop="juhao" label="去除末尾句号"></el-table-column>
                <el-table-column prop="tszifu" label="去除特殊字符"> </el-table-column>
                <el-table-column prop="kongbai" label="加入空白"> </el-table-column>
                <el-table-column prop="tswen" label="加入特殊文本"> </el-table-column>
                <el-table-column prop="sjwen" label="加入随机文本"> </el-table-column>
              </el-table>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
export default {
  name: "OeRun",
  props: ["kid", "pid"],
  data() {
    return {
      loading:false,
      loading2:false,
      loading3:false,
      loading4:false,
      upurlrid: this.COMMON.httpUrl + "oeRidImport",
      upurlcfg: this.COMMON.httpUrl + "oeCfgImport",
      upurlmul: this.COMMON.httpUrl + "oeMultiPreImport",
      token: "Bearer " + localStorage.getItem("token"),
      totalCountRid:0,
      countMultiPre:0,
      tempridurl:this.COMMON.httpUrl + "storage/temp/oeTempRespid.xlsx",      
      wenConfig:[],
      result:{
        resultOpen:false,
        totalCountTie:0,
        tieData:[],
      }
    };
  },

  methods: {
    getOeRunInfo() {
      var api = this.COMMON.httpUrl + "oeRunInfo";
      var data = {
        pid: this.pid,
      };
      this.axios.post(api, data).then((response) => {
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          this.result.totalCountTie = rs.data.tieCount;    
          this.totalCountRid = rs.data.ridCount;
          this.wenConfig = rs.data.cfg;         
          this.countMultiPre=rs.data.countMultiPre;
        }
      });
    },
    oeRunTie() {
      this.loading=true;
      var api = this.COMMON.httpUrl + "oeRunTie";
      var data = {
        pid: this.pid,
      };
      this.axios.post(api, data).then((response) => {
        this.loading=false;
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        } else {       
          this.result.resultOpen = true;         
          this.result.tieData = rs.data.tieData;    
          this.result.totalCountTie = rs.data.tieCount;        
        }
      });
    },
    preMultiTie() {
      this.loading2=true
      var api = this.COMMON.httpUrl + "oeMultiPreTieMake";
      var data = {
        pid: this.pid,
      };
      this.axios.post(api, data).then((response) => {
        this.loading2=false
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          this.$message.success(rs.message);
          this.countMultiPre =rs.data;        
        }
      });
    },
    
    oeMultiTie() {
      this.loading4=true
      var api = this.COMMON.httpUrl + "oeMultiTie";
      var data = {
        pid: this.pid,
      };
      this.axios.post(api, data).then((response) => {
        this.loading4=false
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          this.$message.success(rs.message);
          this.result.totalCountTie =rs.data;        
          this.countMultiPre=0;
        }
      });
    },
    deltie() {
      var api = this.COMMON.httpUrl + "oeTieClear";
      var data = {
        pid: this.pid,
      };
      this.axios.post(api, data).then((response) => {
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          this.$message.success(rs.message);
          this.result.totalCountTie =0;        
        }
      });
    },
    delrid() {
      var api = this.COMMON.httpUrl + "oeRidClear";
      var data = {
        pid: this.pid,
      };
      this.axios.post(api, data).then((response) => {
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          this.totalCountRid = rs.data;
          this.$message.success(rs.message);
        }
      });
    },
    delcfg() {
      var api = this.COMMON.httpUrl + "oeCfgClear";
      var data = {
        pid: this.pid,
      };
      this.axios.post(api, data).then((response) => {
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          this.wenConfig = [];
          this.$message.success(rs.message);
        }
      });
    },
    delmulti() {
      var api = this.COMMON.httpUrl + "oeMultiPreClear";
      var data = {
        pid: this.pid,
      };
      this.axios.post(api, data).then((response) => {
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          this.$message.success(rs.message);
          this.countMultiPre =0;        
        }
      });
    },
    beforeAvatarUpload(file) {
     
      let fileName = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = fileName === "xls";
      const extension2 = fileName === "xlsx";
      if (!extension && !extension2) {
        this.$message({
          message: "上传文件只能是 xls、xlsx格式!",
          type: "warning",
        });
        return false;
      }      
    },
    beforeAvatarUpload3(file) {
     this.loading3=true
     let fileName = file.name.substring(file.name.lastIndexOf(".") + 1);
     const extension = fileName === "xls";
     const extension2 = fileName === "xlsx";
     if (!extension && !extension2) {
       this.$message({
         message: "上传文件只能是 xls、xlsx格式!",
         type: "warning",
       });
       return false;
     }      
   },
    onSuccessRid(res) {
      if (res.code) {
        this.$message.error(res.message);
      } else {
        this.$message.success(res.message);        
        this.totalCountRid = res.data;
      }      

    },
    onSuccessCfg(res) {
      if (res.code) {
        this.$message.error(res.message);
      } else {
        this.$message.success(res.message);        
        this.wenConfig = res.data;
      }      

    },
    onSuccessMul(res) {
      this.loading3=false
      if (res.code) {
        this.$message.error(res.message);
      } else {
        this.$message.success(res.message);        
        this.countMultiPre = res.data;
      }      

    },
     // 文件下载
     pdowndata(fname, url, er) {      
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: this.token,
        },
        body: JSON.stringify({ pid: this.pid}),
      };

      let src = this.COMMON.httpUrl + url;
      fetch(src, requestOptions)
        .then((response) => {
          
          if (!response.ok) {
            // 如果响应不是成功的，尝试读取JSON格式的错误信息
            return response.json().then((errorData) => {
              // 假设错误数据是一个包含code和message的对象
              if (errorData.code && errorData.message) {
                // 抛出错误以便后续的.catch可以捕获
                throw new Error(errorData.message);
              } else {
                // 如果格式不符合预期，可以抛出一个通用的错误
                throw new Error(er || "未知错误");
              }
            });
          }
          return response.blob();
        })
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          // 文件名称可以在这里更改
          a.download = fname;
          a.href = url;
          a.click();
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },
  },
  created() {
    this.getOeRunInfo();
  },
};
</script>
  <style scoped>
#content {
  background-color: white;
  padding-top: 40px;
  padding-left: 50px;
}
.part1{
  height: 200px;
}
.part2{
  height: 200px;
}
.part3 {
  min-height: 200px;
}
.upload {
  display: inline-block;
  margin: 0 8px;
}
.cfg{
  width:50%;
  margin-top:30px;
  margin-bottom: 30px;
}
.dialog-content {  
  /* 设置最大高度，确保内容超出时显示滚动条 */  
  max-height: calc(100vh - 200px); /* 假设这里减去的200px是标题和底部按钮的空间 */  
  overflow-y: auto; /* 垂直滚动 */  
  padding: 20px; /* 根据需要添加内边距 */  
}  
</style>
 
   