<template>
    <div style="overflow:hidden">
        <div id="goback" style="margin-bottom:30px;font-size:14px;">
            <i class="el-icon-back"  @click="$router.go(-1)" style="cursor:pointer;">返回</i>&nbsp;&nbsp; <span style="font-size:14px;color:rgb(192, 196, 204)">|</span> &nbsp;&nbsp;<span style="font-size:14px;color:rgb(192, 196, 204)">编号：</span>{{pcode}} <span style="font-size:14px;color:rgb(192, 196, 204)">项目：</span>{{pname}}&nbsp;&nbsp;<span style="font-size:14px;color:rgb(192, 196, 204)">报告：</span>{{bname}}      
        </div>
        <el-tabs v-model="activeName" type="card">
            <el-tab-pane label="报告" name="one" :lazy="true">
                <KpiShow :kid="kid" :pid="pid" :pname="pname" :kname="bname"></KpiShow>
            </el-tab-pane>
            <el-tab-pane label="关联表" name="two" :lazy="true">
                <KpiGuan :kid="kid" :pid="pid"></KpiGuan>
            </el-tab-pane>
           
            <el-tab-pane label="表头" name="three" :lazy="true">
                <KpiHead :kid="kid" :pid="pid"></KpiHead>
            </el-tab-pane>

            <el-tab-pane label="表侧" name="four" :lazy="true">
                <KpiSide :kid="kid" :pid="pid"></KpiSide>
            </el-tab-pane>
            <el-tab-pane label="计算格式" name="five" :lazy="true">
                <KpiGeshi :kid="kid" :pid="pid"></KpiGeshi>
            </el-tab-pane>
            <el-tab-pane label="组合表" name="six" :lazy="true">
                <KpiZuhe :kid="kid" :pid="pid"></KpiZuhe>
            </el-tab-pane>
            <el-tab-pane label="开放浏览" name="seven" :lazy="true">
                <KpiOpen :kid="kid" :pid="pid"></KpiOpen>
            </el-tab-pane>
           
        </el-tabs>
    </div>
</template>
<script>
import KpiShow from './KpiShow'
import KpiHead from './KpiHead'
import KpiSide from './KpiSide'
import KpiZuhe from './KpiZuhe'
import KpiGeshi from './KpiGeshi'
import KpiOpen from './KpiOpen'
import KpiGuan from './KpiGuan'


export default {
    name: 'KpiIndex',
    props: ['pid','kid','pname','bname','pcode'],
    data() {
        return {
            activeName: 'one',
        };
    },
    components: {
        KpiGuan,KpiHead,KpiSide,KpiShow,KpiOpen,KpiGeshi,KpiZuhe    
    },
    methods: {
    },
    created() {
        let kpitag=localStorage.getItem("kpitag")
        if (kpitag!="" &&kpitag!=null){
            this.activeName=kpitag
        }
    },

}
</script>
