<template>
  <div>
    <div id="goback" style="margin-bottom: 10px; font-size: 14px">
      <i class="el-icon-back" @click="$router.go(-1)" style="cursor: pointer"
        >返回</i
      >&nbsp;&nbsp;
      <span style="font-size: 14px; color: rgb(192, 196, 204)">|</span>
      &nbsp;&nbsp;<span style="color: rgb(192, 196, 204)">编号：</span
      >{{ pcode }} <span style="color: rgb(192, 196, 204)">项目：</span
      >{{ pname }}
    </div>
    <div id="content">
      <div class="op">
        <el-button
          size="small"
          type="primary"
          icon="el-icon-zoom-in"
          @click="operate('新建', null, null)"
          :disabled="doing"
          >创建reportal</el-button
        >
        <el-button
          size="small"
          icon="el-icon-view"
          @click="topage('kpipv')"
          :disabled="doing"
          >PV 浏览</el-button
        >
      </div>
      <div class="plist">
        <el-table :data="tableData" style="width: 1200px">
          <el-table-column prop="id" label="id" v-if="false"> </el-table-column>
          <el-table-column prop="name" label="报告名称" width="400">
            <template slot-scope="scope">
              {{ scope.row.name
              }}<span v-if="scope.row.open" class="open">开放浏览</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="600">
            <template slot-scope="scope">
              <router-link
                :to="{
                  name: 'kpi',
                  params: {
                    pid: pid,
                    kid: scope.row.id,
                    bname: scope.row.name,
                    pname: pname,
                    pcode: pcode,
                  },
                }"
                class="caozuo"
                >查看</router-link
              >
              <router-link
                :to="{
                  name: 'quotatab',
                  params: {
                    pid: pid,
                    kid: scope.row.id,
                    bname: scope.row.name,
                    pname: pname,
                    pcode: pcode,
                  },
                }"
                class="caozuo"
                >配额表</router-link
              >
         
              <router-link
                :to="{
                  name: 'quotarun',
                  params: {
                    pid: pid,
                    kid: scope.row.id,
                    bname: scope.row.name,
                    pname: pname,
                    pcode: pcode,
                  },
                }"
                class="caozuo"
                >撞数</router-link
              >
              <el-button
                round
                size="mini"
                @click="operate('复制', scope.row.name, scope.row.id)"
                class="result"
                :disabled="doing"
                >复制</el-button
              >
              <el-button
                round
                size="mini"
                @click="operate('重命名', scope.row.name, scope.row.id)"
                class="result"
                :disabled="doing"
                >重命名</el-button
              >

              <el-button
                :disabled="doing"
                type="danger"
                round
                size="mini"
                @click="del(scope.row.id)"
                class="result"
                plain
                >删除</el-button
              >
            </template>
          </el-table-column>
          <el-table-column prop="created_time" label="创建日期">
            <template slot-scope="scope">
              <div :style="{ color: '#c0c4cc', 'font-size': '12px' }">
                {{ scope.row.created_time }}
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="fenye">
        <el-pagination
          @current-change="getreport"
          :current-page.sync="currentPage"
          :page-size="10"
          layout="total, prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReportList",
  props: ["pid", "pname", "pcode"],
  data() {
    return {
      doing: false,
      tableData: [],
      currentPage: 1,
      total: 1,
    };
  },

  created() {
    this.getreport(this.currentPage);
  },
  methods: {
    topage(t) {
      this.$router.push({
        name: t,
        params: { pid: this.pid, pname: this.pname, pcode: this.pcode },
      });
    },

    operate(op, name, kid) {
      let cpid = kid;
      let newname = name;
      switch (op) {
        case "新建":
          cpid = null;
          newname = null;
          break;
        case "复制":
          newname = name + "-副本";
          break;
      }
      this.$prompt("请输入reportal名称", op, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /\S+/,
        inputErrorMessage: "请输入非空字符",
        inputValue: newname,
      })
        .then(({ value }) => {
          if (op == "重命名") {
            this.rename(cpid, value);
          } else {
            this.add(cpid, value);
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消输入",
          });
        });
    },
    add(cpid, name) {
      this.doing = true;
      var api = this.COMMON.httpUrl + "reportAdd";
      var data = {
        cpid: cpid,
        name: name,
        pid: this.pid,
        pname: this.pname,
      };
      this.axios.post(api, data).then((response) => {
        this.doing = false;
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          this.tableData = rs.data.data;
          this.currentPage = rs.data.current_page;
          this.total = rs.data.total;
        }
      });
    },
    rename(kid, name) {
      this.doing = true;
      var api = this.COMMON.httpUrl + "reportRename";
      var data = {
        kid: kid,
        name: name,
        pid: this.pid,
        pname: this.pname,
      };
      this.axios.post(api, data).then((response) => {
        this.doing = false;
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          this.tableData = rs.data.data;
          this.currentPage = rs.data.current_page;
          this.total = rs.data.total;
        }
      });
    },
    del(kid) {
      this.$confirm("删除后相关数据将被清除,是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.rundel(kid);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    rundel(kid) {
      this.doing = true;
      var api = this.COMMON.httpUrl + "reportDel";
      var data = {
        pid: this.pid,
        kid: kid,
      };
      this.axios.post(api, data).then((response) => {
        var rs = response.data;
        this.doing = false;
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          this.$message.success("删除成功");
          this.tableData = rs.data.data;
          this.currentPage = rs.data.current_page;
          this.total = rs.data.total;
        }
      });
    },
    getreport(page) {
      var api = this.COMMON.httpUrl + "reportList";
      var data = {
        pid: this.pid,
        page: page,
      };
      this.axios.post(api, data).then((response) => {
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          this.tableData = rs.data.data;
          this.currentPage = rs.data.current_page;
          this.total = rs.data.total;
        }
      });
    },
  },
};
</script>
  
<style scoped>
#content {
  background-color: white;
  width: 1200px;
  text-align: left;
  padding-left: 0px;
}

.op {
  padding: 20px 0;
}

.fenye {
  margin-top: 20px;
}
.open {
  margin-left: 15px;
  border-radius: 8px;
  padding: 2px 4px;
  font-size: 12px;
  color: white;
  background-color: rgb(82 200 43);
}
.caozuo {
  color: #409eff !important;
  margin-right: 15px;
}
</style>