var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticStyle:{"margin-bottom":"10px","font-size":"14px"},attrs:{"id":"goback"}},[_c('i',{staticClass:"el-icon-back",staticStyle:{"cursor":"pointer","font-size":"14px"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("返回")]),_vm._v("   "),_c('span',{staticStyle:{"font-size":"14px","color":"rgb(192, 196, 204)"}},[_vm._v("|")]),_vm._v("   "),_c('span',{staticStyle:{"font-size":"16px","color":"rgb(192, 196, 204)"}},[_vm._v("编号：")]),_vm._v(_vm._s(_vm.pcode)+" "),_c('span',{staticStyle:{"font-size":"14px","color":"rgb(192, 196, 204)"}},[_vm._v("项目：")]),_vm._v(_vm._s(_vm.pname)+" ")]),_c('div',{attrs:{"id":"content"}},[_c('div',{staticClass:"op"},[_c('el-button',{attrs:{"type":"danger","size":"small","icon":"el-icon-delete","loading":_vm.shuaxin},on:{"click":_vm.del}},[_vm._v("删除")])],1),_c('div',{staticClass:"plist"},[_c('el-table',{ref:"multipleTable",attrs:{"row-key":"id","data":_vm.tableData,"size":"medium"}},[_c('el-table-column',{attrs:{"type":"selection"}}),_c('el-table-column',{attrs:{"sortable":"","prop":"pcode","label":"项目编号","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.pcode=='null')?_c('span',{staticStyle:{"color":"#c0c4cc"}},[_vm._v("null")]):_c('span',[_vm._v(_vm._s(scope.row.pcode))])]}}])}),_c('el-table-column',{attrs:{"sortable":"","prop":"pname","label":"项目名称","width":"300"}}),_c('el-table-column',{attrs:{"prop":"remote_state","label":"项目状态","width":"100"}}),_c('el-table-column',{attrs:{"label":"操作","width":"550"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('router-link',{staticClass:"caozuo",attrs:{"to":{
                name: 'pdata',
                params: {
                  pid: scope.row.id,
                  ppcode: scope.row.pcode,
                  ppname: scope.row.pname,
                },
              }}},[_vm._v("项目数据")]),_c('router-link',{staticClass:"caozuo",attrs:{"to":{
                name: 'pedit',
                params: {
                  pid: scope.row.id,
                  ppcode: scope.row.pcode,
                  ppname: scope.row.pname,
                },
              }}},[_vm._v("编辑")]),_c('router-link',{staticClass:"caozuo",attrs:{"to":{
                name: 'oldtonew',
                params: {
                  pid: scope.row.id,
                  pcode: scope.row.pcode,
                  pname: scope.row.pname,
                },
              }}},[_vm._v("Oldtonew")]),(_vm.perm.oe)?_c('router-link',{staticClass:"caozuo",attrs:{"to":{
                name: 'oeindex',
                params: {
                  pid: scope.row.id,
                  pcode: scope.row.pcode,
                  pname: scope.row.pname,
                },
              }}},[_vm._v("OE")]):_vm._e(),_c('router-link',{staticClass:"caozuo",attrs:{"to":{
                name: 'info',
                params: {
                  pid: scope.row.id,
                  pcode: scope.row.pcode,
                  pname: scope.row.pname,
                },
              }}},[_vm._v("Info")]),_c('router-link',{staticClass:"caozuo",attrs:{"to":{
                name: 'logic',
                params: {
                  pid: scope.row.id,
                  pcode: scope.row.pcode,
                  pname: scope.row.pname,
                },
              }}},[_vm._v("Logic")]),_c('router-link',{staticClass:"caozuo",attrs:{"to":{
                name: 'instruct',
                params: {
                  pid: scope.row.id,
                  pcode: scope.row.pcode,
                  pname: scope.row.pname,
                },
              }}},[_vm._v("Instruct")]),_c('router-link',{staticClass:"caozuo",attrs:{"to":{
                name: 'reportlist',
                params: {
                  pid: scope.row.id,
                  pcode: scope.row.pcode,
                  pname: scope.row.pname,
                },
              }}},[_vm._v("Kpi")])]}}])}),_c('el-table-column',{attrs:{"sortable":"","prop":"created_time","label":"创建日期","width":"160"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{style:({ color: '#c0c4cc', 'font-size': '12px' })},[_vm._v(" "+_vm._s(scope.row.created_time)+" ")])]}}])})],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }